import { GridColumnMenuProps, GridColumnResizeParams } from "@mui/x-data-grid";

export const handleColumnResize = (params: GridColumnResizeParams, COLUMN_WIDTHS_KEY: string) => {
    const savedWidths = JSON.parse(
        localStorage.getItem(COLUMN_WIDTHS_KEY) || "{}"
    );
    savedWidths[params.colDef.field] = params.width;

    localStorage.setItem(COLUMN_WIDTHS_KEY, JSON.stringify(savedWidths));
};
