import React from "react";
import "./CustomCheckbox.css";

interface CustomCheckboxProps {
  id?: string | undefined;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkedBgColor?: string;
  title?: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  id = "custom-checkbox", // 기본 id 값 설정
  checked,
  onChange,
  checkedBgColor = "#005194",
  title = "",
}) => {
  return (
    <div className="checkbox-cell">
      <input
        id={id}
        type="checkbox"
        className="custom-checkbox"
        checked={checked}
        onChange={onChange}
        style={
          {
            "--checkbox-background-color": checkedBgColor,
          } as React.CSSProperties
        }
      />
      <label
        htmlFor={id}
        style={{
          fontFamily: "malgun",
          fontSize: 13,
          fontWeight:"bold",
          cursor: "pointer", // 마우스 포인터 추가
        }}
      >
        {title}
      </label>
    </div>
  );
};

export default CustomCheckbox;
