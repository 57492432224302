import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList } from "@mui/lab";
import { Button, SelectChangeEvent, Stack, Tab } from "@mui/material";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataGrid, {
  CellClickArgs,
  CellKeyboardEvent,
  CellKeyDownArgs,
  CellMouseEvent,
  CellSelectArgs,
  ColumnOrColumnGroup,
  DataGridHandle,
  RenderHeaderCellProps,
} from "react-data-grid";
import "react-data-grid/lib/styles.css";
import CommonButton from "../../../components/common/CommonButton";
import CustomCheckbox from "../../../components/common/CustomCheckbox";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import DataTotalCount, {
  DataCount,
} from "../../../components/common/DataTotalCount";
import { ColorSelect } from "../../../components/shared/cell-renderers/ColorSelect";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import SelectForm from "../../../components/shared/input-field/SelectForm";
import { autoRowHeight } from "../../../components/shared/lib/autoRowHeignt";
import { getCurrentDate } from "../../../components/shared/lib/getCurrentDate";
import {
  MultiRowCell,
  MultiTextEditor,
} from "../../../components/shared/lib/multiRowCell";
import { CellButton } from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { colorOptions } from "../../../constants/constant";
import { getDetailCode } from "../../../service/common/api";
import { BlackGetType } from "../../../types/BlackType";
import { CallTabType, CallType } from "../../../types/CallType";
import { CodeDetailType } from "../../../types/CodeType";
import { SortType } from "../../../types/CommonType";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import {
  getClipboardValue,
  handleCopy,
  onColumnResize,
} from "../../../utils/commonUtil";
import {
  formatDate,
  formatDateYYYYMMDD,
  getCurrentKorDate,
} from "../../../utils/dateUtil";
import { excelDownLoader } from "../../../utils/excelDownLoader";
import { isEmpty } from "../../../utils/isEmpty";
import { getJwtState } from "../../../utils/jwtState";
import SheetChangePopup from "./SheetChangePopup";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { isNumericString } from "../../../utils/stringUtil";

function inputStopPropagation(event: React.KeyboardEvent<HTMLInputElement>) {
  if (["ArrowLeft", "ArrowRight"].includes(event.key)) {
    event.stopPropagation();
  }
}
interface Filter extends CallType {}
const FilterContext = createContext<Filter | undefined>(undefined);
const SortContext = createContext<SortType | undefined>(undefined);
const FilterToggleContext = createContext<boolean>(false);
const DEFAULT_HEADER_HEIGHT = 35;

const initFilter: Filter = {
  SEQ: -1,
  CALL_ID: 0,
  CALL_DIV_CD: "",
  RECENT_CONTACT_DATE: "",
  SALESPERSON_ID: "",
  SALESPERSON_NM: "",
  CREATE_DATE: "",
  KEYWORD: "",
  CONTACT_SOURCE: "",
  BUSINESS_NAME: "",
  ENT_CONTACT_NAME: "",
  PRODUCT_URL: "",
  PHONE_NUMBER: "",
  NOTES: "",
  EMAIL: "",
  IS_KAKAO_CONTACT: false,
  IS_MAIL_CONTACT: false,
  COLOR: "",
  CREATED_ID: "",
  CREATED_NM: "",
  CREATED_AT: new Date(0),
  UPDATED_ID: "",
  UPDATED_NM: "",
  UPDATED_AT: new Date(0),
  DELETED_ID: undefined,
  DELETED_NM: undefined,
  DELETED_AT: undefined,
};

const calculateDifferenceCount = (initFilter: Filter, filters: Filter) => {
  let differenceCount = 0;

  for (const key in initFilter) {
    const columnKey = key as keyof Filter;
    if (columnKey !== "CREATE_DATE" && columnKey !== "RECENT_CONTACT_DATE") {
      continue;
    }

    if (initFilter[key as keyof Filter] !== filters[key as keyof Filter]) {
      differenceCount++;
    }
  }

  return differenceCount;
};

const CallTable = () => {
  const gridRef = useRef<DataGridHandle | null>(null);
  const storedData = localStorage.getItem("call_list");
  const widths = storedData ? JSON.parse(storedData) : undefined;
  const [filterToggle, setFilterToggle] = useState(false);
  const [filters, setFilters] = useState<Filter>(() => initFilter);
  const [openSheetChangeModal, setOpenSheetChangeModal] = useState<CallType[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(
    new Set()
  );
  const [tab, setTab] = useState<string>("C024A");
  const [tabs, setTabs] = useState<CallTabType[]>([]);
  const [color, setColor] = useState<string>("전체");
  const { userId } = getJwtState();
  const selectedRowId = useRef<number>();
  const selectedColumnKey = useRef<string>();
  const selectedColumnIdx = useRef<number>();

  const [rows, setRows] = useState<CallType[]>([]);
  const [myRows, setMyRows] = useState<CallType[]>([]);
  const [filterdRows, setFilterdRows] = useState<CallType[]>([]);
  const [blackList, setBlackList] = useState<BlackGetType[]>([]);
  const [search, setSearch] = useState("");
  const [entList, setEntList] = useState<EntGetType[]>([]);
  const [sortType, setSortType] = useState<SortType>({
    columnKey: "",
    direction: "ASC",
  });
  const today = dayjs(getCurrentKorDate());
  const getInitialSDate = (): Dayjs | null => {
    const storedSDate = localStorage.getItem("callsheet_sd");
    return storedSDate ? dayjs(storedSDate) : today.startOf("month");
  };

  const getInitialEDate = (): Dayjs | null => {
    const storedEDate = localStorage.getItem("callsheet_ed");
    return storedEDate ? dayjs(storedEDate) : today.endOf("month");
  };
  const [sDate, setSDate] = useState<Dayjs | null>(getInitialSDate);
  const [eDate, setEDate] = useState<Dayjs | null>(getInitialEDate);

  const getInitialTotal = (): boolean => {
    const storedTotal = localStorage.getItem("callsheet_total");
    const isTotal = storedTotal === "true" ? true : false;
    return storedTotal ? isTotal : false;
  };

  const [total, setTotal] = useState(getInitialTotal);

  const getMyData = async () => {
    try {
      const response = await customAxios.get("/call/mydata");
      const data: CallType[] = response.data;
      setMyRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  const getTabType = async () => {
    try {
      const response = await getDetailCode("C024");
      const data: CodeDetailType[] = response.data;

      const transformedData = data.map((item) => ({
        value: item.CD_DTL_ID,
        label: item.CD_DTL_NM,
      }));
      if (transformedData.length > 0) {
        setTab(transformedData[0].value);
      }
      setTabs(transformedData);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const scrollToRow = () => {
    if (gridRef.current) {
      const storedData = localStorage.getItem("selectedCallList_" + tab);
      if (storedData) {
        const parsedData = JSON.parse(storedData);

        setTimeout(() => {
          if (gridRef.current) {
            gridRef.current.scrollToCell({
              rowIdx: parsedData.rowIdx,
              idx: 0,
            });
          }
        }, 100); // 100ms 지연
      }
    }
  };

  const getRows = async () => {
    try {
      const response = await customAxios.get("/call", {
        params: {
          CALL_DIV_CD: tab,
          SALESPERSON_ID: userId,
          COLOR: color === "전체" ? "" : color,
          search,
          sDate: sDate?.format("YYYYMMDD"),
          eDate: eDate?.format("YYYYMMDD"),
          total,
        },
      });
      const data: CallType[] = response.data;
      setRows(data);
      getMyData();
    } catch (e) {
      errorHandler(e);
    }
  };

  const getEntList = async () => {
    try {
      const response = await customAxios.get("/ent/list");
      setEntList(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const getBlackList = async () => {
    try {
      const response = await customAxios.get("/black/call");
      const data: BlackGetType[] = response.data;
      setBlackList(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getRows();
    setSelectedRows(new Set());
    scrollToRow();
  }, [tab, color, sDate, eDate, total]);

  useEffect(() => {
    getTabType();
    getBlackList();
    getEntList();
  }, []);

  const differenceCount = useMemo(
    () => calculateDifferenceCount(initFilter, filters),
    [filters, filterToggle]
  );

  useEffect(() => {
    // 필터링 함수
    const applyFilters = () => {
      const updatedRows = rows.filter((row) => {
        // 필터의 각 조건을 체크하고, true인 경우에만 통과하도록 설정
        return (
          (!filters.CREATE_DATE ||
            formatDateYYYYMMDD(row.CREATE_DATE).includes(
              filters.CREATE_DATE
            )) &&
          (!filters.RECENT_CONTACT_DATE ||
            row.RECENT_CONTACT_DATE.includes(filters.RECENT_CONTACT_DATE))
        );
      });

      setFilterdRows(updatedRows);
    };

    // filters가 변경될 때마다 applyFilters 실행
    applyFilters();
  }, [filters, rows]); // filters와 rows가 변경될 때만 실행

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
      setSelectedRows(new Set());
    }
  };

  const handleDeleteSelectedRow = async () => {
    const selectedIds = Array.from(selectedRows);
    const size = selectedIds.length;

    if (size === 0) {
      popupCustomAlert("warn", "선택된 데이터가 없습니다");
      return;
    }

    if (confirm(`선택된 ${size}개의 데이터를 삭제하시겠습니까?`)) {
      try {
        await customAxios.delete("/call/selected", {
          data: {
            CALLS_ID: selectedIds,
          },
        });
        setSelectedRows(new Set());
        getRows();
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  const handleMoveSheet = () => {
    const selectedIds = Array.from(selectedRows);
    const size = selectedIds.length;

    if (size === 0) {
      popupCustomAlert("warn", `선택된 데이터가 없습니다`);
      return;
    }

    setOpenSheetChangeModal(rows.filter((v) => selectedRows.has(v.CALL_ID)));
  };

  const handleSheetChangeModalSubmit = () => {
    setOpenSheetChangeModal([]);
    setSelectedRows(new Set());
    getRows();
  };

  const handleCreateRow = async () => {
    const result = prompt("몇개의 행을 추가하시겠습니까? (숫자만 입력)", "1");

    if (result !== null) {
      if (isNumericString(result)) {
        const currentDate = getCurrentDate();
        try {
          await customAxios.post("/call", {
            CALL_DIV_CD: tab,
            SALESPERSON_ID: userId,
            CREATE_DATE: currentDate,
            COUNT: Number(result),
          });
          getRows();
        } catch (e) {
          errorHandler(e);
        }
      } else {
        popupCustomAlert("warn", "숫자만 입력해주세요");
      }
    }
  };

  //몇번째 줄이 수정중인지 파악함
  const handleSelectedRow = (cell: CellSelectArgs<CallType, any>) => {
    selectedRowId.current = cell.rowIdx;
    selectedColumnKey.current = cell.column.key;
    selectedColumnIdx.current = cell.column.idx;

    const dataToStore = {
      rowIdx: cell.rowIdx,
    };

    localStorage.setItem(
      "selectedCallList_" + tab,
      JSON.stringify(dataToStore)
    );
  };

  const handleCellClick = (
    args: CellClickArgs<CallType, any>,
    event: CellMouseEvent
  ) => {};

  const handleDoubleClick = (
    args: CellClickArgs<CallType, any>,
    event: CellMouseEvent
  ) => {
    return;
  };

  // 정렬 처리 핸들러
  const handleSortColumnsChange = (newSortColumns: SortType[]) => {
    let sortColumns = newSortColumns;
    if (!sortColumns[0]) {
      sortColumns = [
        {
          columnKey: sortType.columnKey,
          direction: "ASC",
        },
      ];
      setSortType(sortColumns[0]);
    } else if (sortType.columnKey === sortColumns[0].columnKey) {
      setSortType({
        columnKey: sortType.columnKey,
        direction: sortType.direction === "ASC" ? "DESC" : "ASC",
      });
    } else {
      setSortType(sortColumns[0]);
    }

    const sortedRows = [...rows].sort((a, b) => {
      return sortColumns.reduce((acc, { columnKey, direction }) => {
        if (acc !== 0) return acc; // 이전 정렬 조건의 결과를 유지

        const aValue = a[columnKey as keyof CallType];
        const bValue = b[columnKey as keyof CallType];
        const sortOrder = direction === "ASC" ? 1 : -1;

        // 값이 `null` 또는 `undefined`일 때 처리
        if (aValue == null) return 1 * sortOrder;
        if (bValue == null) return -1 * sortOrder;

        return aValue < bValue
          ? -1 * sortOrder
          : aValue > bValue
          ? 1 * sortOrder
          : 0;
      }, 0);
    });

    setRows(sortedRows);
  };

  const validateRequest = (data: CallType, key: string): boolean => {
    /**
     * 업체명 ENT_NM   BUSINESS_NAME
     * 전화번호  ENT_CONTACT_NUMBER  PHONE_NUMBER
     * 링크 P_URLS  PRODUCT_URL
     *
     * 현재 내부에서 광고 대행을 하는 중이라는 경고 알림
     */

    if (key === "BUSINESS_NAME") {
      if (
        entList.findIndex(
          (v) => v.B_NAME === data.BUSINESS_NAME && v.LIVE !== "end"
        ) > -1
      ) {
        popupCustomAlert(
          "warn",
          "현재 내부에서 광고 대행을 하는 중입니다",
          "CALL_ENT" + data.BUSINESS_NAME
        );
      }
    } else if (key === "PHONE_NUMBER") {
      if (
        entList.findIndex(
          (v) =>
            isEmpty(v.ENT_CONTACT_NUMBER) === false &&
            v.ENT_CONTACT_NUMBER === data.PHONE_NUMBER
        ) > -1
      ) {
        popupCustomAlert(
          "warn",
          "현재 내부에서 광고 대행을 하는 중입니다 (전화번호)",
          "CALL_ENT" + data.PHONE_NUMBER
        );
      }
    } else if (key === "PRODUCT_URL") {
      if (
        entList.findIndex(
          (v) => isEmpty(v.P_URLS) === false && v.P_URLS === data.PRODUCT_URL
        ) > -1
      ) {
        popupCustomAlert(
          "warn",
          "현재 내부에서 광고 대행을 하는 중입니다 (링크)",
          "CALL_ENT" + data.PRODUCT_URL
        );
      }
    }

    let target = "";
    let index = -1;
    let targetName = "";
    let toastId = "";

    if (key === "BUSINESS_NAME") {
      target = data.BUSINESS_NAME;
      index = blackList.findIndex(
        (v) => isEmpty(v.B_NAME) === false && v.B_NAME === target
      );
      targetName = "브랜드명";
      toastId = "black-brand" + data.BUSINESS_NAME;
    } else if (key === "PHONE_NUMBER") {
      target = data.PHONE_NUMBER;
      index = blackList.findIndex(
        (v) => isEmpty(v.PHONE) === false && v.PHONE === target
      );
      targetName = "전화번호";
      toastId = "black-phone" + data.PHONE_NUMBER;
    } else if (key === "EMAIL") {
      target = data.EMAIL;
      index = blackList.findIndex(
        (v) => isEmpty(v.EMAIL) === false && v.EMAIL === target
      );
      targetName = "이메일";
      toastId = "black-email" + data.EMAIL;
    } else if (key === "PRODUCT_URL") {
      target = data.PRODUCT_URL;
      index = blackList.findIndex(
        (v) => isEmpty(v.LINK) === false && v.LINK === target
      );
      targetName = "링크";
      toastId = "black-link" + data.PRODUCT_URL;
    }

    if (index > -1) {
      popupCustomAlert(
        "warn",
        `블랙리스트에 등록되어 있는 정보입니다. (${targetName} : ${target})`,
        toastId
      );
      return false;
    }
    return true;
  };

  //수정된 값을 서버로 보내어 업데이트함
  const handleUpdateRow = async (newData: any, oldData: any) => {
    const rowId = selectedRowId.current || 0;
    let callInfo: CallType = newData[rowId];
    if (callInfo === undefined || selectedColumnKey.current === undefined) {
      return;
    }
    const updateColumnKey = selectedColumnKey.current as keyof CallType;
    let updateColumnValue = callInfo[updateColumnKey];

    if (updateColumnKey === "COLOR") {
      const COLOR = callInfo[updateColumnKey];
      const colors = new Set([
        "white",
        "red",
        "orange",
        "yellow",
        "green",
        "blue",
      ]);

      if (colors.has(COLOR) === false) {
        return;
      }
    } else if (updateColumnKey === "CREATE_DATE") {
      const CREATE_DATE = callInfo[updateColumnKey];

      updateColumnValue = CREATE_DATE.replace(/-/g, "");

      if (/^\d{8}$/.test(updateColumnValue) === false) {
        popupCustomAlert("error", "작성일 형식이 맞지 않습니다 (YYYYMMDD)");
        return;
      }
    }

    if (validateRequest(callInfo, updateColumnKey) === false) {
      return;
    }

    if (typeof updateColumnValue === "string") {
      updateColumnValue = updateColumnValue.replace(/^"|"$/g, "").trimStart();
    }

    try {
      await customAxios
        .put("/call", {
          ...callInfo,
          [updateColumnKey]: updateColumnValue,
        })
        .then(getRows)
        .then(() => {
          // 강제 상태 업데이트로 화면 최신화
          setRows((prevRows) => {
            const updatedRows = [...prevRows];
            updatedRows[rowId] = {
              ...callInfo,
              [updateColumnKey]: updateColumnValue,
            };
            return updatedRows;
          });
        });
    } catch (e) {
      errorHandler(e);
    }
  };

  function handleCellKeyDown(
    args: CellKeyDownArgs<CallType, any>,
    event: CellKeyboardEvent
  ) {
    const { column, row, rowIdx, mode } = args;

    const passKey = new Set([
      "F1",
      "F2",
      "F3",
      "F4",
      "F5",
      "F6",
      "F7",
      "F8",
      "F9",
      "F10",
      "F11",
      "F12",
      "Escape",
    ]);
    if (passKey.has(event.key)) {
      return;
    }

    if (mode === "SELECT") {
      event.preventDefault();
    }

    if (event.key === "Enter") {
      event.preventDefault();
      if (mode === "SELECT") {
      } else {
        if (gridRef.current && rowIdx < rows.length - 1) {
          gridRef.current.selectCell(
            {
              rowIdx: rowIdx + 1,
              idx: args.column.idx,
            },
            false
          );
        }
      }

      return;
    } else if (event.key === "Escape") {
      event.preventDefault();
    } else if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      if (mode === "SELECT") {
        getClipboardValue().then((value) => {
          if (value) {
            const targetColumnKey = column.key as keyof CallType;
            let updateValue = value;

            if (targetColumnKey === "COLOR") {
              const colors = new Set([
                "white",
                "red",
                "orange",
                "yellow",
                "green",
                "blue",
              ]);
              if (colors.has(value) === false) {
                return;
              }
            } else if (targetColumnKey === "CREATE_DATE") {
              const cleanedValue = value.replace(/-/g, "");

              if (/^\d{8}$/.test(cleanedValue) === false) {
                popupCustomAlert(
                  "error",
                  "작성일 형식이 맞지 않습니다 (YYYYMMDD)"
                );
                return;
              }

              updateValue = cleanedValue;
            }

            if (typeof updateValue === "string") {
              updateValue = updateValue.replace(/^"|"$/g, "").trimStart();
            }

            const callInfo = {
              ...row,
              [targetColumnKey]: updateValue,
            };

            if (validateRequest(callInfo, targetColumnKey) === true) {
              const rowId = selectedRowId.current || 0;
              try {
                customAxios
                  .put("/call", callInfo)
                  .then(getRows)
                  .then(() => {
                    // 강제 상태 업데이트로 화면 최신화
                    setRows((prevRows) => {
                      const updatedRows = [...prevRows];
                      updatedRows[rowId] = {
                        ...callInfo,
                        [targetColumnKey]: updateValue,
                      };
                      return updatedRows;
                    });
                  });
              } catch (e) {
                errorHandler(e);
              }
            }
          } else {
            console.log("클립보드에 데이터가 없습니다.");
          }
        });
      }
    } else if (event.key === "Delete" && mode === "SELECT") {
      const targetColumnKey = column.key;
      if (
        [
          "RECENT_CONTACT_DATE",
          "COLOR",
          "BUSINESS_NAME",
          "CONTACT_SOURCE",
          "KEYWORD",
          "PRODUCT_URL",
          "ENT_CONTACT_NAME",
          "PHONE_NUMBER",
          "NOTES",
          "EMAIL",
        ].includes(targetColumnKey)
      ) {
        const callInfo = {
          ...row,
          [targetColumnKey]: targetColumnKey === "COLOR" ? "white" : "",
        };

        const rowId = selectedRowId.current || 0;

        try {
          customAxios
            .put("/call", callInfo)
            .then(getRows)
            .then(() =>
              // 강제 상태 업데이트로 화면 최신화
              setRows((prevRows) => {
                const updatedRows = [...prevRows];
                updatedRows[rowId] = {
                  ...callInfo,
                  [targetColumnKey]: targetColumnKey === "COLOR" ? "white" : "",
                };
                return updatedRows;
              })
            )
            .then(() => {
              setTimeout(() => {
                if (gridRef.current) {
                  gridRef.current.selectCell(
                    {
                      rowIdx: filterdRows.length,
                      idx: args.column.idx,
                    },
                    false
                  );
                }
              }, 1);
            })
            .then(() => {
              setTimeout(() => {
                if (gridRef.current) {
                  gridRef.current.selectCell(
                    {
                      rowIdx,
                      idx: args.column.idx,
                    },
                    false
                  );
                }
              }, 70);
            });
        } catch (e) {
          errorHandler(e);
        }
      }
    }
  }

  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  const handleSelectedRowsChange = (newSelectedRows: ReadonlySet<number>) => {
    setSelectedRows(newSelectedRows);
  };

  //컬럼 테이블 생성
  const columns: ColumnOrColumnGroup<CallType, any>[] = [
    {
      key: "CHK",
      name: "선택",
      headerCellClass: "text-center",
      width: getColumnWidth(widths, "CHK", 70),
      sortable: false,
      renderHeaderCell: () => {
        const isAllSelected =
          rows.length > 0 && rows.every((row) => selectedRows.has(row.CALL_ID));

        const handleSelectAllChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          if (event.target.checked) {
            const allRowIds = new Set(rows.map((row) => row.CALL_ID));
            setSelectedRows(allRowIds);
          } else {
            setSelectedRows(new Set());
          }
        };

        return (
          <CustomCheckbox
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
        );
      },
      renderCell: ({ row }: any) => {
        const handleRowCheckboxChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          setSelectedRows((prevSelectedRows) => {
            const updatedSelectedRows = new Set(prevSelectedRows);
            if (event.target.checked) {
              updatedSelectedRows.add(row.CALL_ID);
            } else {
              updatedSelectedRows.delete(row.CALL_ID);
            }
            return updatedSelectedRows;
          });
        };

        return (
          <CustomCheckbox
            checked={selectedRows.has(row.CALL_ID)}
            onChange={handleRowCheckboxChange}
          />
        );
      },
      renderSummaryCell({ row }: any) {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DataCount totalCount={selectedRows.size} />
          </div>
        );
      },
    },
    {
      key: "SEQ",
      name: "순번",
      width: getColumnWidth(widths, "SEQ", 100),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
    },
    {
      key: "COLOR",
      name: "색상",
      width: getColumnWidth(widths, "COLOR", 50),
      headerCellClass: "text-center grid-header-font",
      renderCell: ({ row }: any) => {
        return <></>;
      },
      renderEditCell: ColorSelect,
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
      sortable: false,
    },
    {
      key: "SALESPERSON_NM",
      name: "영업사원",
      width: getColumnWidth(widths, "SALESPERSON_NM", 140),
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center",
      sortable: false,
      renderCell({ row, onRowChange }: any) {
        return <span className="grid-cell-font">{row.SALESPERSON_NM}</span>;
      },
      renderSummaryCell({ row }: any) {
        return <DataCount totalCount={filterdRows.length} text="필터" />;
      },
    },
    {
      key: "CREATE_DATE",
      name: "작성일",
      width: getColumnWidth(widths, "CREATE_DATE", 100),
      draggable: true,
      resizable: true,
      headerCellClass: "filter-cell grid-header-font",
      cellClass: "text-center grid-cell-font",
      renderCell: ({ row }: any) => {
        return (
          <div className="grid-cell-font">
            {formatDateYYYYMMDD(row.CREATE_DATE)}
          </div>
        );
      },
      renderHeaderCell: (p: any) => (
        <FilterRenderer<CallType> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.CREATE_DATE}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  CREATE_DATE: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
      renderEditCell: MultiTextEditor,
      editorOptions: {
        commitOnOutsideClick: true,
      },
    },
    {
      key: "RECENT_CONTACT_DATE",
      name: "최근 컨택일",
      headerCellClass: "filter-cell grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "RECENT_CONTACT_DATE", 150),
      ...MultiRowCell(),
      renderHeaderCell: (p: any) => (
        <FilterRenderer<CallType> {...p}>
          {({ filters, ...rest }) => (
            <input
              {...rest}
              className={"filterClassname"}
              value={filters.RECENT_CONTACT_DATE}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  RECENT_CONTACT_DATE: e.target.value,
                })
              }
              onKeyDown={inputStopPropagation}
            />
          )}
        </FilterRenderer>
      ),
    },
    {
      key: "CONTACT_SOURCE",
      name: "출처",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "CONTACT_SOURCE", 100),
      ...MultiRowCell(),
      sortable: false,
    },
    {
      key: "KEYWORD",
      name: "키워드",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "KEYWORD", 100),
      ...MultiRowCell(),
      sortable: false,
    },

    {
      key: "BUSINESS_NAME",
      name: "브랜드명",
      headerCellClass: "grid-header-font",
      cellClass: (row: CallType) => {
        let className = "grid-cell-font memo-cell";

        const isDuplicate =
          myRows.filter((r) => r.BUSINESS_NAME === row.BUSINESS_NAME).length >
          1;

        if (row.BUSINESS_NAME !== "" && isDuplicate) {
          className += " duplicate-background";
        }

        return className;
      },
      width: getColumnWidth(widths, "BUSINESS_NAME", 100),
      renderCell: ({ row, column, onClose }: any) => {
        return (
          <pre
            style={{
              margin: "0px",
              padding: "0px 10px",
              lineHeight: "1.1rem",
              fontSize: "13px",
              fontFamily: "malgun",
            }}
            onFocus={() => {
              onClose(true, false);
            }}
          >
            {row[column.key]}
          </pre>
        );
      },
      editorOptions: {
        commitOnOutsideClick: true,
      },
      renderEditCell: MultiTextEditor,
      sortable: false,
    },
    {
      key: "PRODUCT_URL",
      name: "링크",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "PRODUCT_URL", 180),
      ...MultiRowCell(),
      sortable: false,
    },
    {
      key: "ENT_CONTACT_NAME",
      name: "업체 담당자",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "ENT_CONTACT_NAME", 100),
      ...MultiRowCell(),
      sortable: false,
    },
    {
      key: "PHONE_NUMBER",
      name: "전화번호",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "PHONE_NUMBER", 150),
      ...MultiRowCell(),
      sortable: false,
    },
    {
      key: "NOTES",
      name: "통화 내용",
      headerCellClass: "text-center grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "NOTES", 250),
      ...MultiRowCell(),
      sortable: false,
    },
    {
      key: "EMAIL",
      name: "이메일",
      headerCellClass: "text-center grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "EMAIL", 150),
      ...MultiRowCell(),
      sortable: false,
    },
    {
      key: "IS_KAKAO_CONTACT",
      name: "카톡",
      width: getColumnWidth(widths, "IS_KAKAO_CONTACT", 70),
      headerCellClass: "text-center grid-header-font",
      sortable: false,
      renderCell({ row, onRowChange }: any) {
        return (
          <CustomCheckbox
            checked={!!row.IS_KAKAO_CONTACT}
            onChange={(event: any) =>
              onRowChange(
                { ...row, IS_KAKAO_CONTACT: event.target.checked },
                true
              )
            }
            checkedBgColor="#ecca06"
          />
        );
      },
    },
    {
      key: "IS_MAIL_CONTACT",
      name: "메일",
      width: getColumnWidth(widths, "IS_MAIL_CONTACT", 70),
      headerCellClass: "text-center grid-header-font",
      sortable: false,
      renderCell({ row, onRowChange }: any) {
        return (
          <CustomCheckbox
            checked={!!row.IS_MAIL_CONTACT}
            onChange={(event: any) =>
              onRowChange(
                { ...row, IS_MAIL_CONTACT: event.target.checked },
                true
              )
            }
            checkedBgColor="#6e00d4"
          />
        );
      },
    },
    {
      key: "CREATED_AT",
      name: "데이터 생성시간",
      headerCellClass: "grid-header-font text-center",
      cellClass: "grid-cell-font text-center",
      sortable: false,
      width: getColumnWidth(widths, "CREATED_AT", 150),
      renderCell: ({ row }: any) => {
        return <div>{formatDate(row.CREATED_AT)}</div>;
      },
    },
  ];

  const handleColorChange = (e: SelectChangeEvent) => {
    setColor(e.target.value);
  };

  const handleExcelExport = () => {
    if (filterdRows.length === 0) {
      popupCustomAlert("warn", "다운로드할 데이터가 없습니다");
      return;
    }
    const excelData = filterdRows.map((item) => {
      return {
        순번: item.SEQ,
        색상: item.COLOR,
        영업사원ID: item.SALESPERSON_ID,
        영업사원명: item.SALESPERSON_NM,
        작성일: item.CREATE_DATE,
        최근컨택일: item.RECENT_CONTACT_DATE,
        출처: item.CONTACT_SOURCE,
        키워드: item.KEYWORD,
        브랜드명: item.BUSINESS_NAME,
        링크: item.PRODUCT_URL,
        업체담당자: item.ENT_CONTACT_NAME,
        전화번호: item.PHONE_NUMBER,
        통화내용: item.NOTES,
        이메일: item.EMAIL,
        카톡: item.IS_KAKAO_CONTACT ? "O" : "X",
        메일: item.IS_MAIL_CONTACT ? "O" : "X",
      };
    });

    excelDownLoader(excelData, "콜시트.xlsx");
  };

  return (
    <Stack flex={1} display={"flex"} width={"100%"} gap={2}>
      {openSheetChangeModal.length > 0 && (
        <SheetChangePopup
          open={openSheetChangeModal}
          tab={tab}
          tabs={tabs}
          onSubmit={handleSheetChangeModalSubmit}
          onCancel={() => setOpenSheetChangeModal([])}
        />
      )}
      <Stack gap={2} display={"flex"} flex={1}>
        <Stack direction="row" justifyContent={"space-between"} gap={3}>
          <TabContext value={tab}>
            <TabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab key={"C024A"} label={"콜시트"} value={"C024A"} />
              <Tab key={"C024B"} label={"가망 건"} value={"C024B"} />
              <Tab key={"C024C"} label={"분배 DB"} value={"C024C"} />
              <Tab key={"C024D"} label={"A급 DB"} value={"C024D"} />
            </TabList>
          </TabContext>
          <Stack
            flexDirection={"row"}
            display={"flex"}
            gap={1}
            alignItems={"center"}
            justifyContent={"center"}
            alignContent={"center"}
          >
            {total === false && (
              <>
                <DatePicker
                  format="YYYY-MM-DD"
                  label="작성 일자"
                  views={["month", "year", "day"]}
                  value={sDate}
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      if (newValue.isValid()) {
                        setSDate(newValue);
                        localStorage.setItem(
                          "callsheet_sd",
                          newValue.format("YYYYMMDD")
                        );
                      }
                    }
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {
                        fontFamily: "malgun",
                        width: 150,
                      },
                    },
                  }}
                />
                <DatePicker
                  format="YYYY-MM-DD"
                  label="종료 일자"
                  value={eDate}
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      if (newValue.isValid()) {
                        setEDate(newValue);
                        localStorage.setItem(
                          "callsheet_ed",
                          newValue.format("YYYYMMDD")
                        );
                      }
                    }
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {
                        fontFamily: "malgun",
                        width: 150,
                      },
                    },
                  }}
                />
              </>
            )}
            <SelectForm
              label="색상"
              options={colorOptions}
              size="small"
              sx={{ width: "120px" }}
              value={color}
              handleChange={handleColorChange}
            />
            <SearchInputField
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              placeholder=""
              showIcon={false}
              width={140}
            />
            <CommonButton
              onClick={() => {
                getRows();
                setSelectedRows(new Set());
              }}
              label="검색"
              icon={<SearchIcon fontSize="small" />}
            />
            <Button
              sx={{
                fontFamily: "malgun",
                height: 40,
              }}
              color={"primary"}
              onClick={() => {
                if (total) {
                  localStorage.setItem("callsheet_total", "false");
                } else {
                  localStorage.setItem("callsheet_total", "true");
                }

                setTotal(!total);
              }}
              variant={"contained"}
            >
              {total ? "전체" : "일자"}
            </Button>
            <Button
              sx={{
                // backgroundColor: "white",
                letterSpacing: 2,
                fontFamily: "malgun",
                height: 40,
              }}
              color={
                filterToggle === false && differenceCount > 0
                  ? "warning"
                  : "primary"
              }
              onClick={() => setFilterToggle(!filterToggle)}
              variant={"contained"}
            >
              필터
            </Button>
            <Button
              variant="contained"
              onClick={handleExcelExport}
              sx={{
                fontFamily: "malgun",
                height: 40,
              }}
            >
              다운로드
            </Button>
            <Button
              variant="contained"
              onClick={handleCreateRow}
              sx={{
                fontFamily: "malgun",
                height: 40,
              }}
            >
              추가
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteSelectedRow}
              sx={{
                fontFamily: "malgun",
                height: 40,
              }}
              color="warning"
            >
              삭제
            </Button>
            <Button
              variant="contained"
              onClick={handleMoveSheet}
              color="success"
              sx={{ fontFamily: "malgun", height: 40, color: "white" }}
            >
              시트 이동
            </Button>
          </Stack>
        </Stack>
        <FilterToggleContext.Provider value={filterToggle}>
          <FilterContext.Provider value={filters}>
            <SortContext.Provider value={sortType}>
              <DataGrid
                className="rdg-light"
                style={{ height: `84vh` }}
                ref={gridRef}
                selectedRows={selectedRows}
                onSelectedRowsChange={handleSelectedRowsChange}
                rowClass={(
                  row: CallType,
                  rowIdx: number
                ): string | undefined => {
                  return row.COLOR === "" || row.COLOR === "white"
                    ? undefined
                    : `${row.COLOR}-background`;
                }}
                rowKeyGetter={(row: CallType) => row.CALL_ID}
                headerRowHeight={filterToggle ? 68 : DEFAULT_HEADER_HEIGHT}
                rowHeight={autoRowHeight}
                onCellClick={handleCellClick}
                onCellDoubleClick={handleDoubleClick}
                onSelectedCellChange={handleSelectedRow}
                onRowsChange={handleUpdateRow}
                sortColumns={[sortType]}
                onSortColumnsChange={handleSortColumnsChange}
                columns={columns}
                rows={filters === initFilter ? rows : filterdRows}
                bottomSummaryRows={summaryRows}
                defaultColumnOptions={{
                  sortable: true,
                  resizable: true,
                }}
                onColumnResize={(idx, width) =>
                  onColumnResize("call_list", columns, idx, width)
                }
                // onPaste={handlePaste}
                onCellKeyDown={handleCellKeyDown}
                onCopy={handleCopy}
              />
            </SortContext.Provider>
          </FilterContext.Provider>
        </FilterToggleContext.Provider>
      </Stack>
    </Stack>
  );
};
export default CallTable;

function FilterRenderer<R>({
  tabIndex,
  column,
  children,
}: RenderHeaderCellProps<R> & {
  children: (args: { tabIndex: number; filters: Filter }) => React.ReactElement;
}) {
  const filterToggle = useContext(FilterToggleContext)!;
  const filters = useContext(FilterContext)!;
  const sortType = useContext(SortContext)!;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          paddingInline: "8px",
        }}
      >
        <div
          style={{
            height: `${DEFAULT_HEADER_HEIGHT}px`,
            lineHeight: `${DEFAULT_HEADER_HEIGHT}px`,
            flex: 1,
          }}
        >
          {column.name}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {column.key === sortType.columnKey &&
            (sortType.direction === "ASC" ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            ))}
        </div>
      </div>
      {filterToggle && (
        <div
          style={{
            borderTop: "1px solid #dddddd",
            display: "flex",
          }}
        >
          {children({ tabIndex, filters })}
        </div>
      )}
    </div>
  );
}
