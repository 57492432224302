import { Chip, Stack } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DataGrid, {
  ColumnOrColumnGroup,
  RenderHeaderCellProps,
  SortColumn,
} from "react-data-grid";
import "react-data-grid/lib/styles.css";
import styled from "styled-components";
import DataTotalCount from "../../../components/common/DataTotalCount";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import { customAxios } from "../../../config";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import {
  ContentGetType,
  ContentProductType,
  ContentWithEntGetType,
  CotentModalOpenType,
} from "../../../types/ContentType";
import { errorHandler } from "../../../utils/apiUtil";
import {
  getLiveStateColor,
  getLiveStateName,
  getMediaColor,
  handleCopy,
  onColumnResize,
} from "../../../utils/commonUtil";
import { getCurrentKorDate } from "../../../utils/dateUtil";
import { generateDates } from "../../../utils/generateDates";
import { getJwtState } from "../../../utils/jwtState";
import ContentModal from "./ContentModal";

export const ContentDateButton = styled.button`
  flex: 1;
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: right;
  cursor: pointer;
  font-family: malgun;
`;

export interface ListTableProps {
  type: TabType;
  search: string;
  year: string;
  month: string;
  live: string;
  media: string;
  pType: ContentProductType;
}

const ContentListTable = forwardRef<ListTableHandle, ListTableProps>(
  ({ type, pType, search, year, month, live, media }: ListTableProps, ref) => {
    const { deptId } = getJwtState();
    const WIDTH_KEY_NAME =
      pType === "image" ? "content_image_list" : "content_video_list";
    const storedData = localStorage.getItem(WIDTH_KEY_NAME);
    const widths = storedData ? JSON.parse(storedData) : undefined;
    const targetDate = getCurrentKorDate(); // YYYYMMDD 형식
    const [rows, setRows] = useState<ContentWithEntGetType[]>([]);
    const gridRef = useRef<HTMLDivElement>(null);
    const selectedRowId = useRef<number>();
    const dayList = useMemo(() => generateDates(year + month), [year, month]);
    const [openModal, setOpenModel] = useState<CotentModalOpenType>();
    const [admin, setAdmin] = useState(false);
    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태

    const getIsAdmin = async () => {
      try {
        if (deptId !== undefined) {
          const index = [
            "C007Z",
            "C007Y",
            "C007A",
            "C007H",
            "C007I",
            "C007K",
          ].findIndex((v) => v === deptId);
          if (index > -1) {
            setAdmin(true);
          }
        }
      } catch (e: unknown) {
        errorHandler(e);
      }
    };

    useEffect(() => {
      getIsAdmin();
    }, []);

    const scrollToDateColumn = () => {
      const columnIndex = dayList.findIndex((date) => date === targetDate);
      if (gridRef.current && columnIndex !== -1) {
        const grid = gridRef.current.querySelector(".rdg");
        if (grid) {
          const columnWidth = 90;
          grid.scrollLeft = columnIndex * columnWidth;
        }
      }
    };

    useEffect(() => {
      if (rows.length > 0) {
        // scrollToDateColumn();
      }
    }, [dayList, rows]);

    const getRows = async () => {
      try {
        const response = await customAxios.get("/content", {
          params: {
            type,
            pType,
            search,
            admin,
            year,
            month,
            live: live === "전체" ? "" : live,
            media: media === "전체" ? "" : media,
            deptId,
          },
        });
        const data: ContentWithEntGetType[] = response.data;
        setRows(data);
      } catch (e) {
        errorHandler(e);
      }
    };

    useImperativeHandle(ref, () => ({
      triggerSearch: () => {
        getRows();
      },
      triggerExcelDownload: () => {},
    }));

    useEffect(() => {
      getRows();
    }, [year, month, live, admin]);

    const onClickOpenModel = (
      ENT_ID: number,
      ENT_NM: string,
      CONTENT_DATE: string,
      IS_CREATE: boolean
    ) => {
      setOpenModel({
        type,
        ENT_ID,
        ENT_NM,
        CONTENT_DATE,
        IS_CREATE,
      });
    };

    //몇번째 줄이 수정중인지 파악함
    const handleSelectedRow = (cell: any) => {
      selectedRowId.current = cell.rowIdx;
    };

    const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
      setSortColumns(newSortColumns);

      if (newSortColumns.length === 0) {
        setRows([...rows]); // 원래 데이터 유지
      } else {
        const sortedRows = [...rows].sort((a, b) => {
          for (const { columnKey, direction } of newSortColumns) {
            const sortOrder = direction === "ASC" ? 1 : -1;

            if (dayList.findIndex((v) => v === columnKey) > -1) {
              const index1 = a.CONTENT_LIST.findIndex(
                (v) => v.CONTENT_DATE === columnKey
              );
              const index2 = b.CONTENT_LIST.findIndex(
                (v) => v.CONTENT_DATE === columnKey
              );
              const amount1 =
                index1 !== -1
                  ? a.CONTENT_LIST[index1].TOTAL_COUNT
                  : Number.NEGATIVE_INFINITY;
              const amount2 =
                index2 !== -1
                  ? b.CONTENT_LIST[index2].TOTAL_COUNT
                  : Number.NEGATIVE_INFINITY;

              if (amount1 < amount2) return -1 * sortOrder;
              if (amount1 > amount2) return 1 * sortOrder;
            } else {
              const aValue = (a as Record<string, any>)[columnKey];
              const bValue = (b as Record<string, any>)[columnKey];

              // aValue와 bValue가 undefined거나 null인 경우를 처리
              if (aValue === undefined || aValue === null) return 1 * sortOrder;
              if (bValue === undefined || bValue === null)
                return -1 * sortOrder;

              if (aValue < bValue) return -1 * sortOrder;
              if (aValue > bValue) return 1 * sortOrder;
            }
          }
          return 0; // 모든 조건이 같을 때
        });
        setRows(sortedRows);
      }
    };

    const summaryRows = useMemo((): any[] => {
      const n: {
        [key in string]: number;
      } = {}; // 분자
      const d: {
        [key in string]: number;
      } = {}; // 분모
      let total_n = 0;
      let total_d = 0;

      for (let i = 0; i < dayList.length; i++) {
        n[dayList[i]] = 0;
        d[dayList[i]] = 0;
      }

      for (let i = 0; i < rows.length; i++) {
        for (let j = 0; j < rows[i].CONTENT_LIST.length; j++) {
          const item = rows[i].CONTENT_LIST[j];
          const CONTENT_DATE = item.CONTENT_DATE;
          const CONTENT_STATE_CD = item.CONTENT_STATE_CD;
          const COUNT = item.CONTENT_COUNT;

          d[CONTENT_DATE] += COUNT;
          if (CONTENT_STATE_CD !== "C026A") {
            n[CONTENT_DATE] += COUNT;
            total_n += COUNT;
          }
          total_d += COUNT;
        }
      }

      return [
        {
          sum_n: n,
          sum_d: d,
          total_n,
          total_d,
        },
      ];
    }, [rows, year, month]);

    //컬럼 테이블 생성
    const columns: ColumnOrColumnGroup<ContentWithEntGetType, any>[] = [
      {
        key: "ENT_NM",
        name: "사업자명",
        frozen: true,
        resizable: true,
        minWidth: getColumnWidth(widths, "ENT_NM", 120),
        headerCellClass: "text-center grid-header-font",
        cellClass: "grid-cell-font",
        renderSummaryCell({ row }: any) {
          return <DataTotalCount totalCount={rows.length} />;
        },
      },
      {
        key: "MEDIA",
        name: "매체",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        frozen: true,
        width: getColumnWidth(widths, "MEDIA", 100),
        renderCell({ row, onRowChange }: any) {
          return (
            <div
              style={{ textAlign: "center", color: getMediaColor(row.MEDIA) }}
            >
              {row.MEDIA}
            </div>
          );
        },
        editable: false,
      },
      {
        key: "B_NAME",
        name: "브랜드명",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "B_NAME", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "grid-cell-font",
      },
      {
        key: "TEAM_ACCOUNT",
        name: "팀 계정",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "TEAM_ACCOUNT", 100),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
      },
      {
        key: "LIVE",
        name: "라이브 상태",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "LIVE", 100),
        headerCellClass: "text-center grid-header-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              <Chip
                label={getLiveStateName(row.LIVE)}
                variant="outlined"
                size="small"
                sx={{
                  color: getLiveStateColor(row.LIVE),
                  borderColor: getLiveStateColor(row.LIVE),
                }}
              />
            </div>
          );
        },
      },
      {
        key: "SALESMAN_NM",
        name: "영업 담당자",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        frozen: true,
        editable: false,
        width: getColumnWidth(widths, "SALESMAN_NM", 100),
      },
      {
        key: "MARKETER_NM",
        name: "마케터",
        frozen: true,
        resizable: true,
        minWidth: getColumnWidth(widths, "MARKETER_NM", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
      },
      ...(pType === "image"
        ? [
            {
              key: "DESIGNER_NM",
              name: "디자이너",
              frozen: true,
              resizable: true,
              minWidth: getColumnWidth(widths, "DESIGNER_NM", 80),
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
            },
          ]
        : []),
      ...(pType === "video"
        ? [
            {
              key: "CREATOR_NM",
              name: "영상",
              frozen: true,
              resizable: true,
              minWidth: getColumnWidth(widths, "CREATOR_NM", 80),
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
            },
          ]
        : []),
      {
        key: "TOTAL_CONTENT_COUNT",
        name: "합계",
        frozen: true,
        resizable: true,
        minWidth: getColumnWidth(widths, "TOTAL_CONTENT_COUNT", 80),
        headerCellClass: "text-center grid-header-font",
        renderCell: ({ row }: any) => {
          const info: ContentWithEntGetType = row;
          const CONTENT_LIST: ContentGetType[] = info.CONTENT_LIST;
          const MATCHING_LIST = CONTENT_LIST.filter(
            (v) => v.CONTENT_STATE_CD !== "C026A"
          );

          const date = year + month;

          const n = MATCHING_LIST.reduce(
            (sum, item) => sum + item.CONTENT_COUNT,
            0
          ).toLocaleString();
          const d = CONTENT_LIST.reduce(
            (sum, item) => sum + item.CONTENT_COUNT,
            0
          ).toLocaleString();

          return (
            <ContentDateButton
              onClick={() =>
                onClickOpenModel(row.ENT_ID, row.ENT_NM, date, false)
              }
            >
              {d === "0" ? 0 : n + " / " + d}
            </ContentDateButton>
          );
        },
        renderSummaryCell({ row }: any) {
          const total_n = row.total_n;
          const total_d = row.total_d;

          return (
            <div style={{ textAlign: "right" }}>
              {total_d === 0
                ? 0
                : total_n.toLocaleString() + " / " + total_d.toLocaleString()}
            </div>
          );
        },
      },
      ...generateDates(year + month).map((date) => {
        return {
          key: date,
          name: date,
          width: getColumnWidth(widths, date, 90),
          cellClass: date === targetDate ? "today-focus-cell" : "",
          renderHeaderCell: ({
            column,
          }: RenderHeaderCellProps<ContentWithEntGetType, any>) => {
            let sortType = "";
            if (
              sortColumns.length > 0 &&
              sortColumns[0].columnKey === column.key
            ) {
              const { direction } = sortColumns[0];
              sortType = direction;
            }

            return (
              <div
                className="text-center grid-header-font"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {date.slice(0, 4) +
                  "-" +
                  date.slice(4, 6) +
                  "-" +
                  date.slice(6, 8)}
                {sortType !== "" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {sortType === "ASC" && <ArrowDropUpIcon />}
                    {sortType === "DESC" && <ArrowDropDownIcon />}
                  </div>
                )}
              </div>
            );
          },
          renderCell: ({ row }: any) => {
            const info: ContentWithEntGetType = row;
            const CONTENT_LIST: ContentGetType[] = info.CONTENT_LIST;
            const matchingItems = CONTENT_LIST.filter(
              (item) => item.CONTENT_DATE === date
            );
            const doneItems = matchingItems.filter(
              (v) => v.CONTENT_STATE_CD !== "C026A"
            );

            const n = doneItems
              .reduce((sum, item) => sum + item.CONTENT_COUNT, 0)
              .toLocaleString();
            const d = matchingItems
              .reduce((sum, item) => sum + item.CONTENT_COUNT, 0)
              .toLocaleString();

            return (
              <ContentDateButton
                onClick={() =>
                  onClickOpenModel(
                    row.ENT_ID,
                    row.ENT_NM,
                    date,
                    type === "" ? false : true
                  )
                }
              >
                {d === "0" ? 0 : n + " / " + d}
              </ContentDateButton>
            );
          },
          renderSummaryCell({ row }: any) {
            const n = row.sum_n[date].toLocaleString();
            const d = row.sum_d[date].toLocaleString();
            return (
              <div style={{ textAlign: "right" }}>
                {row.sum_n[date] === undefined && row.sum_d[date] === undefined
                  ? 0
                  : d == 0
                  ? 0
                  : n + " / " + d}
              </div>
            );
          },
        };
      }),
    ];

    return (
      <Stack flex={1}>
        {openModal !== undefined && (
          <ContentModal
            open={openModal}
            pType={pType}
            onSubmit={() => {
              setOpenModel(undefined);
              getRows();
            }}
          />
        )}

        {rows && (
          <div
            ref={gridRef}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DataGrid
              className="rdg-light"
              style={{ height: `84vh` }}
              onSelectedCellChange={handleSelectedRow}
              sortColumns={sortColumns}
              onSortColumnsChange={handleSortColumnsChange}
              columns={columns}
              rows={rows}
              bottomSummaryRows={summaryRows}
              defaultColumnOptions={{
                resizable: true,
                sortable: true,
              }}
              onColumnResize={(idx, width) =>
                onColumnResize(WIDTH_KEY_NAME, columns, idx, width)
              }
              onCopy={handleCopy}
            />
          </div>
        )}
      </Stack>
    );
  }
);

export default ContentListTable;
