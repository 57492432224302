import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Box,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import {
  PopupCloseButton,
  TableCol2,
  TableContainer,
  TableMultiInfoCol1,
  TableRow,
} from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import { isEmpty } from "../../../utils/isEmpty";

export type LivePeopleChangePopupType =
  | "salesman"
  | "marketer"
  | "designer"
  | "creator";

export type LivePeopleChangePopupOpenType = EntGetType & {
  type: LivePeopleChangePopupType;
};

interface Props {
  open: LivePeopleChangePopupOpenType;
  onSubmit: () => void;
  onCancel: () => void;
}

type BusinessUserType = {
  USER_ID: string;
  USER_NAME: string;
};

export default function LivePeopleChangePopup({
  open,
  onSubmit,
  onCancel,
}: Props) {
  const [origin, setOrigin] = useState<BusinessUserType>();
  const [target, setTarget] = useState<BusinessUserType[]>([]);
  const [selectedUser, setSelectedUser] = useState<BusinessUserType | null>(
    null
  ); // 선택된 영업사원

  /**
   * API 호출
   */
  const getBusinessUser = async (DEPT_ID: string) => {
    try {
      const response = await customAxios.get("/user/dept", {
        params: {
          DEPT_ID,
        },
      });

      setTarget(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    if (open) {
      if (open.type === "salesman") {
        setOrigin({
          USER_ID: open.SALESMAN_ID,
          USER_NAME: isEmpty(open.SALESMAN_ID) ? "미지정" : open.SALESMAN_NM,
        });

        getBusinessUser("C007B"); // 영업팀
      } else if (open.type === "marketer") {
        setOrigin({
          USER_ID: open.MARKETER_ID,
          USER_NAME: isEmpty(open.MARKETER_ID) ? "미지정" : open.MARKETER_NM,
        });

        if (open.TYPE === "search") {
          getBusinessUser("C007D"); // 마케팅 SA
        } else {
          getBusinessUser("C007C"); // 마케팅 DA
        }
      } else if (open.type === "designer") {
        setOrigin({
          USER_ID: open.DESIGNER_ID,
          USER_NAME: isEmpty(open.DESIGNER_ID) ? "미지정" : open.DESIGNER_NM,
        });

        getBusinessUser("C007E"); // 콘텐츠 디자인
      } else if (open.type === "creator") {
        setOrigin({
          USER_ID: open.CREATOR_ID,
          USER_NAME: isEmpty(open.CREATOR_ID) ? "미지정" : open.CREATOR_NM,
        });

        if (open.TYPE === "domestic" || open.TYPE === "overseas") {
          getBusinessUser("C007F"); // 콘텐츠 영상팀
        }
      }
    }
  }, [open]);

  /**
   * 컴포넌트 제어
   */

  const handleClose = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (selectedUser) {
      try {
        if (open.type === "salesman") {
          await customAxios.put("/ent", {
            ENT_ID: open.ENT_ID,
            SALESMAN_ID: selectedUser.USER_ID,
          });
        } else if (open.type === "marketer") {
          await customAxios.put("/ent", {
            ENT_ID: open.ENT_ID,
            MARKETER_ID: selectedUser.USER_ID,
          });
        } else if (open.type === "designer") {
          await customAxios.put("/ent", {
            ENT_ID: open.ENT_ID,
            DESIGNER_ID: selectedUser.USER_ID,
          });
        } else if (open.type === "creator") {
          await customAxios.put("/ent", {
            ENT_ID: open.ENT_ID,
            CREATOR_ID: selectedUser.USER_ID,
          });
        }

        onSubmit();
      } catch (e: unknown) {
        errorHandler(e);
      }
    } else {
      popupCustomAlert("warn", "변경 담당자를 선택해주세요");
    }
  };

  const getTitle = () => {
    let title = "";
    if (open.type === "salesman") {
      title = "영업사원";
    } else if (open.type === "marketer") {
      title = "마케터";
    } else if (open.type === "designer") {
      title = "디자이너";
    } else if (open.type === "creator") {
      title = "영상 제작자";
    }

    return title;
  };

  const getTabTitle = (): string => {
    let tabTitle = "";

    if (open.TYPE === "domestic") {
      tabTitle = "국내매체광고";
    } else if (open.TYPE === "overseas") {
      tabTitle = "해외매체광고";
    } else if (open.TYPE === "search") {
      tabTitle = "검색광고";
    }
    return tabTitle;
  };

  const getTooltipTitle = (task: LivePeopleChangePopupType): string => {
    if (open.type === "salesman") {
      return "영업팀";
    }
    if (open.TYPE === "domestic") {
      if (open.type === "marketer") {
        return "마케팅 DA";
      } else if (open.type === "designer") {
        return "콘텐츠 디자인";
      } else if (open.type === "creator") {
        return "콘텐츠 영상팀";
      }
    } else if (open.TYPE === "overseas") {
      if (open.type === "marketer") {
        return "마케팅 DA";
      } else if (open.type === "designer") {
        return "콘텐츠 디자인";
      }
    } else if (open.TYPE === "search") {
      if (open.type === "marketer") {
        return "마케팅 SA";
      }
    }
    return "";
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      fullScreen={false}
      maxWidth={"sm"}
    >
      <Stack pl={5} pr={5} pt={3} pb={3} gap={2}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Box alignContent={"center"}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "17px",
                fontFamily: "malgun",
              }}
            >
              [{getTabTitle()}] {getTitle()} 변경
            </span>
          </Box>
          <Box>
            <PopupCloseButton onClick={handleClose} autoFocus>
              <ClearIcon sx={{ color: "#BDBDBD" }} />
            </PopupCloseButton>
          </Box>
        </Stack>
        <TableContainer>
          <TableRow>
            <TableMultiInfoCol1>현재 담당자</TableMultiInfoCol1>
            <TableCol2
              style={{ display: "flex", flexDirection: "row", gap: 3 }}
            >
              <OutlinedInput
                id="outlined-creator"
                value={origin?.USER_ID}
                size="small"
                readOnly
              />
              {origin !== undefined && (
                <Autocomplete
                  options={[origin]}
                  getOptionLabel={(option) => option.USER_NAME}
                  isOptionEqualToValue={(option, value) =>
                    option.USER_ID === value.USER_ID
                  }
                  defaultValue={origin}
                  value={origin}
                  renderInput={(params) => <TextField {...params} />}
                  size="small"
                  fullWidth
                  readOnly
                />
              )}
            </TableCol2>
          </TableRow>
          <TableRow>
            <TableMultiInfoCol1>
              변경 담당자
              <Tooltip title={getTooltipTitle(open.type)} placement="top">
                <InfoOutlinedIcon
                  sx={{
                    fontSize: "11px",
                    color: "#1976d2",
                    ml: 1,
                  }}
                />
              </Tooltip>
            </TableMultiInfoCol1>
            <TableCol2
              style={{ display: "flex", flexDirection: "row", gap: 3 }}
            >
              <OutlinedInput
                id="outlined-creator"
                value={selectedUser !== null ? selectedUser?.USER_ID : ""}
                size="small"
                readOnly
              />
              <Autocomplete
                options={target.filter((v) => v.USER_ID !== origin?.USER_ID)}
                getOptionLabel={(option) => option.USER_NAME}
                isOptionEqualToValue={(option, value) =>
                  option.USER_ID === value.USER_ID
                }
                onChange={(event, value) => setSelectedUser(value)}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                fullWidth
              />
            </TableCol2>
          </TableRow>
        </TableContainer>

        <div style={{ display: "flex", gap: 5 }}>
          <Button variant="outlined" onClick={handleClose} sx={{ flex: 1 }}>
            취소
          </Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ flex: 1 }}>
            변경
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}
