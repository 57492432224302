import { Chip, Stack } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import DataGrid, { ColumnOrColumnGroup, SortColumn } from "react-data-grid";
import "react-data-grid/lib/styles.css";
import CustomCheckbox from "../../../components/common/CustomCheckbox";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import DataTotalCount, {
  DataCount,
} from "../../../components/common/DataTotalCount";
import { HostingSelect } from "../../../components/shared/cell-renderers/HostingSelect";
import { MediaSelect } from "../../../components/shared/cell-renderers/MediaSelect";
import { ProductSelect } from "../../../components/shared/cell-renderers/ProductSelect";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import { autoRowHeight } from "../../../components/shared/lib/autoRowHeignt";
import { MultiRowCell } from "../../../components/shared/lib/multiRowCell";
import { customAxios } from "../../../config";
import { SalesListTableHandle, TabType } from "../../../types/CommonType";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import {
  getLiveStateColor,
  getLiveStateName,
  getMediaColor,
  handleCopy,
  onColumnResize,
} from "../../../utils/commonUtil";
import { getJwtState } from "../../../utils/jwtState";
import SalesListChangePeoplePopup from "./SalesListChangePeoplePopup";

export interface TotalSalesListTableProps {
  type: TabType;
  search: string;
  live: string;
}

const TotalSalesListTable = forwardRef<
  SalesListTableHandle,
  TotalSalesListTableProps
>(({ type, search, live }: TotalSalesListTableProps, ref) => {
  const { userId } = getJwtState();
  const [rows, setRows] = useState<EntGetType[]>([]);
  const storedData = localStorage.getItem("total_sales_list");
  const widths = storedData ? JSON.parse(storedData) : undefined;
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태

  const [selectedRows, setSelectedRows] = useState<ReadonlySet<number>>(
    new Set()
  );
  const [openChangePeopleModal, setOpenChangePeopleModal] = useState<
    EntGetType[]
  >([]);

  const getRows = async () => {
    try {
      const response = await customAxios.get("/ent", {
        params: {
          type,
          search,
          admin: true,
          live,
        },
      });
      const data: EntGetType[] = response.data;
      setRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  useImperativeHandle(ref, () => ({
    triggerSearch: () => {
      getRows();
      setSelectedRows(new Set());
    },
    triggerExcelDownload: () => {},
    triggerChangeSalesPeople: () => {
      const selectedIds = Array.from(selectedRows);
      const size = selectedIds.length;

      if (size === 0) {
        popupCustomAlert("warn", `선택된 데이터가 없습니다`);
        return;
      }

      setOpenChangePeopleModal(rows.filter((v) => selectedRows.has(v.ENT_ID)));
    },
  }));

  useEffect(() => {
    getRows();
    setSelectedRows(new Set());
  }, [live]);

  // 정렬 처리 핸들러
  const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
    setSortColumns(newSortColumns);

    if (newSortColumns.length === 0) {
      setRows([...rows]); // 원래 데이터 유지
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        for (const { columnKey, direction } of newSortColumns) {
          const sortOrder = direction === "ASC" ? 1 : -1;
          const aValue = (a as Record<string, any>)[columnKey];
          const bValue = (b as Record<string, any>)[columnKey];

          // aValue와 bValue가 undefined거나 null인 경우를 처리
          if (aValue === undefined || aValue === null) return 1 * sortOrder;
          if (bValue === undefined || bValue === null) return -1 * sortOrder;

          if (aValue < bValue) return -1 * sortOrder;
          if (aValue > bValue) return 1 * sortOrder;
        }
        return 0; // 모든 조건이 같을 때
      });
      setRows(sortedRows);
    }
  };

  const handleSheetChangeModalSubmit = (type: "people" | "sheet") => {
    if (type === "people") {
      setOpenChangePeopleModal([]);
    }

    setSelectedRows(new Set());
    getRows();
  };

  // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  //컬럼 테이블 생성
  const columns: ColumnOrColumnGroup<EntGetType, any>[] = [
    {
      key: "CHK",
      name: "선택",
      headerCellClass: "text-center",
      frozen: true,
      width: getColumnWidth(widths, "CHK", 70),
      renderHeaderCell: () => {
        const isAllSelected =
          rows.length > 0 && rows.every((row) => selectedRows.has(row.ENT_ID));

        const handleSelectAllChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          if (event.target.checked) {
            const allRowIds = new Set(rows.map((row) => row.ENT_ID));
            setSelectedRows(allRowIds);
          } else {
            setSelectedRows(new Set());
          }
        };

        return (
          <CustomCheckbox
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
        );
      },
      renderCell: ({ row }: any) => {
        const handleRowCheckboxChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          setSelectedRows((prevSelectedRows) => {
            const updatedSelectedRows = new Set(prevSelectedRows);
            if (event.target.checked) {
              updatedSelectedRows.add(row.ENT_ID);
            } else {
              updatedSelectedRows.delete(row.ENT_ID);
            }
            return updatedSelectedRows;
          });
        };

        return (
          <CustomCheckbox
            checked={selectedRows.has(row.ENT_ID)}
            onChange={handleRowCheckboxChange}
          />
        );
      },
      renderSummaryCell({ row }: any) {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <DataCount totalCount={selectedRows.size} />
          </div>
        );
      },
    },
    {
      key: "LIVE_REQUEST",
      name: "라이브 상태",
      frozen: true,
      resizable: true,
      headerCellClass: "text-center grid-header-font",
      width: getColumnWidth(widths, "LIVE_REQUEST_STATE", 100),
      renderCell({ row, onRowChange }: any) {
        return (
          <div style={{ color: "black", textAlign: "center" }}>
            <Chip
              label={getLiveStateName(row.LIVE)}
              variant="outlined"
              size="small"
              sx={{
                color: getLiveStateColor(row.LIVE),
                borderColor: getLiveStateColor(row.LIVE),
                fontFamily: "malgun",
              }}
            />
          </div>
        );
      },
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    {
      key: "SALESMAN_NM",
      name: "영업 담당자",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "SALESMAN_NM", 100),
    },
    ...(type === "domestic" || type === "overseas"
      ? [
          {
            key: "P_NAME",
            name: "상품",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            renderEditCell: ProductSelect,
            width: getColumnWidth(widths, "P_NAME", 100),
            editable: false,
          },
        ]
      : []),
    ...(type === "search"
      ? [
          {
            key: "S_PRODUCT",
            name: "광고 상품",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            width: getColumnWidth(widths, "S_PRODUCT", 100),
          },
        ]
      : []),
    {
      key: "MEDIA",
      name: "매체",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "MEDIA", 100),
      renderEditCell: MediaSelect,
      renderCell({ row, onRowChange }: any) {
        return (
          <div style={{ textAlign: "center", color: getMediaColor(row.MEDIA) }}>
            {row.MEDIA}
          </div>
        );
      },
      editable: false,
    },
    ...(type === "domestic"
      ? [
          {
            key: "D_ACCOUNT_NUMBER",
            name: "계정번호",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            ...MultiRowCell(),
            editable: false,
            width: getColumnWidth(widths, "D_ACCOUNT_NUMBER", 100),
          },
          {
            key: "D_ACCOUNT_NM",
            name: "계정명",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            ...MultiRowCell(),
            editable: false,
            width: getColumnWidth(widths, "D_ACCOUNT_NM", 100),
          },
        ]
      : []),
    ...(type === "overseas"
      ? [
          {
            key: "O_BUSINESS_ACCOUNT_NM",
            name: "계정번호",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            ...MultiRowCell(),
            editable: false,
            width: getColumnWidth(widths, "O_BUSINESS_ACCOUNT_NM", 100),
          },
          {
            key: "O_AD_ACCOUNT_NM",
            name: "계정명",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            ...MultiRowCell(),
            editable: false,
            width: getColumnWidth(widths, "O_AD_ACCOUNT_NM", 100),
          },
          {
            key: "O_PROGRESS_METHOD",
            name: "진행방식",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            ...MultiRowCell(),
            editable: false,
            width: getColumnWidth(widths, "O_PROGRESS_METHOD", 100),
          },
        ]
      : []),
    ...(type === "search"
      ? [
          {
            key: "S_SEARCH_AD_ID",
            name: "검색광고 아이디",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            ...MultiRowCell(),
            editable: false,
            width: getColumnWidth(widths, "S_SEARCH_AD_ID", 100),
          },
        ]
      : []),
    {
      key: "ENT_NM",
      name: "사업자명",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      ...MultiRowCell(),
      editable: false,
      width: getColumnWidth(widths, "ENT_NM", 100),
    },
    {
      key: "ENT_NUMBER",
      name: "사업자번호",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      ...MultiRowCell(),
      editable: false,
      width: getColumnWidth(widths, "ENT_NUMBER", 100),
    },
    {
      key: "B_NAME",
      name: "브랜드명",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      ...MultiRowCell(),
      editable: false,
      width: getColumnWidth(widths, "B_NAME", 100),
    },
    {
      key: "H_NAME",
      name: "호스팅사",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      renderEditCell: HostingSelect,
      editable: false,
      width: getColumnWidth(widths, "H_NAME", 100),
    },
    {
      key: "CATEGORY",
      name: "제품 카테고리",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      ...MultiRowCell(),
      editable: false,
      width: getColumnWidth(widths, "CATEGORY", 100),
    },
    {
      key: "P_URLS",
      name: "진행제품 URL",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      ...MultiRowCell(),
      editable: false,
      width: getColumnWidth(widths, "P_URLS", 100),
    },
    {
      key: "ENT_CONTACT_NAME",
      name: "업체 담당자 이름",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      ...MultiRowCell(),
      editable: false,
      width: getColumnWidth(widths, "ENT_CONTACT_NAME", 100),
    },
    {
      key: "ENT_CONTACT_POSITION",
      name: "업체 담당자 직책",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      ...MultiRowCell(),
      editable: false,
      width: getColumnWidth(widths, "ENT_CONTACT_POSITION", 100),
    },
    {
      key: "ENT_CONTACT_NUMBER",
      name: "업체 담당자 연락처",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      ...MultiRowCell(),
      editable: false,
      width: getColumnWidth(widths, "ENT_CONTACT_NUMBER", 100),
    },
    {
      key: "ENT_CONTACT_EMAIL",
      name: "업체 담당자 이메일",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      ...MultiRowCell(),
      editable: false,
      width: getColumnWidth(widths, "ENT_CONTACT_EMAIL", 100),
    },
    {
      key: "ENT_NOTES",
      name: "특이사항",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "ENT_NOTES", 200),
      ...MultiRowCell(),
      editable: false,
    },
    ...(type === "overseas"
      ? [
          {
            key: "O_PAYER_NM",
            name: "입금자명",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            ...MultiRowCell(),
            editable: false,
            width: getColumnWidth(widths, "O_PAYER_NM", 100),
          },
          {
            key: "TAX_INVOICE_EMAIL",
            name: "세금계산서 발행 이메일",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            ...MultiRowCell(),
            width: getColumnWidth(widths, "TAX_INVOICE_EMAIL", 100),
          },
          {
            key: "IS_CONTRACT_SIGN",
            name: "계약서 서명 여부",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            renderCell({ row, onRowChange }: any) {
              return (
                <CustomCheckbox
                  checked={!!row.IS_CONTRACT_SIGN}
                  onChange={(event: any) =>
                    onRowChange(
                      { ...row, IS_CONTRACT_SIGN: event.target.checked },
                      true
                    )
                  }
                />
              );
            },
            width: getColumnWidth(widths, "IS_CONTRACT_SIGN", 100),
          },
          {
            key: "IS_TAX_INVOICE_ISSUED",
            name: "세금계산서 발행",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            renderCell({ row, onRowChange }: any) {
              return (
                <CustomCheckbox
                  checked={!!row.IS_TAX_INVOICE_ISSUED}
                  onChange={(event: any) =>
                    onRowChange(
                      { ...row, IS_TAX_INVOICE_ISSUED: event.target.checked },
                      true
                    )
                  }
                />
              );
            },
            width: getColumnWidth(widths, "IS_TAX_INVOICE_ISSUED", 100),
          },
          {
            key: "IS_ACCOUNT_PERMISSION_GRANTED",
            name: "계정 권한 부여",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            renderCell({ row, onRowChange }: any) {
              return (
                <CustomCheckbox
                  checked={!!row.IS_ACCOUNT_PERMISSION_GRANTED}
                  onChange={(event: any) =>
                    onRowChange(
                      {
                        ...row,
                        IS_ACCOUNT_PERMISSION_GRANTED: event.target.checked,
                      },
                      true
                    )
                  }
                />
              );
            },
            width: getColumnWidth(widths, "IS_ACCOUNT_PERMISSION_GRANTED", 100),
          },
          {
            key: "IS_AD_CHARGE_REPLY_RECEIVED",
            name: "광고비 충전 회신",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            renderCell({ row, onRowChange }: any) {
              return (
                <CustomCheckbox
                  checked={!!row.IS_AD_CHARGE_REPLY_RECEIVED}
                  onChange={(event: any) =>
                    onRowChange(
                      {
                        ...row,
                        IS_AD_CHARGE_REPLY_RECEIVED: event.target.checked,
                      },
                      true
                    )
                  }
                />
              );
            },
            width: getColumnWidth(widths, "IS_AD_CHARGE_REPLY_RECEIVED", 100),
          },
        ]
      : [
          {
            key: "IS_CONV_TRACK",
            name: "영업팀 전환추적 체크",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            renderCell({ row, onRowChange }: any) {
              return (
                <CustomCheckbox
                  checked={!!row.IS_CONV_TRACK}
                  onChange={(event: any) =>
                    onRowChange(
                      { ...row, IS_CONV_TRACK: event.target.checked },
                      true
                    )
                  }
                />
              );
            },
            width: getColumnWidth(widths, "IS_CONV_TRACK", 100),
          },
          {
            key: "IS_TRANSFER_CHECK",
            name: "영업팀 이관체크 ",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            renderCell({ row, onRowChange }: any) {
              return (
                <CustomCheckbox
                  checked={!!row.IS_TRANSFER_CHECK}
                  onChange={(event: any) =>
                    onRowChange(
                      { ...row, IS_TRANSFER_CHECK: event.target.checked },
                      true
                    )
                  }
                />
              );
            },
            width: getColumnWidth(widths, "IS_TRANSFER_CHECK", 100),
          },
        ]),
    {
      key: "IS_MAIN_ACCOUNT_PERMISSION_CHECKED",
      name: "대표 계정 권한 체크",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      renderCell({ row, onRowChange }: any) {
        return (
          <CustomCheckbox
            checked={!!row.IS_MAIN_ACCOUNT_PERMISSION_CHECKED}
            onChange={(event: any) =>
              onRowChange(
                {
                  ...row,
                  IS_MAIN_ACCOUNT_PERMISSION_CHECKED: event.target.checked,
                },
                true
              )
            }
          />
        );
      },
      width: getColumnWidth(widths, "IS_MAIN_ACCOUNT_PERMISSION_CHECKED", 100),
    },
  ];

  return (
    <Stack flex={1}>
      {openChangePeopleModal.length > 0 && (
        <SalesListChangePeoplePopup
          open={openChangePeopleModal}
          onSubmit={() => handleSheetChangeModalSubmit("people")}
          onCancel={() => setOpenChangePeopleModal([])}
        />
      )}
      <DataGrid
        className="rdg-light"
        style={{ height: `84vh` }}
        rowHeight={autoRowHeight}
        columns={columns}
        sortColumns={sortColumns}
        onSortColumnsChange={handleSortColumnsChange}
        rows={rows}
        bottomSummaryRows={summaryRows} // 하단 요약 행 추가
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        onColumnResize={(idx, width) =>
          onColumnResize("total_sales_list", columns, idx, width)
        }
        onCopy={handleCopy}
      />
    </Stack>
  );
});

export default TotalSalesListTable;
