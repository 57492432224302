import ClearIcon from "@mui/icons-material/Clear";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import SegmentSharpIcon from "@mui/icons-material/SegmentSharp";
import { Dialog } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, { ColumnOrColumnGroup, SortColumn } from "react-data-grid";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import DataTotalCount, {
  DataCount,
} from "../../../components/common/DataTotalCount";
import { ContentStateSelect } from "../../../components/shared/cell-renderers/ContentStateSelect";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import { autoRowHeight } from "../../../components/shared/lib/autoRowHeignt";
import { MultiRowCell } from "../../../components/shared/lib/multiRowCell";
import { CallButton } from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import {
  ContentItemGetType,
  ContentProductType,
  CotentModalOpenType,
} from "../../../types/ContentType";
import { errorHandler } from "../../../utils/apiUtil";
import {
  getContentStateColor,
  handleCopy,
  onColumnResize,
} from "../../../utils/commonUtil";
import { getJwtState } from "../../../utils/jwtState";

interface ContentModalProps {
  open: CotentModalOpenType;
  pType: ContentProductType;
  onSubmit: () => void;
}

const ContentModal: React.FC<ContentModalProps> = ({
  open,
  pType,
  onSubmit,
}) => {
  const WIDTH_KEY_NAME = "content_modal";
  const storedData = localStorage.getItem(WIDTH_KEY_NAME);
  const widths = storedData ? JSON.parse(storedData) : undefined;
  const { userId, deptId } = getJwtState();
  const isTotal = open.CONTENT_DATE.length === 6;
  const CONTENT_DIV_CD = pType === "image" ? "C025A" : "C025B";
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);
  const [rows, setRows] = useState<ContentItemGetType[]>([]);
  const selectedRowId = useRef<number>();
  const selectedColumnKey = useRef<string>();

  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
    selectedColumnKey.current = cell.column.key;
  };

  const getRows = async () => {
    try {
      const response = await customAxios.get("/content/list", {
        params: {
          ENT_ID: open.ENT_ID,
          CONTENT_DIV_CD,
          CONTENT_DATE: open.CONTENT_DATE,
          deptId,
        },
      });

      const data: ContentItemGetType[] = response.data;
      setRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getRows();
  }, [open]);

  const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
    setSortColumns(newSortColumns);

    if (newSortColumns.length === 0) {
      setRows([...rows]); // 원래 데이터 유지
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        for (const { columnKey, direction } of newSortColumns) {
          const sortOrder = direction === "ASC" ? 1 : -1;
          const aValue = (a as Record<string, any>)[columnKey];
          const bValue = (b as Record<string, any>)[columnKey];

          // aValue와 bValue가 undefined거나 null인 경우를 처리
          if (aValue === undefined || aValue === null) return 1 * sortOrder;
          if (bValue === undefined || bValue === null) return -1 * sortOrder;

          if (aValue < bValue) return -1 * sortOrder;
          if (aValue > bValue) return 1 * sortOrder;
        }
        return 0; // 모든 조건이 같을 때
      });
      setRows(sortedRows);
    }
  };

  const handleUpdateRow = async (newData: any, oldData: any) => {
    const rowId = selectedRowId.current || 0;
    const rowColumnName = selectedColumnKey.current || "";

    let row: ContentItemGetType = newData[rowId];
    const CONTENT_ID = row.CONTENT_ID;

    try {
      if (rowColumnName === "CONTENT_STATE_CD") {
        const value = row.CONTENT_STATE_CD;

        await customAxios.put("/content", {
          CONTENT_ID,
          CONTENT_STATE_CD: value,
        });

        popupCustomAlert(
          "success",
          "상태를 변경했습니다",
          "update-content-state"
        );
      } else if (rowColumnName === "MEMO") {
        const value = row.MEMO;

        await customAxios.put("/content", {
          CONTENT_ID,
          MEMO: value,
        });

        popupCustomAlert(
          "success",
          "특이사항 내용을 수정했습니다",
          "update-content-memo"
        );
      } else if (rowColumnName === "NAS_PATH") {
        const value = row.NAS_PATH;

        await customAxios.put("/content", {
          CONTENT_ID,
          NAS_PATH: value,
          MANAGER_ID: userId,
        });

        popupCustomAlert(
          "success",
          "NAS 경로를 변경했습니다",
          "update-content-nas"
        );
      }
      getRows();
    } catch (e) {
      errorHandler(e);
    }
  };

  const handleClickSaveMemo = async (row: ContentItemGetType) => {
    try {
      if (confirm(`메모를 저장하시겠습니까?`)) {
        await customAxios.put("/content", {
          ...row,
          CONTENT_ID: row.CONTENT_ID,
          MEMO: row.MEMO,
        });
        getRows();
        popupCustomAlert("success", "메모를 저장했습니다.");
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  const handleClose = () => {
    onSubmit();
  };

  const formatDateString = (date: string) => {
    if (date.length === 6) {
      return date.slice(0, 4) + "년 " + date.slice(4, 6) + "월";
    }
    return (
      date.slice(0, 4) +
      "년 " +
      date.slice(4, 6) +
      "월 " +
      date.slice(6, 8) +
      "일"
    );
  };

  const columns: ColumnOrColumnGroup<ContentItemGetType, any>[] = [
    {
      key: "SEQ",
      name: "No",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "SEQ", 70),
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    ...(isTotal === true
      ? [
          {
            key: "CONTENT_DATE",
            name: "소재 날짜",
            headerCellClass: "text-center grid-header-font",
            cellClass: "text-center grid-cell-font",
            editable: false,
            width: getColumnWidth(widths, "CONTENT_DATE", 70),
            renderCell({ row, onRowChange }: any) {
              return (
                <div className="text-center grid-cell-font">
                  {formatDateString(row.CONTENT_DATE)}
                </div>
              );
            },
          },
        ]
      : []),
    {
      key: "CONTENT_DIV_NM",
      name: "구분",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "CONTENT_DIV_NM", 70),
    },
    {
      key: "CONTENT_STATE_CD",
      name: "상태",
      headerCellClass: "text-center grid-header-font",
      renderHeaderCell() {
        return (
          <div
            className="flex-box flex-box-row"
            style={{ justifyContent: "space-between" }}
          >
            <span className="flex">상태</span>
            <div className="flex-box" style={{ alignItems: "center" }}>
              <SegmentSharpIcon style={{ fontSize: 15, color: "#9b9898" }} />
            </div>
          </div>
        );
      },
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "CONTENT_STATE_CD", 70),
      renderCell({ row }: { row: ContentItemGetType }) {
        return (
          <div
            className="text-center grid-cell-font"
            style={{
              color: getContentStateColor(row.CONTENT_STATE_CD),
            }}
          >
            {row.CONTENT_STATE_NM}
          </div>
        );
      },
      renderEditCell: ContentStateSelect,
    },
    {
      key: "REQUEST_NM",
      name: "마케터",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "REQUEST_NM", 70),
    },
    {
      key: "MANAGER_NM",
      name: "제작자",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "MANAGER_NM", 70),
    },
    {
      key: "NAS_PATH",
      name: "나스 경로",
      renderHeaderCell() {
        return (
          <div
            className="flex-box flex-box-row"
            style={{ justifyContent: "space-between" }}
          >
            <span>나스 경로</span>
            <div className="flex-box" style={{ alignItems: "center" }}>
              <EditSharpIcon style={{ fontSize: 15, color: "#9b9898" }} />
            </div>
          </div>
        );
      },
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "NAS_PATH", 200),
      ...MultiRowCell(),
    },
    {
      key: "CONTENT_COUNT",
      name: "소재 개수",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      summaryCellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "CONTENT_COUNT", 70),
      renderSummaryCell({ row }: any) {
        return (
          <DataCount
            unitText="개"
            totalCount={rows.reduce((sum, item) => sum + item.CONTENT_COUNT, 0)}
          />
        );
      },
    },
    {
      key: "AD_SPACE",
      name: "지면",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "AD_SPACE", 70),
      renderCell({ row }: any) {
        return <pre className="grid-cell-font">{row.AD_SPACE}</pre>;
      },
      ...MultiRowCell(),
    },
    {
      key: "USP",
      name: "소구점",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "USP", 70),
      renderCell({ row }: any) {
        return <pre className="grid-cell-font">{row.USP}</pre>;
      },
      ...MultiRowCell(),
    },
    {
      key: "AD_COPY",
      name: "카피",
      headerCellClass: "text-center grid-header-font",
      cellClass: "text-center grid-cell-font",
      editable: false,
      width: getColumnWidth(widths, "AD_COPY", 70),
      renderCell({ row }: any) {
        return <pre className="grid-cell-font">{row.AD_COPY}</pre>;
      },
      ...MultiRowCell(),
    },
    {
      key: "MEMO",
      name: "특이사항",
      headerCellClass: "text-center grid-header-font",
      renderHeaderCell() {
        return (
          <div
            className="flex-box flex-box-row"
            style={{ justifyContent: "space-between" }}
          >
            <span>특이사항</span>
            <div className="flex-box" style={{ alignItems: "center" }}>
              <EditSharpIcon style={{ fontSize: 15, color: "#9b9898" }} />
            </div>
          </div>
        );
      },
      cellClass: "notes-cell",
      width: getColumnWidth(widths, "MEMO", 250),
      renderCell({ row }: any) {
        return <pre className="grid-cell-font">{row.MEMO}</pre>;
      },
      ...MultiRowCell(),
    },
  ];

  return (
    <Dialog
      disableEscapeKeyDown
      open={true}
      onClose={handleClose}
      fullWidth
      maxWidth={"lg"}
    >
      <div
        id="modal-container"
        style={{
          padding: "10px 12px",
          minHeight: "605px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
              fontFamily: "malgun",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                fontFamily: "malgun",
              }}
            >
              {pType === "image" ? "[이미지] " : "[영상] "} {open.ENT_NM}
            </span>
            <span
              style={{
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "malgun",
              }}
            >
              {formatDateString(open.CONTENT_DATE)}
            </span>
            <span
              style={{
                fontWeight: "600",
                fontSize: "12px",
                fontFamily: "malgun",
              }}
            >
              * 나스 경로 변경 시, 제작자가 변경됩니다
            </span>
          </div>
          <div className="flex-box flex-box-row">
            <CallButton onClick={handleClose}>
              <ClearIcon sx={{ color: "#DCDCDC" }} />
            </CallButton>
          </div>
        </div>
        <DataGrid
          className="rdg-light"
          style={{ flex: 1, minHeight: "300px", maxHeight: "600px" }}
          sortColumns={sortColumns}
          onSortColumnsChange={handleSortColumnsChange}
          rowHeight={autoRowHeight}
          rows={rows}
          columns={columns}
          onSelectedCellChange={handleSelectedRow}
          bottomSummaryRows={summaryRows} // 하단 요약 행 추가
          defaultColumnOptions={{
            resizable: true,
            sortable: true,
          }}
          onRowsChange={handleUpdateRow}
          onColumnResize={(idx, width) =>
            onColumnResize(WIDTH_KEY_NAME, columns, idx, width)
          }
          onCopy={handleCopy}
        />
      </div>
    </Dialog>
  );
};

export default ContentModal;
