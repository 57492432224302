import { Button, Stack, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { MIN_WIDTH, SIDENAV_WIDTH, TOPNAV_HEIGHT } from "../constants/constant";
import styled from "styled-components";
import { useEffect } from "react";
import { useAutologin } from "../service/login/loginHooks";
import Modals from "../components/modal/modal";
import TopNav from "../components/layout/top-navigation/TopNav";
import SideNav from "../components/layout/side-navigation/SideNav";
import { useGetMyMenu } from "../service/menu/menuHooks";

const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  min-width: ${MIN_WIDTH};
  box-sizing: border-box;
  padding-top: ${TOPNAV_HEIGHT};
  padding-left: ${SIDENAV_WIDTH};
  background-color: white;
  display: flex;
`;

const Content = styled.div`
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  /* height: calc(100vh - ${TOPNAV_HEIGHT}); */
  display: flex;
`;

const Layout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const autoLogin = useAutologin();
  const { data: menuSet } = useGetMyMenu();

  useEffect(() => {
    autoLogin();
  }, []);

  useEffect(() => {
    if (location.pathname !== "/organization" && menuSet !== undefined) {
      //권한이 없는 메뉴로 들어오면 반려
      if (!menuSet.has(location.pathname.split("/")[1])) {
        handleMoveToOrganization();
      }
    }
  }, [menuSet, location.pathname]);

  const handleMoveToOrganization = () => {
    navigate("/organization");
  };
  return (
    <>
      <TopNav />
      <Stack direction="row">
        <SideNav />
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
      </Stack>
      <Modals />
    </>
  );
};

export default Layout;
