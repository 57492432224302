import { CellClickArgs, CopyEvent } from "react-data-grid";
import { formatDate, formatDateYYYYMMDD } from "./dateUtil";
import { isEmpty } from "./isEmpty";

export const transformColor = (color: string) => {
  let ret = "";

  if (color === "red") {
    ret = "#f3cccb";
  } else if (color === "orange") {
    ret = "#fde5cd";
  } else if (color === "yellow") {
    ret = "#fff2cc";
  } else if (color === "green") {
    ret = "#daead2";
  } else if (color === "blue") {
    ret = "#cee3f3";
  }

  return ret;
};

export const getLiveStateName = (LIVE: string) => {
  let ret = "";

  if (LIVE === "ready") {
    ret = "대기";
  } else if (LIVE === "request") {
    ret = "요청";
  } else if (LIVE === "divide") {
    ret = "분배";
  } else if (LIVE === "start") {
    ret = "시작";
  } else if (LIVE === "end") {
    ret = "종료";
  }

  return ret;
};

export const getEntTypeName = (TYPE: string) => {
  let ret = "";

  if (TYPE === 'domestic') {
    ret = "국내매체"
  } else if (TYPE === "overseas") {
    ret = "해외매체"
  } else if (TYPE === "search") {
    ret = "검색광고"
  }

  return ret;
}

export const getMediaColor = (MEDIA: string) => {
  let ret = "";

  if (MEDIA === 'GFA') {
    ret = "#2DB400"
  } else if (MEDIA === "KAKAO") {
    ret = "#F9E000"
  } else if (MEDIA === "당근") {
    ret = "#fd7f18"
  } else if (MEDIA === "토스") {
    ret = "#0064FF"
  } else if (MEDIA === "GOOGLE") {
    ret = "#ff0000"
  } else if (MEDIA === "META") {
    ret = "#0081FB"
  } else if (MEDIA === "TikTok") {
    ret = "#3aaeaa"
  } else if (MEDIA === "검색광고") {
    ret = "#6e0fbb"
  }

  return ret;
}

export const getLiveStateColor = (LIVE: string) => {
  let ret = "";

  if (LIVE === "ready" || LIVE === "대기") {
    ret = "#676767";
  } else if (LIVE === "request" || LIVE === "요청") {
    ret = "#00008B";
  } else if (LIVE === "divide" || LIVE === "분배") {
    ret = "#00a200";
  } else if (LIVE === "start" || LIVE === "시작") {
    ret = "#006dda";
  } else if (LIVE === "end" || LIVE === "종료") {
    ret = "#FF4500";
  }

  return ret;
};

export const getContentStateColor = (CONTENT_STATE_CD: string) => {
  let color = "";
  if (CONTENT_STATE_CD === 'C026A') {
    color = "#4CAF50";
  } else if (CONTENT_STATE_CD === 'C026B') {
    color = "#2196F3";
  }
  else if (CONTENT_STATE_CD === 'C026C') {
    color = "#fd7f18";
  }
  else if (CONTENT_STATE_CD === 'C026D') {
    color = "#a50a98";
  }
  return color;
}

export const onColumnResize = (name: string, columns: any, idx: number, width: number) => {
  const storedData = localStorage.getItem(name);

  // 기존에 저장된 데이터가 객체 형태로 있으면 파싱, 없으면 빈 객체로 초기화
  let existingData = storedData ? JSON.parse(storedData) : {};

  // 새로 저장할 columnKey와 데이터
  const columnKey = columns[idx].key;
  const newData = {
    columnWidth: width,
    columnIndex: idx, // 필요에 따라 columnIndex도 저장할 수 있습니다.
  };

  // 객체에 columnKey를 키로 사용하여 값 업데이트 또는 추가
  existingData[columnKey] = newData;

  // 업데이트된 데이터를 다시 로컬 스토리지에 저장
  localStorage.setItem(name, JSON.stringify(existingData));
}

function isISODateString(value: string): boolean {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  return isoDateRegex.test(value);
}

export async function getClipboardValue() {
  try {
    // 클립보드에서 텍스트 읽기
    const clipboardText = await navigator.clipboard.readText();
    // 읽어온 값을 반환
    return clipboardText;
  } catch (error) {
    console.error("Failed to read clipboard contents:", error);
    return null;
  }
}

export const handleCopy = <T>(event: CopyEvent<T>): void => {

  const columnKey = event.sourceColumnKey;

  let sourceValue = event.sourceRow[columnKey as keyof T];

  if (columnKey.endsWith("_AT")) {
    if (isISODateString(sourceValue as string)) {
      sourceValue = formatDate(sourceValue as string, true) as any;
    } else {
      // sourceValue = formatDate(sourceValue as string, true) as any;
    }
  } else if (columnKey !== "RECENT_CONTACT_DATE" && columnKey.endsWith("_DATE")) {
    sourceValue = formatDateYYYYMMDD(sourceValue as string) as any;
  }

  if (isEmpty(sourceValue)) {
    navigator.clipboard.writeText(String(""));
  } else {
    navigator.clipboard.writeText(String(sourceValue));
  }

};

const handleCellClick = <T>(
  args: CellClickArgs<T, unknown>,
  event: React.MouseEvent
) => {
  const { column, row } = args;
  console.log("Clicked Column Key:", column.key);
  console.log("Clicked Cell Value:", row[column.key as keyof T]);

};

export async function checkClipboard(): Promise<boolean> {
  try {
    const text = await navigator.clipboard.readText();
    return text.trim().length > 0; // 클립보드에 값이 있으면 true, 없으면 false
  } catch (error) {
    console.error("Failed to read clipboard contents:", error);
    return false;
  }
}
