export function generateDates(yearMonth: string) {
  // 연도와 월을 분리합니다.
  const year = parseInt(yearMonth.slice(0, 4));
  const month = parseInt(yearMonth.slice(4, 6));

  // 해당 월의 마지막 날짜를 구합니다.
  const lastDay = new Date(year, month, 0).getDate();

  // 해당 월의 모든 날짜를 생성합니다.
  const dates = [];
  for (let day = 1; day <= lastDay; day++) {
    const formattedDay = day.toString().padStart(2, "0");
    dates.push(`${yearMonth}${formattedDay}`);
  }
  return dates;
}

export function generateMonths(year: string) {
  const months = [];
  for (let month = 1; month <= 12; month++) {
    const formattedMonth = month.toString().padStart(2, "0");
    months.push(`${year}${formattedMonth}`);
  }
  return months;
}

export function generateDatesInRange(startDate: string | undefined, endDate: string | undefined): string[] {
  if (startDate === undefined || endDate === undefined) {
    return [];
  }
  const dates = [];

  // startDate와 endDate를 Date 객체로 변환합니다.
  let current = new Date(
    parseInt(startDate.slice(0, 4)), // 연도
    parseInt(startDate.slice(4, 6)) - 1, // 월 (0부터 시작하므로 -1)
    parseInt(startDate.slice(6, 8)) // 일
  );
  const end = new Date(
    parseInt(endDate.slice(0, 4)),
    parseInt(endDate.slice(4, 6)) - 1,
    parseInt(endDate.slice(6, 8))
  );

  // startDate부터 endDate까지의 날짜를 생성합니다.
  while (current <= end) {
    const year = current.getFullYear();
    const month = (current.getMonth() + 1).toString().padStart(2, "0");
    const day = current.getDate().toString().padStart(2, "0");
    dates.push(`${year}${month}${day}`);

    // 날짜를 하루 증가시킵니다.
    current.setDate(current.getDate() + 1);
  }

  return dates;
}
