import {
    GridColumnMenuColumnsItem,
    GridColumnMenuContainer,
    GridColumnMenuFilterItem,
    GridColumnMenuProps,
    GridColumnMenuSortItem
} from "@mui/x-data-grid";

export function CustomColumnMenu(props: GridColumnMenuProps) {
    const { hideMenu, colDef } = props;
  
    return (
        <GridColumnMenuContainer
        open
        hideMenu={hideMenu}
        colDef={colDef}  
      >
        <GridColumnMenuSortItem onClick={hideMenu} colDef={colDef} />
        <GridColumnMenuFilterItem onClick={hideMenu} colDef={colDef} />
        {/* <GridColumnMenuColumnsItem onClick={hideMenu} colDef={colDef} /> */}
      </GridColumnMenuContainer>
    );
  }