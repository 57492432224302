import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "react-data-grid/lib/styles.css";
import { customAxios } from "../../../config";
import { ADProgressBoardResponse } from "../../../types/ADProgressType";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import { errorHandler } from "../../../utils/apiUtil";
import styled from "styled-components";
import { getLiveStateColor, getLiveStateName, getMediaColor } from "../../../utils/commonUtil";
import {
  TableCol1,
  TableCol2,
  TableRow,
} from "../../../components/shared/styled-css/common-css";
import { getJwtState } from "../../../utils/jwtState";

const BoardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const ItemWrapper = styled.div`
  flex: 1;
  border: 1px solid #ddd;
`;

export interface SalesListTableProps {
  type: TabType;
  search: string;
  live: string;
  year: string;
  month: string;
  total:boolean;
}

const ADProgressBoard = forwardRef<ListTableHandle, SalesListTableProps>(
  ({ type, search, live, year, month, total }: SalesListTableProps, ref) => {
    const [rows, setRows] = useState<ADProgressBoardResponse>();
    const { deptId } = getJwtState();

    const getRows = async () => {
      try {
        const response = await customAxios.get("/ent/ad-progress-list/board", {
          params: {
            type,
            search,
            live: live === "전체" ? "" : live,
            deptId,
            year,
            month,
            total
          },
        });

        const data: ADProgressBoardResponse = response.data;
        setRows(data);
      } catch (e) {
        errorHandler(e);
      }
    };

    useImperativeHandle(ref, () => ({
      triggerSearch: () => {
        getRows();
      },
      triggerExcelDownload: () => {},
    }));

    useEffect(() => {
      getRows();
    }, [type, live, year, month]);

    return (
      <div
        style={{
          marginTop: 7,
          height: "190px",
        }}
      >
        {rows !== undefined && (
          <BoardContainer>
            <ItemWrapper>
              <TableRow>
                <TableCol1 className="flex" style={{ borderRightWidth: 0 }}>
                  매체
                </TableCol1>
              </TableRow>
              <div className="hide-scroll" style={{ height: "150px" }}>
                {rows.MEDIA.map((v) => (
                  <TableRow>
                    <TableCol1 className="flex" style={{
                      color: getMediaColor(v.MEDIA)
                    }}>
                      {v.MEDIA === null || v.MEDIA === ""
                        ? "선택안함"
                        : v.MEDIA}
                    </TableCol1>
                    <TableCol2
                      className="text-center"
                      style={{
                        justifyContent: "center",
                        flex: 1,
                      }}
                    >
                      {v.CNT}
                    </TableCol2>
                  </TableRow>
                ))}
              </div>
            </ItemWrapper>

            {(type === "domestic" || type === "overseas") && (
              <ItemWrapper>
                <TableRow>
                  <TableCol1 className="flex" style={{ borderRightWidth: 0 }}>
                    상품
                  </TableCol1>
                </TableRow>
                <div className="hide-scroll" style={{ height: "150px" }}>
                  {rows.P_NAME.map((v) => (
                    <TableRow>
                      <TableCol1 className="flex">
                        {v.P_NAME === null || v.P_NAME === ""
                          ? "선택안함"
                          : v.P_NAME}
                      </TableCol1>
                      <TableCol2
                        className="text-center"
                        style={{
                          justifyContent: "center",
                          flex: 1,
                        }}
                      >
                        {v.CNT}
                      </TableCol2>
                    </TableRow>
                  ))}
                </div>
              </ItemWrapper>
            )}

            {type === "search"  && (
              <ItemWrapper>
                <TableRow>
                  <TableCol1 className="flex" style={{ borderRightWidth: 0 }}>
                    광고 상품
                  </TableCol1>
                </TableRow>
                <div className="hide-scroll" style={{ height: "150px" }}>
                  {rows.S_PRODUCT.map((v) => (
                    <TableRow>
                      <TableCol1 className="flex">
                        {v.S_PRODUCT === null || v.S_PRODUCT === ""
                          ? "선택안함"
                          : v.S_PRODUCT}
                      </TableCol1>
                      <TableCol2
                        className="text-center"
                        style={{
                          justifyContent: "center",
                          flex: 1,
                        }}
                      >
                        {v.CNT}
                      </TableCol2>
                    </TableRow>
                  ))}
                </div>
              </ItemWrapper>
            )}

            <ItemWrapper>
              <TableRow>
                <TableCol1 className="flex" style={{ borderRightWidth: 0 }}>
                  라이브 상태
                </TableCol1>
              </TableRow>
              <div className="hide-scroll" style={{ height: "150px" }}>
                {rows.LIVE.map((v) => (
                  <TableRow>
                    <TableCol1 className="flex" style={{
                      color: getLiveStateColor(v.LIVE)
                    }}>
                      {v.LIVE === null || v.LIVE === ""
                        ? "선택안함"
                        : getLiveStateName(v.LIVE)}
                    </TableCol1>
                    <TableCol2
                      className="text-center"
                      style={{
                        justifyContent: "center",
                        flex: 1,
                      }}
                    >
                      {v.CNT}
                    </TableCol2>
                  </TableRow>
                ))}
              </div>
            </ItemWrapper>

            <ItemWrapper>
              <TableRow>
                <TableCol1 className="flex" style={{ borderRightWidth: 0 }}>
                  팀 계정
                </TableCol1>
              </TableRow>
              <div className="hide-scroll" style={{ height: "150px" }}>
                {rows.TEAM_ACCOUNT.map((v) => (
                  <TableRow>
                    <TableCol1 className="flex">
                      {v.TEAM_ACCOUNT === "" ? "선택안함" : v.TEAM_ACCOUNT}
                    </TableCol1>
                    <TableCol2
                      className="text-center"
                      style={{
                        justifyContent: "center",
                        flex: 1,
                      }}
                    >
                      {v.CNT}
                    </TableCol2>
                  </TableRow>
                ))}
              </div>
            </ItemWrapper>
          </BoardContainer>
        )}
      </div>
    );
  }
);

export default ADProgressBoard;
