import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Stack } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, { ColumnOrColumnGroup, SortColumn } from "react-data-grid";
import "react-data-grid/lib/styles.css";
import DataTotalCount from "../../../components/common/DataTotalCount";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import { MultiRowCell } from "../../../components/shared/lib/multiRowCell";
import {
  Container,
  HeaderCell,
} from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { useGetCallList, useUpdateCall } from "../../../service/call/callHooks";
import { BlackGetType } from "../../../types/BlackType";
import { errorHandler } from "../../../utils/apiUtil";
import { formatDate } from "../../../utils/dateUtil";
import { getJwtState } from "../../../utils/jwtState";
import { handleCopy, onColumnResize } from "../../../utils/commonUtil";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import CommonButton from "../../../components/common/CommonButton";

const BlackList = () => {
  const gridRef = useRef<any>(null);
  const [rows, setRows] = useState<BlackGetType[]>([]);
  const [search, setSearch] = useState("");
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태

  const storedData = localStorage.getItem("black_list");
  const widths = storedData ? JSON.parse(storedData) : undefined;

  const getRows = async () => {
    try {
      const response = await customAxios.get("/black", {
        params: {
          search,
        },
      });
      const data: BlackGetType[] = response.data;
      setRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getRows();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
    }
  };

  const handleCellSelected = (position: any) => {
    if (gridRef.current) {
      gridRef.current.selectCell(position, true);
    }
  };

  const { userId } = getJwtState();
  const selectedRowId = useRef<number>();
  const { data, refetch } = useGetCallList({ SALESPERSON_ID: userId });

  const handleCreateRow = async () => {
    try {
      await customAxios.post("/black");
      getRows();
    } catch (e) {
      errorHandler(e);
    }
  };

  const handleDeleteRow = async (row: any) => {
    if (confirm("정말 삭제하시겠습니까?")) {
      try {
        await customAxios.delete("/black", {
          params: {
            SEQ: row.SEQ,
          },
        });
        getRows();
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  //몇번째 줄이 수정중인지 파악함
  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
  };

  const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
    setSortColumns(newSortColumns);

    if (newSortColumns.length === 0) {
      setRows([...rows]); // 원래 데이터 유지
    } else {
      const sortedRows = [...rows].sort((a, b) => {
        for (const { columnKey, direction } of newSortColumns) {
          const sortOrder = direction === "ASC" ? 1 : -1;
          const aValue = (a as Record<string, any>)[columnKey];
          const bValue = (b as Record<string, any>)[columnKey];

          // aValue와 bValue가 undefined거나 null인 경우를 처리
          if (aValue === undefined || aValue === null) return 1 * sortOrder;
          if (bValue === undefined || bValue === null) return -1 * sortOrder;

          if (aValue < bValue) return -1 * sortOrder;
          if (aValue > bValue) return 1 * sortOrder;
        }
        return 0; // 모든 조건이 같을 때
      });
      setRows(sortedRows);
    }
  };

  //수정된 값을 서버로 보내어 업데이트함
  const handleUpdateBlackList = async (newData: any, oldData: any) => {
    const updateData: BlackGetType = newData[selectedRowId.current || 0];

    try {
      await customAxios.put("/black", {
        ...updateData,
      });
      getRows();
    } catch (e) {
      errorHandler(e);
    }
  };

  // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
  const summaryRows = useMemo((): any[] => {
    return [{}];
  }, []);

  //컬럼 테이블 생성
  const columns: ColumnOrColumnGroup<BlackGetType, any>[] = [
    {
      key: "B_NAME",
      name: "브랜드",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "B_NAME", 150),
      ...MultiRowCell(),
      renderSummaryCell({ row }: any) {
        return <DataTotalCount totalCount={rows.length} />;
      },
    },
    {
      key: "PHONE",
      name: "연락처",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "PHONE", 150),
      ...MultiRowCell(),
    },

    {
      key: "EMAIL",
      name: "이메일",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "EMAIL", 150),
      ...MultiRowCell(),
    },
    {
      key: "LINK",
      name: "링크",
      headerCellClass: "grid-header-font",
      cellClass: "grid-cell-font",
      width: getColumnWidth(widths, "LINK", 150),
      ...MultiRowCell(),
    },
    {
      key: "CREATED_AT",
      name: "데이터 생성시간",
      headerCellClass: "grid-header-font text-center",
      cellClass: "text-center grid-cell-font",
      width: getColumnWidth(widths, "CREATED_AT", 150),
      renderCell: ({ row }: any) => {
        return <div>{formatDate(row.CREATED_AT)}</div>;
      },
    },
    {
      key: "DELETE",
      name: "삭제",
      headerCellClass: "grid-header-font",
      width: getColumnWidth(widths, "DELETE", 100),
      renderHeaderCell() {
        return <HeaderCell></HeaderCell>;
      },
      renderCell: ({ row }: any) => {
        return (
          <Button
            fullWidth
            color="error"
            onClick={() => {
              handleDeleteRow(row);
            }}
          >
            삭제
          </Button>
        );
      },
    },
  ];

  return (
    <Container>
      <Stack flex={1} display={"flex"} width={"100%"} gap={2}>
        <Stack gap={2} display={"flex"} flex={1}>
          <Stack direction="row" justifyContent={"space-between"} gap={3}>
            <Stack flexDirection={"row"} display={"flex"} gap={1}>
              <SearchInputField
                value={search}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                placeholder=""
                showIcon={false}
              />
              <CommonButton
                onClick={() => refetch()}
                label="검색"
                icon={<SearchIcon fontSize="small" />}
              />
            </Stack>
            <Stack flexDirection={"row"} display={"flex"} gap={1}>
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleCreateRow}
                size="small"
              >
                행 추가
              </Button>
            </Stack>
          </Stack>
          <Stack flex={1}>
            {data && (
              <DataGrid
                className="rdg-light"
                style={{ height: `84vh` }}
                ref={gridRef}
                onCellClick={handleCellSelected}
                onSelectedCellChange={handleSelectedRow}
                onRowsChange={handleUpdateBlackList}
                columns={columns}
                rows={rows}
                bottomSummaryRows={summaryRows}
                sortColumns={sortColumns}
                onSortColumnsChange={handleSortColumnsChange}
                defaultColumnOptions={{
                  sortable: true,
                  resizable: true,
                }}
                onColumnResize={(idx, width) =>
                  onColumnResize("black_list", columns, idx, width)
                }
                onCopy={handleCopy}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};
export default BlackList;
