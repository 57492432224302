import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import {
  GridColTypeDef,
  GridRenderEditCellParams,
  useGridApiContext,
} from "@mui/x-data-grid";
import * as React from "react";

export function isKeyboardEvent(event: any): event is React.KeyboardEvent {
  return !!event.key;
}

function EditTextarea(props: GridRenderEditCellParams<any, string>) {
  const { id, field, value, colDef, hasFocus, cellMode } = props;
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();

   React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback<
    NonNullable<InputBaseProps["onChange"]>
  >(
    (event) => {
      let newValue = event.target.value;

      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    },
    [apiRef, field, id]
  );

  return (
    <div style={{ position: "relative", alignSelf: "flex-start" }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper
            elevation={1}
            sx={{ minWidth: colDef.computedWidth - 3 }}
            square
          >
            <InputBase
              multiline
              // rows={4}
              value={valueState}
              sx={{
                width: "100%",
                fontFamily: "malgun",
                fontSize: 13,
                zIndex: 10005,
              }}
              onChange={handleChange}
              inputRef={(ref) => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

export const multilineColumn: GridColTypeDef = {
  type: "string",
  renderCell: (params) => (
    <div
      style={{
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
      }}
    >
      {params.value}
    </div>
  ),
  renderEditCell: (params) => <EditTextarea {...params} />,
};
