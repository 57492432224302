import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import { koKR } from "@mui/x-data-grid/locales";

const theme = createTheme({
  palette: {
    primary: {
      main: "#246beb",
      contrastText: "#fff",
    },
    secondary: {
      main: "#2a5c96", 
      contrastText: "#fff",
    },
    background: {
      default: "#ffffff",
      paper: "#fff",
    },
    text: {
      primary: "#333",
      secondary: "#666",
      disabled: "#999",
    },
    error: {
      main: red.A400,
    },
    warning: {
      main: "#e74c3c",
      contrastText: "#fff",
    },
    info: {
      main: "#29b6f6",
    },
    success: {
      main: "#217346",
    },
    action: {
      active: "#001E3C",
      hover: "rgba(0, 30, 60, 0.08)",
      selected: "rgba(0, 30, 60, 0.16)",
      disabled: "rgba(0, 30, 60, 0.3)",
      disabledBackground: "rgba(0, 30, 60, 0.12)",
      focus: "rgba(0, 30, 60, 0.12)",
    },
  },
  typography: {
    // fontFamily: roboto.style.fontFamily,
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
      color: "black",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 500,
      color: "black",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
      color: "black",
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 500,
      color: "black",
    },
    h6: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: "#8C8C8C",
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    button: {
      textTransform: "none",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 4,
  components: {
    MuiStack: {
      defaultProps: {
        borderColor: "#BDBDBD",
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: "15px",
          backgroundColor: "white",
          boxShadow: "none",
          border: "0.5px solid #d9d9d9",
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      // defaultProps: {
      //     color: 'primary'
      // },
      // styleOverrides: {
      //     root: {
      //         color:'#000000',
      //         backgroundColor:'#ffffff',
      //         textTransform: 'none',
      //         borderColor: '#0000ff',
      //         '&:hover': {
      //             borderColor: '#00ff00',
      //         },
      //     },
      // },
    },
  },
}, koKR);

export default theme;
