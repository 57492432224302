import { Chip, Stack } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import DataGrid, {
  CellKeyboardEvent,
  CellKeyDownArgs,
  CellSelectArgs,
  ColumnOrColumnGroup,
  SortColumn,
} from "react-data-grid";
import "react-data-grid/lib/styles.css";
import styled from "styled-components";
import CustomCheckbox from "../../../components/common/CustomCheckbox";
import DataTotalCount from "../../../components/common/DataTotalCount";
import { HostingSelect } from "../../../components/shared/cell-renderers/HostingSelect";
import { ProductSelect } from "../../../components/shared/cell-renderers/ProductSelect";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import { autoRowHeight } from "../../../components/shared/lib/autoRowHeignt";
import { MultiRowCell } from "../../../components/shared/lib/multiRowCell";
import { customAxios } from "../../../config";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import {
  getLiveStateColor,
  getLiveStateName,
  getMediaColor,
  handleCopy,
  onColumnResize,
} from "../../../utils/commonUtil";
import { formatDate } from "../../../utils/dateUtil";
import { getJwtState } from "../../../utils/jwtState";

const CellButton = styled.div`
  border-radius: 5px;
  border: none;
  background-color: none;
  text-align: center;
  cursor: pointer;
`;

export interface SalesListTableProps {
  type: TabType;
  search: string;
  year: string;
  month: string;
  live: string;
}

const LiveRequestTable = forwardRef<ListTableHandle, SalesListTableProps>(
  ({ type, search, year, month, live }: SalesListTableProps, ref) => {
    const storedData = localStorage.getItem("live_request_list");
    const widths = storedData ? JSON.parse(storedData) : undefined;
    const [rows, setRows] = useState<EntGetType[]>([]);
    const selectedRowId = useRef<number>();
    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태

    const getRows = async () => {
      try {
        const response = await customAxios.get("/ent/live", {
          params: {
            type,
            search,
            admin: false,
            live: live === "전체" ? "" : live,
            year,
            month,
          },
        });
        const data: EntGetType[] = response.data;
        setRows(data);
      } catch (e) {
        errorHandler(e);
      }
    };

    useImperativeHandle(ref, () => ({
      triggerSearch: () => {
        getRows();
      },
      triggerExcelDownload: () => {},
    }));

    useEffect(() => {
      getRows();
    }, [year, month, live]);

    const handleSelectedRow = (cell: CellSelectArgs<EntGetType, any>) => {
      selectedRowId.current = cell.rowIdx;
    };

    const handleUpdateEntInfo = async (newData: any, oldData: any) => {
      const rowId = selectedRowId.current || 0;
      let entInfo: EntGetType = newData[rowId];
      if (entInfo === undefined) {
        return;
      }

      try {
        await customAxios.put("/ent/target", {
          ENT_ID: entInfo.ENT_ID,
          ENT_NOTES: entInfo.ENT_NOTES,
        });
        getRows();
      } catch (e) {
        errorHandler(e);
      }
    };

    // 정렬 처리 핸들러
    const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
      setSortColumns(newSortColumns);

      if (newSortColumns.length === 0) {
        setRows([...rows]); // 원래 데이터 유지
      } else {
        const sortedRows = [...rows].sort((a, b) => {
          for (const { columnKey, direction } of newSortColumns) {
            const sortOrder = direction === "ASC" ? 1 : -1;
            const aValue = (a as Record<string, any>)[columnKey];
            const bValue = (b as Record<string, any>)[columnKey];

            // aValue와 bValue가 undefined거나 null인 경우를 처리
            if (aValue === undefined || aValue === null) return 1 * sortOrder;
            if (bValue === undefined || bValue === null) return -1 * sortOrder;

            if (aValue < bValue) return -1 * sortOrder;
            if (aValue > bValue) return 1 * sortOrder;
          }
          return 0; // 모든 조건이 같을 때
        });
        setRows(sortedRows);
      }
    };

    //라이브요청 함수
    const handleLiveRequest = async (row: EntGetType) => {
      if (confirm("정말 라이브요청 취소 하시겠습니까?")) {
        const data = {
          ENT_ID: row.ENT_ID,
          LIVE: "ready",
        };
        try {
          await customAxios.put("/ent/live/request/cancel", data);

          getRows();
        } catch (e: unknown) {
          errorHandler(e);
        }
      }
    };

    function handleCellKeyDown(
      args: CellKeyDownArgs<any, any>,
      event: CellKeyboardEvent
    ) {
      const { column, row, rowIdx, mode } = args;

      const passKey = new Set([
        "F1",
        "F2",
        "F3",
        "F4",
        "F5",
        "F6",
        "F7",
        "F8",
        "F9",
        "F10",
        "F11",
        "F12",
        "Escape",
      ]);
      if (passKey.has(event.key)) {
        return;
      }

      if (mode === "SELECT") {
        event.preventDefault();
      }

      if (event.key === "Enter") {
        event.preventDefault();
      }
    }

    // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
    const summaryRows = useMemo((): any[] => {
      return [{}];
    }, []);

    const columns: ColumnOrColumnGroup<EntGetType, any>[] = [
      {
        key: "LIVE_REQUEST_STATE",
        name: "라이브 상태",
        resizable: true,
        width: getColumnWidth(widths, "LIVE_REQUEST_STATE", 100),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              <Chip
                label={getLiveStateName(row.LIVE)}
                variant="outlined"
                size="small"
                sx={{
                  color: getLiveStateColor(row.LIVE),
                  borderColor: getLiveStateColor(row.LIVE),
                }}
              />
            </div>
          );
        },
        renderSummaryCell({ row }: any) {
          return <DataTotalCount totalCount={rows.length} />;
        },
      },
      {
        key: "LIVE_REQUEST_AT",
        name: "라이브 요청일자",
        resizable: true,
        width: getColumnWidth(widths, "LIVE_REQUEST_AT", 130),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              {row.LIVE_REQUEST_AT && formatDate(row.LIVE_REQUEST_AT, true)}
            </div>
          );
        },
      },
      {
        key: "SALESMAN_NM",
        name: "영업 담당자",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        editable: false,
        width: getColumnWidth(widths, "SALESMAN_NM", 100),
      },
      {
        key: "MARKETER",
        name: "마케터",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        resizable: true,
        width: getColumnWidth(widths, "MARKETER", 160),
        renderCell({ row, onRowChange }: any) {
          return (
            <div
              className="text-center grid-cell-font"
              style={{
                display: row.MARKETER_ID ? "block" : "none",
              }}
            >
              {row.MARKETER_NM} ({row.MARKETER_ID})
            </div>
          );
        },
      },
      ...(type !== "search"
        ? [
            {
              key: "DESIGNER",
              name: "디자이너",
              resizable: true,
              width: getColumnWidth(widths, "DESIGNER", 160),
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <div
                    className="text-center grid-cell-font"
                    style={{
                      display: row.DESIGNER_ID ? "block" : "none",
                    }}
                  >
                    {row.DESIGNER_NM} ({row.DESIGNER_ID})
                  </div>
                );
              },
            },
          ]
        : []),
      ...(type === "" || type === "domestic" || type === "overseas"
        ? [
            {
              key: "CREATOR",
              name: "영상",
              resizable: true,
              width: getColumnWidth(widths, "CREATOR", 160),
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <div
                    className="text-center grid-cell-font"
                    style={{
                      display: row.CREATOR_ID ? "block" : "none",
                    }}
                  >
                    {row.CREATOR_NM} ({row.CREATOR_ID})
                  </div>
                );
              },
            },
          ]
        : []),
      {
        key: "TEAM_ACCOUNT",
        name: "팀 계정",
        width: getColumnWidth(widths, "TEAM_ACCOUNT", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        renderCell({ row, onRowChange }: any) {
          return <div style={{ textAlign: "center" }}>{row.TEAM_ACCOUNT}</div>;
        },
        editable: false,
      },
      ...(type === "domestic" || type === "overseas"
        ? [
            {
              key: "P_NAME",
              name: "상품",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              renderEditCell: ProductSelect,
              width: getColumnWidth(widths, "P_NAME", 100),
              editable: false,
            },
          ]
        : []),
      ...(type === "search"
        ? [
            {
              key: "S_PRODUCT",
              name: "광고 상품",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(widths, "S_PRODUCT", 100),
            },
          ]
        : []),
      {
        key: "MEDIA",
        name: "매체",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "MEDIA", 100),
        renderCell({ row, onRowChange }: any) {
          return (
            <div
              style={{ textAlign: "center", color: getMediaColor(row.MEDIA) }}
            >
              {row.MEDIA}
            </div>
          );
        },
        editable: false,
      },
      ...(type === "domestic"
        ? [
            {
              key: "D_ACCOUNT_NUMBER",
              name: "계정번호",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              editable: false,
              width: getColumnWidth(widths, "D_ACCOUNT_NUMBER", 100),
            },
            {
              key: "D_ACCOUNT_NM",
              name: "계정명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              editable: false,
              width: getColumnWidth(widths, "D_ACCOUNT_NM", 100),
            },
          ]
        : []),
      ...(type === "overseas"
        ? [
            {
              key: "O_BUSINESS_ACCOUNT_NM",
              name: "계정번호",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "O_BUSINESS_ACCOUNT_NM", 100),
            },
            {
              key: "O_AD_ACCOUNT_NM",
              name: "계정명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              editable: false,
              width: getColumnWidth(widths, "O_AD_ACCOUNT_NM", 100),
            },
            {
              key: "O_PROGRESS_METHOD",
              name: "진행방식",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              editable: false,
              width: getColumnWidth(widths, "O_PROGRESS_METHOD", 100),
            },
          ]
        : []),
      ...(type === "search"
        ? [
            {
              key: "S_SEARCH_AD_ID",
              name: "검색광고 아이디",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              editable: false,
              width: getColumnWidth(widths, "S_SEARCH_AD_ID", 100),
            },
          ]
        : []),
      {
        key: "ENT_NM",
        name: "사업자명",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_NM", 100),
      },
      {
        key: "ENT_NUMBER",
        name: "사업자번호",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_NUMBER", 100),
      },
      {
        key: "B_NAME",
        name: "브랜드명",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "B_NAME", 100),
      },
      {
        key: "H_NAME",
        name: "호스팅사",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        renderEditCell: HostingSelect,
        editable: false,
        width: getColumnWidth(widths, "H_NAME", 100),
      },
      {
        key: "CATEGORY",
        name: "제품 카테고리",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "CATEGORY", 100),
      },
      {
        key: "P_URLS",
        name: "진행제품 URL",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "P_URLS", 100),
      },
      {
        key: "ENT_CONTACT_NAME",
        name: "업체 담당자 이름",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_CONTACT_NAME", 100),
      },
      {
        key: "ENT_CONTACT_POSITION",
        name: "업체 담당자 직책",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_CONTACT_POSITION", 100),
      },
      {
        key: "ENT_CONTACT_NUMBER",
        name: "업체 담당자 연락처",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_CONTACT_NUMBER", 100),
      },
      {
        key: "ENT_CONTACT_EMAIL",
        name: "업체 담당자 이메일",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_CONTACT_EMAIL", 100),
      },
      {
        key: "ENT_NOTES",
        name: "특이사항",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "ENT_NOTES", 200),
        ...MultiRowCell(),
      },
      ...(type === "overseas"
        ? [
            {
              key: "O_PAYER_NM",
              name: "입금자명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              editable: false,
              width: getColumnWidth(widths, "O_PAYER_NM", 100),
            },
            {
              key: "TAX_INVOICE_EMAIL",
              name: "세금계산서 발행 이메일",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "TAX_INVOICE_EMAIL", 100),
            },
            {
              key: "IS_CONTRACT_SIGN",
              name: "계약서 서명 여부",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_CONTRACT_SIGN}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_CONTRACT_SIGN: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_CONTRACT_SIGN", 100),
            },
            {
              key: "IS_TAX_INVOICE_ISSUED",
              name: "세금계산서 발행",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_TAX_INVOICE_ISSUED}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_TAX_INVOICE_ISSUED: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_TAX_INVOICE_ISSUED", 100),
            },
            {
              key: "IS_ACCOUNT_PERMISSION_GRANTED",
              name: "계정 권한 부여",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_ACCOUNT_PERMISSION_GRANTED}
                    onChange={(event: any) =>
                      onRowChange(
                        {
                          ...row,
                          IS_ACCOUNT_PERMISSION_GRANTED: event.target.checked,
                        },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(
                widths,
                "IS_ACCOUNT_PERMISSION_GRANTED",
                100
              ),
            },
            {
              key: "IS_AD_CHARGE_REPLY_RECEIVED",
              name: "광고비 충전 회신",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_AD_CHARGE_REPLY_RECEIVED}
                    onChange={(event: any) =>
                      onRowChange(
                        {
                          ...row,
                          IS_AD_CHARGE_REPLY_RECEIVED: event.target.checked,
                        },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_AD_CHARGE_REPLY_RECEIVED", 100),
            },
          ]
        : [
            {
              key: "IS_CONV_TRACK",
              name: "영업팀 전환추적 체크",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_CONV_TRACK}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_CONV_TRACK: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_CONV_TRACK", 100),
            },
            {
              key: "IS_TRANSFER_CHECK",
              name: "영업팀 이관체크 ",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_TRANSFER_CHECK}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_TRANSFER_CHECK: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_TRANSFER_CHECK", 100),
            },
          ]),
      {
        key: "IS_MAIN_ACCOUNT_PERMISSION_CHECKED",
        name: "대표 계정 권한 체크",
        headerCellClass: "text-center grid-header-font",
        editable: false,
        renderCell({ row, onRowChange }: any) {
          return (
            <CustomCheckbox
              checked={!!row.IS_MAIN_ACCOUNT_PERMISSION_CHECKED}
              onChange={(event: any) =>
                onRowChange(
                  {
                    ...row,
                    IS_MAIN_ACCOUNT_PERMISSION_CHECKED: event.target.checked,
                  },
                  true
                )
              }
            />
          );
        },
        width: getColumnWidth(
          widths,
          "IS_MAIN_ACCOUNT_PERMISSION_CHECKED",
          100
        ),
      },
      {
        key: "LIVE_REQUEST",
        name: "라이브요청 취소",
        headerCellClass: "text-center grid-header-font",
        resizable: true,
        width: getColumnWidth(widths, "LIVE_REQUEST", 120),
        cellClass: "text-center grid-cell-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <CellButton
              style={{
                color: "red",
                display: row.LIVE === "request" ? "block" : "none",
              }}
              onClick={() => handleLiveRequest(row)}
            >
              취소
            </CellButton>
          );
        },
      },
    ];

    return (
      <Stack flex={1}>
        <DataGrid
          className="rdg-light"
          style={{ height: `84vh` }}
          sortColumns={sortColumns}
          onSortColumnsChange={handleSortColumnsChange}
          columns={columns}
          rowHeight={autoRowHeight}
          rows={rows}
          onRowsChange={handleUpdateEntInfo}
          onCellKeyDown={handleCellKeyDown}
          onSelectedCellChange={handleSelectedRow}
          bottomSummaryRows={summaryRows} // 하단 요약 행 추가
          defaultColumnOptions={{
            resizable: true,
            sortable: true,
          }}
          onColumnResize={(idx, width) =>
            onColumnResize("live_request_list", columns, idx, width)
          }
          onCopy={handleCopy}
        />
      </Stack>
    );
  }
);

export default LiveRequestTable;
