import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList } from "@mui/lab";
import { Button, SelectChangeEvent, Stack, Tab, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import SelectLiveForm, {
  liveStateOptions,
} from "../../../components/common/SelectLiveForm";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import SelectMonthField from "../../../components/shared/input-field/SelectMonthField";
import SelectYearField from "../../../components/shared/input-field/SelectYearField";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import TotalLiveRequestTable from "./TotalLiveRequestTable";
import { customAxios } from "../../../config";
import { errorHandler } from "../../../utils/apiUtil";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import styled from "styled-components";
import { getJwtState } from "../../../utils/jwtState";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomCheckbox from "../../../components/common/CustomCheckbox";

export const TableContainer = styled.div``;

export const TableRow = styled.div`
  display: flex;
`;

export const TableCol1 = styled.div`
  padding: 8px 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: malgun;
  min-width: 110px;
  font-weight: bold;
  background-color: #f9f9f9;
`;

export const TableCol2 = styled.div`
  padding: 8px 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 110px;
  font-size: 13px;
  font-family: malgun;
  flex: 1;
`;

interface ManagerCount {
  USER_ID: string | null;
  CNT: number;
  USER_NAME: string | null;
  DEPT_NM: string | null;
}

interface ManagerEntCountResponse {
  MARKTER: ManagerCount[];
  DESIGNER: ManagerCount[];
  CREATOR: ManagerCount[];
}

export default function TotalLiveRequestList() {
  const { deptId } = getJwtState();
  const [selected, setSelected] = useState<TabType>("");
  const [search, setSearch] = useState("");
  const tableRef = useRef<ListTableHandle>(null);
  const today = new Date();
  const [year, setYear] = useState<string>(today.getFullYear().toString()); // 오늘 년도
  const [month, setMonth] = useState<string>(
    (today.getMonth() + 1).toString().padStart(2, "0")
  ); //오늘 월
  const [live, setLive] = useState<string>("전체");
  const [show, setShow] = useState(false);
  const [data, setData] = useState<ManagerEntCountResponse>();
  const [total, setTotal] = useState(false);

  useEffect(() => {
    onSearch();
    getManagerEntCount();
  }, [selected]);

  const getManagerEntCount = async () => {
    try {
      const response = await customAxios.get<ManagerEntCountResponse>(
        "/ent/manager/count",
        {
          params: {
            type: selected,
            deptId,
          },
        }
      );
      const data = response.data;
      setData(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  /**
   * 컴포넌트
   */
  const onSearch = () => {
    tableRef.current?.triggerSearch();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setSelected(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handleLiveChange = (e: SelectChangeEvent) => {
    setLive(e.target.value);
  };

  return (
    <Stack flex={1} display={"flex"} width={"100%"}>
      <Stack
        sx={{ width: "100%", typography: "body1" }}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
      >
        <TabContext value={selected}>
          <Stack gap={2}>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={"전체"} value={""} />
                <Tab label={"국내매체광고"} value={"domestic"} />
                <Tab label={"해외매체광고"} value={"overseas"} />
                <Tab label={"검색광고"} value={"search"} />
              </TabList>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
              >
                {total === false && (
                  <>
                    <SelectYearField
                      sx={{ backgroundColor: "white" }}
                      label="년도"
                      fullWidth={false}
                      size="small"
                      value={year}
                      handleChange={(e) => {
                        setYear(e.target.value);
                      }}
                    />
                    <SelectMonthField
                      label="월"
                      sx={{ backgroundColor: "white" }}
                      fullWidth={false}
                      size="small"
                      value={month}
                      handleChange={(e) => {
                        setMonth(e.target.value);
                      }}
                    />
                  </>
                )}
                <SelectLiveForm
                  label="상태"
                  options={liveStateOptions.filter(
                    (v) =>
                      v.value === "request" ||
                      v.value === "divide" ||
                      v.value === "전체"
                  )}
                  size="small"
                  sx={{ width: "120px" }}
                  value={live}
                  handleChange={handleLiveChange}
                />
                <SearchInputField
                  value={search}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  placeholder=""
                  showIcon={false}
                  width={200}
                />
                <CommonButton
                  onClick={onSearch}
                  label="검색"
                  icon={<SearchIcon fontSize="small" />}
                />
                <Button
                  sx={{
                    fontFamily: "malgun",
                    height: 40,
                  }}
                  color={"primary"}
                  onClick={() => setTotal(!total)}
                  variant={"contained"}
                >
                  월별 / 전체
                </Button>
                <Button
                  sx={{
                    fontFamily: "malgun",
                    height: 40,
                  }}
                  color={"secondary"}
                  onClick={() => setShow(!show)}
                  variant={"contained"}
                  startIcon={<MenuOutlinedIcon />}
                >
                  담당자 목록
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </TabContext>
        <div
          style={{
            marginTop: 5,
            display: show ? "flex" : "none",
            flexDirection: "row",
            maxHeight: 350,
            minHeight: 200,
            width: "100%",
          }}
        >
          <div className="flex border-top border-left border-bottom">
            <TableRow className="border-bottom">
              <TableCol1 className="flex border-right">마케터</TableCol1>
              <TableCol1 className="flex ">
                담당 업체 건
                <Tooltip
                  title={
                    "검색 조건과 관계 없이 마케터가 담당하고 있는 라이브 시작 중인 업체 건 수입니다"
                  }
                  placement="top"
                >
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: "11px",
                      color: "#1976d2",
                      ml: 1,
                    }}
                  />
                </Tooltip>
              </TableCol1>
            </TableRow>
            <div className="hide-scroll ">
              {data?.MARKTER.map((v, index) => (
                <TableRow key={index} className="border-bottom">
                  <TableCol2 className="flex border-right">
                    {v.USER_NAME}
                  </TableCol2>
                  <TableCol2 className="flex ">{v.CNT}</TableCol2>
                </TableRow>
              ))}
            </div>
          </div>
          {selected !== "search" && (
            <div className="flex border-top border-left border-bottom">
              <TableRow className="border-bottom">
                <TableCol1 className="flex border-right">디자이너</TableCol1>
                <TableCol1 className="flex ">
                  담당 업체 건
                  <Tooltip
                    title={
                      "검색 조건과 관계 없이 디자이너가 담당하고 있는 라이브 시작 중인 업체 건 수입니다"
                    }
                    placement="top"
                  >
                    <InfoOutlinedIcon
                      sx={{
                        fontSize: "11px",
                        color: "#1976d2",
                        ml: 1,
                      }}
                    />
                  </Tooltip>
                </TableCol1>
              </TableRow>
              <div className="hide-scroll">
                {data?.DESIGNER.map((v, index) => (
                  <TableRow key={index} className="border-bottom">
                    <TableCol2 className="flex border-right">
                      {v.USER_NAME}
                    </TableCol2>
                    <TableCol2 className="flex  ">{v.CNT}</TableCol2>
                  </TableRow>
                ))}
              </div>
            </div>
          )}

          {selected !== "overseas" && selected !== "search" && (
            <div className="flex border">
              <TableRow className="border-bottom">
                <TableCol1 className="flex border-right">영상</TableCol1>
                <TableCol1 className="flex  ">
                  담당 업체 건
                  <Tooltip
                    title={
                      "검색 조건과 관계 없이 영상 담당자가 담당하고 있는 라이브 시작 중인 업체 건 수입니다"
                    }
                    placement="top"
                  >
                    <InfoOutlinedIcon
                      sx={{
                        fontSize: "11px",
                        color: "#1976d2",
                        ml: 1,
                      }}
                    />
                  </Tooltip>
                </TableCol1>
              </TableRow>
              <div className="hide-scroll">
                {data?.CREATOR.map((v, index) => (
                  <TableRow key={index} className="border-bottom">
                    <TableCol2 className="flex border-right">
                      {v.USER_NAME}
                    </TableCol2>
                    <TableCol2 className="flex  ">{v.CNT}</TableCol2>
                  </TableRow>
                ))}
              </div>
            </div>
          )}
        </div>
        <Stack mt={2} flex={1}>
          <TotalLiveRequestTable
            type={selected}
            search={search}
            ref={tableRef}
            year={year}
            month={month}
            live={live}
            total={total}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
