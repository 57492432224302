import styled from "styled-components";
import { TOPNAV_HEIGHT } from "../../../constants/constant";

export const Container = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
`

export const CallTableContainer = styled.div`
  border: 1px solid #ddd;
  height: calc(90vh - ${TOPNAV_HEIGHT});
  overflow-y: auto;
`;

export const CallTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
`;

export const CallTableBody = styled.tbody`
  background-color: #ffffff;
`;

export const CallTh = styled.th`
  background-color: #f9f9f9;
  text-align: center;
  padding: 5px 5px;
  font-weight: bold;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
`;

export const CallTd = styled.td`
  padding: 0px 5px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  border-right: 1px solid #ddd;
  font-weight: 200;
  font-size: 14px;
  color: black;
`;

export const CallButton = styled.button`
  border-radius: 5px;
  border: none;
  background-color: transparent;
  text-align: left;
  font-weight: 600;
  font-family: malgun;
  cursor: pointer;
`;

export const StyledTableRow = styled.tr`
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const HeaderCell = styled.div`
  text-align: center;
`;
export const CellButton = styled.div`
  border-radius: 5px;
  border: none;
  background-color: none;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  padding: 0px 5px;
  font-family: malgun;
  font-size: 13px;
  /* &:hover { 
      background-color: #6799ff;
      color: white;
    } */
`;

export const SearchButton = styled.div`
  border-radius: 4px;
  border: 1px solid #757575;
  width: 75px;
  height: 40px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  color: #757575;
  padding: 3px 10px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  &:hover { 
      background-color: #f9fbff;
      /* color: white; */
    }
`;

export const SearchButtonText = styled.p`
  letter-spacing: 2px;
  font-size: 13px;
  font-weight: 500;
`

export const PopupCloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
`;

export const TableRow = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
`;

export const TableCol1 = styled.div`
  padding: 8px 12px;
  text-align: center;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: malgun;
  width: 140px;
  font-weight: bold;
  background-color: #f9f9f9;
`;


export const TableCol2 = styled.div`
  flex: 7;
  padding: 5px 5px;
  display: flex;
  align-content: center;
  align-items: center;
  word-wrap:break-word;
  font-family: malgun;
  font-size: 14px;
`;



export const TableMultiInfoCol1 = styled.div`
  padding: 8px 12px;
  text-align: center;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: malgun;
  width: 140px;
  font-weight: bold;
  background-color: #f9f9f9;
`;



export const ContentRow = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
`;

export const ContentTableHeader = styled.div` 
  padding: 5px 0px;
  text-align: center;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: malgun;
  width: 140px;
  font-weight: bold;
  background-color: #f9f9f9;
  letter-spacing: 1px;
`;


export const ContentTableCell = styled.div` 
  display: flex;
  align-content: center;
  word-wrap:break-word;
  font-family: malgun;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  border-right: 1px solid #ddd;
`;
