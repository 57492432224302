import ClearIcon from "@mui/icons-material/Clear";
import {
  Autocomplete,
  Box,
  OutlinedInput,
  Stack,
  TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import {
  PopupCloseButton,
  TableCol1,
  TableCol2,
  TableContainer,
  TableRow
} from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { getDetailCode } from "../../../service/common/api";
import { CodeDetailSimpleType, CodeDetailType } from "../../../types/CodeType";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import { isEmpty } from "../../../utils/isEmpty";

interface Props {
  open: EntGetType;
  onSubmit: () => void;
  onCancel: () => void;
}

type BusinessUserType = {
  USER_ID: string;
  USER_NAME: string;
};

export default function LiveAccountChangePopup({
  open,
  onSubmit,
  onCancel,
}: Props) {
  const [account, setAccount] = useState<CodeDetailSimpleType | null>(null);
  const [accountOptions, setAccountOptions] = useState<CodeDetailSimpleType[]>(
    []
  );

  /**
   * API 호출
   */
  const fetchCodeDetails = async (
    codeId: string,
    setter: (data: CodeDetailType[]) => void
  ) => {
    try {
      const response = await getDetailCode(codeId);
      setter(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    if (open) {
      fetchCodeDetails("C022", setAccountOptions);
    }
  }, [open]);

  /**
   * 컴포넌트 제어
   */

  const handleClose = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (account?.CD_DTL_NM === undefined || account.CD_DTL_NM === "") {
      popupCustomAlert("error", "변경할 팀 계정을 선택해주세요");
      return;
    }

    try {
      const payload = {
        ENT_ID: open.ENT_ID,
        TEAM_ACCOUNT: account?.CD_DTL_NM || "",
      };

      await customAxios.put("/ent", payload);
      onSubmit();
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const getTabTitle = (): string => {
    let tabTitle = "";

    if (open.TYPE === "domestic") {
      tabTitle = "국내매체광고";
    } else if (open.TYPE === "overseas") {
      tabTitle = "해외매체광고";
    } else if (open.TYPE === "search") {
      tabTitle = "검색광고";
    }
    return tabTitle;
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      fullScreen={false}
      maxWidth={"sm"}
    >
      <Stack pl={5} pr={5} pt={3} pb={3} gap={2}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Box alignContent={"center"}>
            <span
              style={{
                fontWeight: "600",
                fontSize: "17px",
                fontFamily: "malgun",
              }}
            >
              [{getTabTitle()}] 팀 계정 변경
            </span>
          </Box>
          <Box>
            <PopupCloseButton onClick={handleClose} autoFocus>
              <ClearIcon sx={{ color: "#BDBDBD" }} />
            </PopupCloseButton>
          </Box>
        </Stack>
        <TableContainer>
          <TableRow>
            <TableCol1>현재 팀계정</TableCol1>
            <TableCol2>
              <OutlinedInput
                id="current-team-account-input"
                value={
                  isEmpty(open.TEAM_ACCOUNT) ? "미지정" : open.TEAM_ACCOUNT
                }
                size="small"
                readOnly
                fullWidth
              />
            </TableCol2>
          </TableRow>
          <TableRow>
            <TableCol1>변경할 계정</TableCol1>
            <TableCol2>
              <Autocomplete
                options={accountOptions.filter(
                  (v) => v.CD_DTL_NM !== open.TEAM_ACCOUNT
                )}
                getOptionLabel={(option) => option.CD_DTL_NM}
                isOptionEqualToValue={(option, value) =>
                  option.CD_DTL_NM === value.CD_DTL_NM
                }
                value={account}
                onChange={(event, value) => setAccount(value)}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                fullWidth
              />
            </TableCol2>
          </TableRow>
        </TableContainer>

        <div style={{ display: "flex", gap: 5 }}>
          <Button variant="outlined" onClick={handleClose} sx={{ flex: 1 }}>
            취소
          </Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ flex: 1 }}>
            변경
          </Button>
        </div>
      </Stack>
    </Dialog>
  );
}
