import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList } from "@mui/lab";
import { Button, SelectChangeEvent, Stack, Tab } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import SelectLiveForm, {
  liveStateOptions,
} from "../../../components/common/SelectLiveForm";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import ADProgressTable from "./ADProgressTable";
import ADProgressBoard from "./ADProgressBoard";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { getJwtState } from "../../../utils/jwtState";
import SelectYearField from "../../../components/shared/input-field/SelectYearField";
import SelectMonthField from "../../../components/shared/input-field/SelectMonthField";
import { SelectFormData } from "../../../components/shared/input-field/SelectForm";

export default function ADProgressList() {
  const { deptId } = getJwtState();
  const [selected, setSelected] = useState<TabType>("");
  const [search, setSearch] = useState("");
  const tableBoardRef = useRef<ListTableHandle>(null);
  const tableRef = useRef<ListTableHandle>(null);
  const [live, setLive] = useState<string>("전체");
  const [show, setShow] = useState(true);
  const today = new Date();
  const [year, setYear] = useState<string>(today.getFullYear().toString()); // 오늘 년도
  const [month, setMonth] = useState<string>(
    (today.getMonth() + 1).toString().padStart(2, "0")
  );
  const [total, setTotal] = useState(false);

  const [stateOptions, setStateOptions] = useState<SelectFormData[]>([]);

  const getLiveStateCode = () => {
    const instance: SelectFormData[] = [];
    for (let i = 0; i < liveStateOptions.length; i++) {
      const v = liveStateOptions[i].value;
      if (v === "ready" || v === "request") continue;
      instance.push(liveStateOptions[i]);
    }

    setStateOptions(instance);
  };

  useEffect(() => {
    getLiveStateCode();
    if (deptId === "C007D") {
      setSelected("search");
    }
  }, []);

  useEffect(() => {
    onSearch();
  }, [selected, live, year, month, total]);

  /**
   * 컴포넌트
   */
  const onSearch = () => {
    tableRef.current?.triggerSearch();
    tableBoardRef.current?.triggerSearch();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setSelected(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handleLiveChange = (e: SelectChangeEvent) => {
    setLive(e.target.value);
  };

  const isAuth = (tab: TabType) => {
    if (tab === "") {
      if (deptId === "C007D") {
        return false;
      }
    } else if (tab === "domestic") {
      if (deptId === "C007D") {
        return false;
      }
    } else if (tab === "overseas") {
      if (deptId === "C007D") {
        return false;
      }
    } else if (tab === "search") {
      if (deptId === "C007C" || deptId === "C007E" || deptId === "C007F") {
        return false;
      }
    }

    return true;
  };

  return (
    <Stack flex={1} display={"flex"} width={"100%"}>
      <Stack
        sx={{ width: "100%", typography: "body1" }}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
      >
        <TabContext value={selected}>
          <Stack gap={2}>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={"전체"} value={""} disabled={!isAuth("")} />
                <Tab
                  label={"국내매체광고"}
                  value={"domestic"}
                  disabled={!isAuth("domestic")}
                />
                <Tab
                  label={"해외매체광고"}
                  value={"overseas"}
                  disabled={!isAuth("overseas")}
                />
                <Tab
                  label={"검색광고"}
                  value={"search"}
                  disabled={!isAuth("search")}
                />
              </TabList>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
              >
                {total === false && (
                  <>
                    <SelectYearField
                      sx={{ backgroundColor: "white" }}
                      label="년도"
                      fullWidth={false}
                      size="small"
                      value={year}
                      handleChange={(e) => {
                        setYear(e.target.value);
                      }}
                    />
                    <SelectMonthField
                      label="월"
                      sx={{ backgroundColor: "white" }}
                      fullWidth={false}
                      size="small"
                      value={month}
                      handleChange={(e) => {
                        setMonth(e.target.value);
                      }}
                    />
                  </>
                )}
                <SelectLiveForm
                  label="상태"
                  options={stateOptions}
                  size="small"
                  sx={{ width: "120px" }}
                  value={live}
                  handleChange={handleLiveChange}
                />
                 <SearchInputField
                      value={search}
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyDown}
                      placeholder=""
                      showIcon={false}
                      width={150}
                    />
                <CommonButton
                  onClick={onSearch}
                  label="검색"
                  icon={<SearchIcon fontSize="small" />}
                />
                <Button
                  sx={{
                    fontFamily: "malgun",
                    height: 40,
                  }}
                  color={"primary"}
                  onClick={() => setTotal(!total)}
                  variant={"contained"}
                >
                  월별 / 전체
                </Button>
                <Button
                  sx={{
                    fontFamily: "malgun",
                    height: 40,
                  }}
                  color={"secondary"}
                  onClick={() => setShow(!show)}
                  variant={"contained"}
                  startIcon={<MenuOutlinedIcon />}
                >
                  통계
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </TabContext>
        <div
          style={{
            display: show ? "block" : "none",
          }}
        >
          <ADProgressBoard
            type={selected}
            search={search}
            ref={tableBoardRef}
            live={live}
            year={year}
            month={month}
            total={total}
          />
        </div>
        <Stack mt={2} flex={1}>
          <ADProgressTable
            type={selected}
            search={search}
            ref={tableRef}
            live={live}
            year={year}
            month={month}
            show={show}
            total={total}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
