import { Chip, Stack } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import DataGrid, { ColumnOrColumnGroup, SortColumn } from "react-data-grid";
import "react-data-grid/lib/styles.css";
import CustomCheckbox from "../../../components/common/CustomCheckbox";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import DataTotalCount from "../../../components/common/DataTotalCount";
import { ADProductSelect } from "../../../components/shared/cell-renderers/ADProductSelect";
import { HostingSelect } from "../../../components/shared/cell-renderers/HostingSelect";
import { MediaSelect } from "../../../components/shared/cell-renderers/MediaSelect";
import { ProductSelect } from "../../../components/shared/cell-renderers/ProductSelect";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import { autoRowHeight } from "../../../components/shared/lib/autoRowHeignt";
import { MultiRowCell } from "../../../components/shared/lib/multiRowCell";
import { CellButton } from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { useGetSalseListBySalespersonId } from "../../../service/customer/customerHooks";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import {
  getLiveStateColor,
  getLiveStateName,
  getMediaColor,
  handleCopy,
  onColumnResize,
} from "../../../utils/commonUtil";
import { formatDateToMysql } from "../../../utils/dateUtil";
import { getJwtState } from "../../../utils/jwtState";

interface SortType {
  columnKey: string;
  direction: "ASC" | "DESC";
}

export interface SalesListTableProps {
  type: TabType;
  search: string;
  live: string;
}

const SalesListTable = forwardRef<ListTableHandle, SalesListTableProps>(
  ({ type, search, live }: SalesListTableProps, ref) => {
    const { userId } = getJwtState();
    const storedData = localStorage.getItem("sales_list");
    const widths = storedData ? JSON.parse(storedData) : undefined;
    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태
    const [rows, setRows] = useState<EntGetType[]>([]);
    const selectedRowId = useRef<number>();
    const { data, refetch } = useGetSalseListBySalespersonId({
      customerState: "계약",
      salespersonId: userId,
      type: "banner",
    });
    const [sortType, setSortType] = useState<SortType>({
      columnKey: "CUSTOMER_ID",
      direction: "ASC",
    });

    const [dfilter, setDfilter] = useState<string>("");

    const getRows = async () => {
      try {
        const response = await customAxios.get("/ent", {
          params: {
            type,
            search,
            admin: false,
            live,
          },
        });
        const data: EntGetType[] = response.data;
        setRows(data);
      } catch (e) {
        errorHandler(e);
      }
    };

    useImperativeHandle(ref, () => ({
      triggerSearch: () => {
        getRows();
      },
      triggerExcelDownload: () => {},
    }));

    useEffect(() => {
      getRows();
    }, [live]);

    //몇번째 줄이 수정중인지 파악함
    const handleSelectedRow = (cell: any) => {
      selectedRowId.current = cell.rowIdx;
    };

    const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
      setSortColumns(newSortColumns);

      if (newSortColumns.length === 0) {
        setRows([...rows]); // 원래 데이터 유지
      } else {
        const sortedRows = [...rows].sort((a, b) => {
          for (const { columnKey, direction } of newSortColumns) {
            const sortOrder = direction === "ASC" ? 1 : -1;
            const aValue = (a as Record<string, any>)[columnKey];
            const bValue = (b as Record<string, any>)[columnKey];

            // aValue와 bValue가 undefined거나 null인 경우를 처리
            if (aValue === undefined || aValue === null) return 1 * sortOrder;
            if (bValue === undefined || bValue === null) return -1 * sortOrder;

            if (aValue < bValue) return -1 * sortOrder;
            if (aValue > bValue) return 1 * sortOrder;
          }
          return 0; // 모든 조건이 같을 때
        });
        setRows(sortedRows);
      }
    };

    //수정된 값을 서버로 보내어 업데이트함
    const handleUpdateEntInfo = async (newData: any, oldData: any) => {
      const updateData = newData[selectedRowId.current || 0];
      delete updateData.LIVE_REQUEST_AT;
      delete updateData.LIVE_DIVIDE_AT;
      delete updateData.LIVE_START_AT;
      delete updateData.LIVE_END_AT;

      try {
        await customAxios.put("/ent", {
          ...updateData,
        });
        getRows();
      } catch (e) {
        errorHandler(e);
      }
    };

    //라이브요청 함수
    const handleLiveRequest = async (row: EntGetType) => {
      if (type === "domestic") {
        // IS_TRANSFER_CHECK IS_CONV_TRACK IS_MAIN_ACCOUNT_PERMISSION_CHECKED
        if (
          row.IS_TRANSFER_CHECK === 0 ||
          row.IS_CONV_TRACK === 0 ||
          row.IS_MAIN_ACCOUNT_PERMISSION_CHECKED === 0
        ) {
          // popupCustomAlert("error", "영업팀 이관체크, 전환추적 체크, 대표 계정 권한 체크가 완료된 이후, 요청할 수 있습니다.")
          popupCustomAlert(
            "error",
            "체크 박스를 모두 활성화해야만 라이브 요청을 진행할 수 있습니다."
          );
          return;
        }
      } else if (type === "overseas") {
        if (
          row.IS_CONTRACT_SIGN === 0 ||
          // row.IS_TAX_INVOICE_ISSUED === 0 ||
          row.IS_ACCOUNT_PERMISSION_GRANTED === 0 ||
          // row.IS_AD_CHARGE_REPLY_RECEIVED === 0 ||
          row.IS_MAIN_ACCOUNT_PERMISSION_CHECKED === 0
        ) {
          popupCustomAlert(
            "error",
            "체크 박스를 모두 활성화해야만 라이브 요청을 진행할 수 있습니다. (계약서 서명 여부, 계정 권한 부여, 대표 계정 권한 체크)"
          );
          return;
        }
      }

      if (confirm("정말 라이브요청 하시겠습니까?")) {
        const data = {
          ENT_ID: row.ENT_ID,
          LIVE: "request",
          LIVE_REQUEST_AT: formatDateToMysql(new Date()),
        };

        try {
          await customAxios.put("/ent/live/request/start", data);

          getRows();
        } catch (e: unknown) {
          errorHandler(e);
        }
      }
    };

    //삭제 요청
    const handleDeleteCustomer = async (row: EntGetType) => {
      console.log(row);

      if (confirm("정말 삭제하시겠습니까?")) {
        try {
          await customAxios.delete("/ent", {
            params: {
              ENT_ID: row.ENT_ID,
            },
          });

          popupCustomAlert("success", "데이터를 삭제 완료했습니다");
          getRows();
        } catch (e: unknown) {
          errorHandler(e);
        }
      }
    };

    //정렬할 데이터의 상태를 받아오는 함수
    const handleChangeSortType = (data: any) => {
      const sortColumn = data as SortType[];
      // 이미 가지고 있는 정렬 컬럼이면 방향을 바꾸고 아니면 새로운 컬럼으로 정렬
      if (!sortColumn[0]) {
        setSortType((prev) => ({
          ...prev,
          direction: prev.direction === "ASC" ? "DESC" : "ASC",
        }));
      } else if (sortType.columnKey === sortColumn[0].columnKey) {
        setSortType({
          columnKey: sortType.columnKey,
          direction: sortType.direction === "ASC" ? "DESC" : "ASC",
        });
      } else {
        setSortType(sortColumn[0]);
      }
    };

    //필터를 적용함
    const filteredRows = useMemo(() => {
      if (!data) return [];
      if (!dfilter) return data;

      return data.filter((row) => {
        return Object.values(row).some((value) => {
          return String(value).toLowerCase().includes(dfilter.toLowerCase());
        });
      });
    }, [data, dfilter]);

    //정렬 상태 변경
    const sortedRows = useMemo(() => {
      if (!sortType) return filteredRows;
      const { columnKey, direction } = sortType;
      // 정렬 함수
      const sortFunc = (a: any, b: any) => {
        if (a[columnKey] > b[columnKey]) {
          return direction === "ASC" ? 1 : -1;
        } else if (a[columnKey] < b[columnKey]) {
          return direction === "ASC" ? -1 : 1;
        }
        return 0;
      };
      return filteredRows.sort(sortFunc);
    }, [filteredRows, sortType]);

    // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
    const summaryRows = useMemo((): any[] => {
      return [
        {
          totalCount: data?.length || 0,
        },
      ];
    }, [data]);

    //컬럼 테이블 생성
    const columns: ColumnOrColumnGroup<EntGetType, any>[] = [
      {
        //
        key: "LIVE_REQUEST",
        name: "라이브 요청",
        frozen: true,
        resizable: true,
        width: getColumnWidth(widths, "LIVE_REQUEST", 100),
        headerCellClass: "text-center grid-header-font",
        cellClass: (row: any) => {
          return row.LIVE === "ready"
            ? "live-action-cell"
            : "live-action-disabled-cell";
        },
        renderCell({ row, onRowChange }: any) {
          return (
            <CellButton
              style={{
                display: row.LIVE === "ready" ? "block" : "none",
              }}
              onClick={() => handleLiveRequest(row)}
            >
              요청하기
            </CellButton>
          );
        },
        renderSummaryCell({ row }: any) {
          return <DataTotalCount totalCount={rows.length} />;
        },
      },
      {
        key: "LIVE_REQUEST_STATE",
        name: "라이브 상태",
        resizable: true,
        width: getColumnWidth(widths, "LIVE_REQUEST_STATE", 100),
        frozen: true,
        headerCellClass: "text-center grid-header-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              <Chip
                label={getLiveStateName(row.LIVE)}
                variant="outlined"
                size="small"
                sx={{
                  color: getLiveStateColor(row.LIVE),
                  borderColor: getLiveStateColor(row.LIVE),
                }}
              />
            </div>
          );
        },
      },
      {
        key: "SALESMAN_NM",
        name: "영업 담당자",
        width: getColumnWidth(widths, "SALESMAN_NM", 100),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
      },
      ...(type === "domestic" || type === "overseas"
        ? [
            {
              key: "P_NAME",
              name: "상품",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              renderEditCell: ProductSelect,
              width: getColumnWidth(widths, "P_NAME", 100),
            },
          ]
        : []),
      ...(type === "search"
        ? [
            {
              key: "S_PRODUCT",
              name: "광고 상품",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              renderEditCell: ADProductSelect,
              width: getColumnWidth(widths, "S_PRODUCT", 100),
            },
          ]
        : []),
      {
        key: "MEDIA",
        name: "매체",
        renderEditCell: MediaSelect,
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "MEDIA", 100),
        renderCell({ row, onRowChange }: any) {
          return (
            <div
              style={{ textAlign: "center", color: getMediaColor(row.MEDIA) }}
            >
              {row.MEDIA}
            </div>
          );
        },
      },
      ...(type === "domestic"
        ? [
            {
              key: "D_ACCOUNT_NUMBER",
              name: "계정번호",
              headerCellClass: "text-center grid-header-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "D_ACCOUNT_NUMBER", 100),
            },
            {
              key: "D_ACCOUNT_NM",
              name: "계정명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "D_ACCOUNT_NM", 100),
            },
          ]
        : []),
      ...(type === "overseas"
        ? [
            {
              key: "O_BUSINESS_ACCOUNT_NM",
              name: "계정번호",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "O_BUSINESS_ACCOUNT_NM", 100),
            },
            {
              key: "O_AD_ACCOUNT_NM",
              name: "계정명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "O_AD_ACCOUNT_NM", 100),
            },
            {
              key: "O_PROGRESS_METHOD",
              name: "진행방식",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "O_PROGRESS_METHOD", 100),
            },
          ]
        : []),
      ...(type === "search"
        ? [
            {
              key: "S_SEARCH_AD_ID",
              name: "검색광고 아이디",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(widths, "S_SEARCH_AD_ID", 100),
              ...MultiRowCell(),
            },
          ]
        : []),
      {
        key: "ENT_NM",
        name: "사업자명",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        width: getColumnWidth(widths, "ENT_NM", 100),
      },
      {
        key: "ENT_NUMBER",
        name: "사업자번호",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        width: getColumnWidth(widths, "ENT_NUMBER", 100),
      },
      {
        key: "B_NAME",
        name: "브랜드명",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        width: getColumnWidth(widths, "B_NAME", 100),
      },
      // { key: "P_KIND", name: "제품종류", ...MultiRowCell() },
      {
        key: "H_NAME",
        name: "호스팅사",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        renderEditCell: HostingSelect,
        width: getColumnWidth(widths, "H_NAME", 100),
      },
      {
        key: "CATEGORY",
        name: "제품 카테고리",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        width: getColumnWidth(widths, "CATEGORY", 100),
      },
      {
        key: "P_URLS",
        name: "진행제품 URL",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        width: getColumnWidth(widths, "P_URLS", 100),
      },
      {
        key: "ENT_CONTACT_NAME",
        name: "업체 담당자 이름",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "ENT_CONTACT_NAME", 100),
        ...MultiRowCell(),
      },
      {
        key: "ENT_CONTACT_POSITION",
        name: "업체 담당자 직책",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "ENT_CONTACT_POSITION", 100),
        ...MultiRowCell(),
      },
      {
        key: "ENT_CONTACT_NUMBER",
        name: "업체 담당자 연락처",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "ENT_CONTACT_NUMBER", 100),
        ...MultiRowCell(),
      },
      {
        key: "ENT_CONTACT_EMAIL",
        name: "업체 담당자 이메일",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "ENT_CONTACT_EMAIL", 100),
        ...MultiRowCell(),
      },
      {
        key: "ENT_NOTES",
        name: "특이사항",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "ENT_NOTES", 200),
        ...MultiRowCell(),
      },
      ...(type === "overseas"
        ? [
            {
              key: "O_PAYER_NM",
              name: "입금자명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "O_PAYER_NM", 100),
            },
            {
              key: "TAX_INVOICE_EMAIL",
              name: "세금계산서 발행 이메일",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "TAX_INVOICE_EMAIL", 150),
            },
            {
              key: "IS_CONTRACT_SIGN",
              name: "계약서 서명 여부",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(widths, "IS_CONTRACT_SIGN", 100),
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_CONTRACT_SIGN}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_CONTRACT_SIGN: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
            },
            {
              key: "IS_TAX_INVOICE_ISSUED",
              name: "세금계산서 발행",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(widths, "IS_TAX_INVOICE_ISSUED", 100),
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_TAX_INVOICE_ISSUED}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_TAX_INVOICE_ISSUED: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
            },
            {
              key: "IS_ACCOUNT_PERMISSION_GRANTED",
              name: "계정 권한 부여",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(
                widths,
                "IS_ACCOUNT_PERMISSION_GRANTED",
                100
              ),
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_ACCOUNT_PERMISSION_GRANTED}
                    onChange={(event: any) =>
                      onRowChange(
                        {
                          ...row,
                          IS_ACCOUNT_PERMISSION_GRANTED: event.target.checked,
                        },
                        true
                      )
                    }
                  />
                );
              },
            },
            {
              key: "IS_AD_CHARGE_REPLY_RECEIVED",
              name: "광고비 충전 회신",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(widths, "IS_AD_CHARGE_REPLY_RECEIVED", 100),
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_AD_CHARGE_REPLY_RECEIVED}
                    onChange={(event: any) =>
                      onRowChange(
                        {
                          ...row,
                          IS_AD_CHARGE_REPLY_RECEIVED: event.target.checked,
                        },
                        true
                      )
                    }
                  />
                );
              },
            },
          ]
        : [
            {
              key: "IS_CONV_TRACK",
              name: "영업팀 전환추적 체크",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(widths, "IS_CONV_TRACK", 100),
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_CONV_TRACK}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_CONV_TRACK: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
            },
            {
              key: "IS_TRANSFER_CHECK",
              name: "영업팀 이관체크 ",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(widths, "IS_TRANSFER_CHECK", 100),
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_TRANSFER_CHECK}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_TRANSFER_CHECK: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
            },
          ]),
      {
        key: "IS_MAIN_ACCOUNT_PERMISSION_CHECKED",
        name: "대표 계정 권한 체크",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(
          widths,
          "IS_MAIN_ACCOUNT_PERMISSION_CHECKED",
          100
        ),
        renderCell({ row, onRowChange }: any) {
          return (
            <CustomCheckbox
              checked={!!row.IS_MAIN_ACCOUNT_PERMISSION_CHECKED}
              onChange={(event: any) =>
                onRowChange(
                  {
                    ...row,
                    IS_MAIN_ACCOUNT_PERMISSION_CHECKED: event.target.checked,
                  },
                  true
                )
              }
            />
          );
        },
      },
      {
        key: "DELETE_REQUEST",
        name: "삭제",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "DELETE_REQUEST", 70),
        renderCell({ row, onRowChange }: any) {
          return (
            <CellButton
              style={{
                color: "#ff1744",
                display: row.LIVE === "ready" ? "block" : "none",
              }}
              onClick={() => handleDeleteCustomer(row)}
            >
              삭제
            </CellButton>
          );
        },
      },
    ];

    return (
      <Stack flex={1}>
        {data && (
          <DataGrid
            className="rdg-light"
            style={{ height: `84vh` }}
            rowHeight={autoRowHeight}
            onSelectedCellChange={handleSelectedRow}
            onRowsChange={handleUpdateEntInfo}
            columns={columns}
            sortColumns={sortColumns}
            onSortColumnsChange={handleSortColumnsChange}
            rows={rows}
            bottomSummaryRows={summaryRows} // 하단 요약 행 추가
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            onColumnResize={(idx, width) =>
              onColumnResize("sales_list", columns, idx, width)
            }
            onCopy={handleCopy}
          />
        )}
      </Stack>
    );
  }
);

export default SalesListTable;
