import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { SideNavItemType } from "../../../config/nav-config";

interface SideNavItemProps {
  item: SideNavItemType;
}

interface StyledDivProps {
  isActive: boolean;
}

const ItemContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isActive",
})<StyledDivProps>`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 21px;
  padding-right: 20px;
  color: ${({ isActive }) => (isActive ? "black" : "white")};
  background-color: ${({ isActive }) => (isActive ? "white" : "transparent")};
  font-size: 15px;

  &:hover {
    background-color: ${({ isActive }) => (!isActive ? "#414141" : "")};
    color: ${({ isActive }) => (!isActive ? "white" : "")};
  }
`;

const ItemTitle = styled.div`
  font-weight: 500;
  font-family: malgun;
`;

const SideNavItem: React.FC<SideNavItemProps> = ({ item }) => {
  const { pathname } = useLocation();
  const isActive = pathname.split("/")[1] === item.link;

  return (
    <Link to={item.link} style={{ textDecoration: "none" }}>
      <ItemContainer isActive={isActive}>
        <ItemTitle>{item.title}</ItemTitle>
      </ItemContainer>
    </Link>
  );
};

export default SideNavItem;
