import { Chip, Stack } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import DataGrid, {
  CellKeyboardEvent,
  CellKeyDownArgs,
  CellSelectArgs,
  ColumnOrColumnGroup,
  SortColumn,
} from "react-data-grid";
import "react-data-grid/lib/styles.css";
import CustomCheckbox from "../../../components/common/CustomCheckbox";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import DataTotalCount from "../../../components/common/DataTotalCount";
import { HostingSelect } from "../../../components/shared/cell-renderers/HostingSelect";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import { autoRowHeight } from "../../../components/shared/lib/autoRowHeignt";
import { MultiRowCell } from "../../../components/shared/lib/multiRowCell";
import {
  CallButton,
  CellButton,
} from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import {
  getEntTypeName,
  getLiveStateColor,
  getLiveStateName,
  getMediaColor,
  handleCopy,
  onColumnResize,
} from "../../../utils/commonUtil";
import { formatDate, formatDateToMysql } from "../../../utils/dateUtil";
import { isEmpty } from "../../../utils/isEmpty";
import { getJwtState } from "../../../utils/jwtState";
import LiveAccountChangePopup from "./LiveAccountChangePopup";
import LivePeopleChangePopup, {
  LivePeopleChangePopupOpenType,
  LivePeopleChangePopupType,
} from "./LivePeopleChangePopup";

interface SortType {
  columnKey: string;
  direction: "ASC" | "DESC";
}

export interface SalesListTableProps {
  type: TabType;
  search: string;
  live: string;
  year: string;
  month: string;
  show: boolean;
  total: boolean;
}

const ADProgressTable = forwardRef<ListTableHandle, SalesListTableProps>(
  (
    { type, search, live, year, month, show, total }: SalesListTableProps,
    ref
  ) => {
    const { userId, deptId } = getJwtState();
    const storedData = localStorage.getItem("ad_progress_list");
    const widths = storedData ? JSON.parse(storedData) : undefined;
    const selectedRowId = useRef<number>();
    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]); // 추가된 정렬 상태

    const [rows, setRows] = useState<EntGetType[]>([]);

    const getRows = async () => {
      try {
        const response = await customAxios.get("/ent/ad-progress-list", {
          params: {
            type,
            search,
            live: live === "전체" ? "" : live,
            deptId,
            year,
            month,
            total,
          },
        });
        const data: EntGetType[] = response.data;
        setRows(data);
      } catch (e) {
        errorHandler(e);
      }
    };

    useImperativeHandle(ref, () => ({
      triggerSearch: () => {
        getRows();
      },
      triggerExcelDownload: () => {},
    }));

    useEffect(() => {
      getRows();
    }, []);

    const handleSelectedRow = (cell: CellSelectArgs<EntGetType, any>) => {
      selectedRowId.current = cell.rowIdx;
    };

    const handleUpdateEntInfo = async (newData: any, oldData: any) => {
      const rowId = selectedRowId.current || 0;

      let entInfo: EntGetType = newData[rowId];
      if (entInfo === undefined) {
        return;
      }

      if (userId !== entInfo.SALESMAN_ID) {
        popupCustomAlert(
          "warn",
          "영업담당자만 수정이 가능합니다",
          "only_mydata"
        );
        return;
      }

      try {
        await customAxios.put("/ent/target", {
          ENT_ID: entInfo.ENT_ID,
          ENT_NOTES: entInfo.ENT_NOTES,
        });
        getRows();
      } catch (e) {
        errorHandler(e);
      }
    };

    function handleCellKeyDown(
      args: CellKeyDownArgs<any, any>,
      event: CellKeyboardEvent
    ) {
      const { column, row, rowIdx, mode } = args;

      const passKey = new Set([
        "F1",
        "F2",
        "F3",
        "F4",
        "F5",
        "F6",
        "F7",
        "F8",
        "F9",
        "F10",
        "F11",
        "F12",
        "Escape",
      ]);
      if (passKey.has(event.key)) {
        return;
      }

      if (mode === "SELECT") {
        event.preventDefault();
      }

      if (event.key === "Enter") {
        event.preventDefault();
      }
    }

    // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
    const summaryRows = useMemo((): any[] => {
      return [{}];
    }, []);

    const handleSortColumnsChange = (newSortColumns: SortColumn[]) => {
      setSortColumns(newSortColumns);

      if (newSortColumns.length === 0) {
        setRows([...rows]); // 원래 데이터 유지
      } else {
        const sortedRows = [...rows].sort((a, b) => {
          for (const { columnKey, direction } of newSortColumns) {
            const sortOrder = direction === "ASC" ? 1 : -1;
            const aValue = (a as Record<string, any>)[columnKey];
            const bValue = (b as Record<string, any>)[columnKey];

            // aValue와 bValue가 undefined거나 null인 경우를 처리
            if (aValue === undefined || aValue === null) return 1 * sortOrder;
            if (bValue === undefined || bValue === null) return -1 * sortOrder;

            if (aValue < bValue) return -1 * sortOrder;
            if (aValue > bValue) return 1 * sortOrder;
          }
          return 0; // 모든 조건이 같을 때
        });
        setRows(sortedRows);
      }
    };

    const handleModalSubmit = (type: "change" | "account") => {
      if (type === "change") {
        setOpenChangeModel(undefined);
      } else if (type === "account") {
        setOpenAccountChangeModel(undefined);
      }

      getRows();
    };

    const onClickPeopleChange = (
      modalType: LivePeopleChangePopupType,
      row: EntGetType
    ) => {
      if (type === "") {
        return;
      }

      if (modalType === "salesman") {
        if (deptId !== "C007Z" && deptId !== "C007H") {
          popupCustomAlert("warn", "영업_팀장만 변경 가능합니다");
          return;
        }
      } else {
        if (deptId !== "C007Z" && deptId !== "C007I" && deptId === "C007Y") {
          popupCustomAlert("warn", "마케팅_팀장만 변경 가능합니다");
          return;
        }
      }

      if (row.LIVE !== "start" && row.LIVE !== "end") {
        popupCustomAlert(
          "warn",
          "라이브 상태 시작 또는 종료인 경우에만 사용할 수 있습니다"
        );
        return;
      }

      setOpenChangeModel({
        ...row,
        type: modalType,
      });
    };

    const onClickAccountChange = (row: EntGetType) => {
      if (type === "") {
        return;
      }

      if (deptId !== "C007Z" && deptId !== "C007I" && deptId === "C007Y") {
        popupCustomAlert("warn", "마케팅_팀장만 변경 가능합니다");
        return;
      }

      if (row.LIVE !== "start" && row.LIVE !== "end") {
        popupCustomAlert(
          "warn",
          "라이브 상태 시작 또는 종료인 경우에만 사용할 수 있습니다"
        );
        return;
      }

      setOpenAccountChangeModel({
        ...row,
      });
    };

    const getActionName = (row: EntGetType) => {
      let name = "";
      if (row.LIVE === "divide" || row.LIVE === "request") {
        name = "시작";
      } else if (row.LIVE === "start") {
        name = "종료";
      }
      return name;
    };

    const onClickAction = async (row: EntGetType) => {
      if (deptId !== "C007C" && deptId !== "C007D" && deptId !== "C007Z") {
        popupCustomAlert(
          "info",
          "마케팅팀_DA, 마케팅팀_SA 소속만 사용할 수 있습니다"
        );
        return;
      }

      if (row.LIVE === "divide") {
        if (row.TYPE === "search") {
          if (isEmpty(row.MARKETER_ID) || isEmpty(row.TEAM_ACCOUNT)) {
            popupCustomAlert(
              "info",
              "분배를 완료하지 않아 시작을 할 수 없습니다"
            );
            return;
          }
        } else {
          if (row.P_NAME === "이미지") {
            if (
              isEmpty(row.MARKETER_ID) ||
              isEmpty(row.DESIGNER_ID) ||
              isEmpty(row.TEAM_ACCOUNT)
            ) {
              popupCustomAlert(
                "info",
                "분배를 완료하지 않아 시작을 할 수 없습니다"
              );
              return;
            }
          } else {
            if (
              isEmpty(row.MARKETER_ID) ||
              isEmpty(row.DESIGNER_ID) ||
              isEmpty(row.CREATOR_ID) ||
              isEmpty(row.TEAM_ACCOUNT)
            ) {
              popupCustomAlert(
                "info",
                "분배를 완료하지 않아 시작을 할 수 없습니다"
              );
              return;
            }
          }
        }

        if (confirm(`라이브 시작하시겠습니까?`)) {
          try {
            const payload = {
              ENT_ID: row.ENT_ID,
              LIVE: "start",
              LIVE_START_AT: formatDateToMysql(new Date()),
            };

            await customAxios.put("/ent", payload);
            getRows();
          } catch (e: unknown) {
            errorHandler(e);
          }
        }
      }
      if (row.LIVE === "start") {
        if (confirm(`라이브 종료하시겠습니까?`)) {
          try {
            const payload = {
              ENT_ID: row.ENT_ID,
              LIVE: "end",
              LIVE_END_AT: formatDateToMysql(new Date()),
            };

            await customAxios.put("/ent/live/end", payload);
            getRows();
          } catch (e: unknown) {
            errorHandler(e);
          }
        }
      }
    };

    const getActionCellClass = (row: EntGetType) => {
      let cellClass = "";
      if (row.LIVE === "start") {
        cellClass = "live-cancel-cell";
      } else if (row.LIVE === "divide") {
        cellClass = "live-action-cell";
      }
      return cellClass;
    };

    //컬럼 테이블 생성
    const columns: ColumnOrColumnGroup<EntGetType, any>[] = [
      ...(type !== "" &&
      (deptId === "C007Z" || deptId === "C007C" || deptId === "C007D")
        ? [
            {
              key: "Action",
              name: "Action",
              frozen: true,
              resizable: true,
              width: getColumnWidth(widths, "Action", 120),
              cellClass: (row: any) => getActionCellClass(row),
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <div
                    className="text-center grid-cell-font"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 3,
                      justifyContent: "center",
                    }}
                  >
                    <CellButton
                      onClick={() => onClickAction(row)}
                      style={{ color: "#fff" }}
                    >
                      {getActionName(row)}
                    </CellButton>
                  </div>
                );
              },
            },
          ]
        : []),
      {
        key: "LIVE_REQUEST_STATE",
        name: "라이브 상태",
        resizable: true,
        width: getColumnWidth(widths, "LIVE_REQUEST_STATE", 100),
        headerCellClass: "text-center grid-header-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              <Chip
                label={getLiveStateName(row.LIVE)}
                variant="outlined"
                size="small"
                sx={{
                  color: getLiveStateColor(row.LIVE),
                  borderColor: getLiveStateColor(row.LIVE),
                }}
              />
            </div>
          );
        },
        renderSummaryCell({ row }: any) {
          return <DataTotalCount totalCount={rows.length} />;
        },
      },
      {
        key: "LIVE_START_AT",
        name: "시작 일자",
        resizable: true,
        width: getColumnWidth(widths, "B_NAME", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "grid-cell-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              {row.LIVE_START_AT && formatDate(row.LIVE_START_AT, true)}
            </div>
          );
        },
      },
      {
        key: "LIVE_END_AT",
        name: "종료 일자",
        resizable: true,
        width: getColumnWidth(widths, "B_NAME", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "grid-cell-font",
        renderCell({ row, onRowChange }: any) {
          return (
            <div style={{ textAlign: "center" }}>
              {row.LIVE_END_AT && formatDate(row.LIVE_END_AT, true)}
            </div>
          );
        },
      },
      ...(type === ""
        ? [
            {
              key: "TYPE",
              name: "구분",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              editable: false,
              width: getColumnWidth(widths, "TYPE", 100),
              renderCell({ row, onRowChange }: any) {
                return (
                  <div style={{ textAlign: "center" }}>
                    {getEntTypeName(row.TYPE)}
                  </div>
                );
              },
            },
          ]
        : []),
      {
        key: "SALESMAN_NM",
        name: "영업 담당자",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        editable: false,
        width: getColumnWidth(widths, "SALESMAN_NM", 100),
        renderCell({ row, onRowChange }: any) {
          return row.SALESMAN_EMPLOYMENT_STATUS === "EMPLOYED" ? (
            <div style={{ textAlign: "center" }}>{row.SALESMAN_NM}</div>
          ) : (
            <div style={{ textAlign: "center", color: "#fc5d5d" }}>
              <span title={`${row.SALESMAN_NM} (${row.SALESMAN_ID})`}>
                퇴사
              </span>
            </div>
          );
        },
      },
      {
        key: "MARKETER",
        name: "마케터",
        resizable: true,
        width: getColumnWidth(widths, "MARKETER", 160),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center",
        renderCell({ row, onRowChange }: any) {
          return isEmpty(row.MARKETER_ID) ? (
            <CallButton
              id="marketer-button"
              style={{ color: "#a5a4a4", fontWeight: "400" }}
              onClick={() => onClickPeopleChange("marketer", row)}
            >
              미지정
            </CallButton>
          ) : (
            <CallButton
              id="marketer-button"
              onClick={() => onClickPeopleChange("marketer", row)}
            >
              {isEmpty(row.MARKETER_ID) === false && row.MARKETER_NM}
            </CallButton>
          );
        },
      },
      ...(type !== "search"
        ? [
            {
              key: "DESIGNER",
              name: "디자이너",
              resizable: true,
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center",
              width: getColumnWidth(widths, "DESIGNER", 160),
              renderCell({ row }: { row: EntGetType }) {
                return isEmpty(row.DESIGNER_ID) ? (
                  <CallButton
                    id="designer-button"
                    style={{ color: "#a5a4a4", fontWeight: "400" }}
                    onClick={() => onClickPeopleChange("designer", row)}
                  >
                    {row.TYPE !== "search" && "미지정"}
                  </CallButton>
                ) : (
                  <CallButton
                    id="designer-button"
                    onClick={() => onClickPeopleChange("designer", row)}
                  >
                    {row.DESIGNER_NM}
                  </CallButton>
                );
              },
            },
          ]
        : []),
      ...(type === "" || type === "domestic" || type === "overseas"
        ? [
            {
              key: "CREATOR",
              name: "영상",
              resizable: true,
              width: getColumnWidth(widths, "CREATOR", 160),
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center",
              renderCell({ row }: { row: EntGetType }) {
                return isEmpty(row.CREATOR_ID) ? (
                  <CallButton
                    id="creator-button"
                    style={{ color: "#a5a4a4", fontWeight: "400" }}
                    onClick={() => onClickPeopleChange("creator", row)}
                  >
                    {row.TYPE !== "search" && "미지정"}
                  </CallButton>
                ) : (
                  <CallButton
                    id="creator-button"
                    onClick={() => onClickPeopleChange("creator", row)}
                  >
                    {isEmpty(row.CREATOR_ID) === false && row.CREATOR_NM}
                  </CallButton>
                );
              },
            },
          ]
        : []),
      {
        key: "TEAM_ACCOUNT",
        name: "팀 계정",
        width: getColumnWidth(widths, "TEAM_ACCOUNT", 80),
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center",
        editable: false,
        renderCell({ row, onRowChange }: any) {
          return isEmpty(row.TEAM_ACCOUNT) ? (
            <CallButton
              id="account-button"
              style={{ color: "#a5a4a4", fontWeight: "400" }}
              onClick={() => onClickAccountChange(row)}
            >
              미지정
            </CallButton>
          ) : (
            <CallButton
              id="account-button"
              onClick={() => onClickAccountChange(row)}
            >
              {isEmpty(row.TEAM_ACCOUNT) === false && row.TEAM_ACCOUNT}
            </CallButton>
          );
        },
      },
      ...(type === "" || type === "domestic" || type === "overseas"
        ? [
            {
              key: "P_NAME",
              name: "상품",
              width: getColumnWidth(widths, "P_NAME", 110),
              editable: false,
              headerCellClass: "text-center grid-header-font",
              cellClass: "grid-cell-font",
              renderCell({ row, onRowChange }: any) {
                const isEmpty = row.P_NAME === undefined || row.P_NAME === "";

                return (
                  <div
                    style={{
                      textAlign: "center",
                      backgroundColor: isEmpty ? "red" : "",
                    }}
                  >
                    {row.P_NAME}
                  </div>
                );
              },
            },
          ]
        : []),
      ...(type === "" || type === "search"
        ? [
            {
              key: "S_PRODUCT",
              name: "광고 상품",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(widths, "S_PRODUCT", 100),
            },
          ]
        : []),
      {
        key: "MEDIA",
        name: "매체",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "MEDIA", 100),
        renderCell({ row, onRowChange }: any) {
          return (
            <div
              style={{ textAlign: "center", color: getMediaColor(row.MEDIA) }}
            >
              {row.MEDIA}
            </div>
          );
        },
        editable: false,
      },
      ...(type === "domestic"
        ? [
            {
              key: "D_ACCOUNT_NUMBER",
              name: "계정번호",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
            },
            {
              key: "D_ACCOUNT_NM",
              name: "계정명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
            },
          ]
        : []),
      ...(type === "overseas"
        ? [
            {
              key: "O_BUSINESS_ACCOUNT_NM",
              name: "계정번호",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
            },
            {
              key: "O_PROGRESS_METHOD",
              name: "진행방식",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
            },
            {
              key: "O_PAYER_NM",
              name: "입금자명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
            },
          ]
        : []),
      ...(type === "search"
        ? [
            {
              key: "S_SEARCH_AD_ID",
              name: "검색광고 아이디",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
            },
          ]
        : []),
      {
        key: "ENT_NM",
        name: "사업자명",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
      },
      {
        key: "ENT_NUMBER",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        name: "사업자번호",
        ...MultiRowCell(),
        editable: false,
      },
      {
        key: "B_NAME",
        name: "브랜드명",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
      },
      {
        key: "H_NAME",
        name: "호스팅사",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        renderEditCell: HostingSelect,
        editable: false,
        width: getColumnWidth(widths, "H_NAME", 100),
      },
      {
        key: "CATEGORY",
        name: "제품 카테고리",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "CATEGORY", 100),
      },
      {
        key: "P_URLS",
        name: "진행제품 URL",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "P_URLS", 100),
      },
      {
        key: "ENT_CONTACT_NAME",
        name: "업체 담당자 이름",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_CONTACT_NAME", 100),
      },
      {
        key: "ENT_CONTACT_POSITION",
        name: "업체 담당자 직책",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_CONTACT_POSITION", 100),
      },
      {
        key: "ENT_CONTACT_NUMBER",
        name: "업체 담당자 연락처",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_CONTACT_NUMBER", 100),
      },
      {
        key: "ENT_CONTACT_EMAIL",
        name: "업체 담당자 이메일",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        ...MultiRowCell(),
        editable: false,
        width: getColumnWidth(widths, "ENT_CONTACT_EMAIL", 100),
      },
      {
        key: "ENT_NOTES",
        name: "특이사항",
        headerCellClass: "text-center grid-header-font",
        cellClass: "text-center grid-cell-font",
        width: getColumnWidth(widths, "ENT_NOTES", 200),
        ...MultiRowCell(),
      },
      ...(type === "domestic"
        ? [
            {
              key: "D_ACCOUNT_NUMBER",
              name: "계정번호",
              headerCellClass: "text-center grid-header-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "D_ACCOUNT_NUMBER", 100),
            },
            {
              key: "D_ACCOUNT_NM",
              name: "계정명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "D_ACCOUNT_NM", 100),
            },
          ]
        : []),
      ...(type === "overseas"
        ? [
            {
              key: "O_BUSINESS_ACCOUNT_NM",
              name: "계정번호",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "O_BUSINESS_ACCOUNT_NM", 100),
            },
            {
              key: "O_AD_ACCOUNT_NM",
              name: "계정명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "O_AD_ACCOUNT_NM", 100),
            },
            {
              key: "O_PROGRESS_METHOD",
              name: "진행방식",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "O_PROGRESS_METHOD", 100),
            },
          ]
        : []),
      ...(type === "search"
        ? [
            {
              key: "S_SEARCH_AD_ID",
              name: "검색광고 아이디",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              width: getColumnWidth(widths, "S_SEARCH_AD_ID", 100),
              ...MultiRowCell(),
            },
          ]
        : []),
      ...(type === "overseas"
        ? [
            {
              key: "O_PAYER_NM",
              name: "입금자명",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              editable: false,
              width: getColumnWidth(widths, "O_PAYER_NM", 100),
            },
            {
              key: "TAX_INVOICE_EMAIL",
              name: "세금계산서 발행 이메일",
              headerCellClass: "text-center grid-header-font",
              cellClass: "text-center grid-cell-font",
              ...MultiRowCell(),
              width: getColumnWidth(widths, "TAX_INVOICE_EMAIL", 100),
            },
            {
              key: "IS_CONTRACT_SIGN",
              name: "계약서 서명 여부",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_CONTRACT_SIGN}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_CONTRACT_SIGN: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_CONTRACT_SIGN", 100),
            },
            {
              key: "IS_TAX_INVOICE_ISSUED",
              name: "세금계산서 발행",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_TAX_INVOICE_ISSUED}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_TAX_INVOICE_ISSUED: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_TAX_INVOICE_ISSUED", 100),
            },
            {
              key: "IS_ACCOUNT_PERMISSION_GRANTED",
              name: "계정 권한 부여",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_ACCOUNT_PERMISSION_GRANTED}
                    onChange={(event: any) =>
                      onRowChange(
                        {
                          ...row,
                          IS_ACCOUNT_PERMISSION_GRANTED: event.target.checked,
                        },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(
                widths,
                "IS_ACCOUNT_PERMISSION_GRANTED",
                100
              ),
            },
            {
              key: "IS_AD_CHARGE_REPLY_RECEIVED",
              name: "광고비 충전 회신",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_AD_CHARGE_REPLY_RECEIVED}
                    onChange={(event: any) =>
                      onRowChange(
                        {
                          ...row,
                          IS_AD_CHARGE_REPLY_RECEIVED: event.target.checked,
                        },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_AD_CHARGE_REPLY_RECEIVED", 100),
            },
          ]
        : [
            {
              key: "IS_CONV_TRACK",
              name: "영업팀 전환추적 체크",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_CONV_TRACK}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_CONV_TRACK: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_CONV_TRACK", 100),
            },
            {
              key: "IS_TRANSFER_CHECK",
              name: "영업팀 이관체크 ",
              headerCellClass: "text-center grid-header-font",
              renderCell({ row, onRowChange }: any) {
                return (
                  <CustomCheckbox
                    checked={!!row.IS_TRANSFER_CHECK}
                    onChange={(event: any) =>
                      onRowChange(
                        { ...row, IS_TRANSFER_CHECK: event.target.checked },
                        true
                      )
                    }
                  />
                );
              },
              width: getColumnWidth(widths, "IS_TRANSFER_CHECK", 100),
            },
          ]),
      {
        key: "IS_MAIN_ACCOUNT_PERMISSION_CHECKED",
        name: "대표 계정 권한 체크",
        headerCellClass: "text-center grid-header-font",
        editable: false,
        renderCell({ row, onRowChange }: any) {
          return (
            <CustomCheckbox
              checked={!!row.IS_MAIN_ACCOUNT_PERMISSION_CHECKED}
              onChange={(event: any) =>
                onRowChange(
                  {
                    ...row,
                    IS_MAIN_ACCOUNT_PERMISSION_CHECKED: event.target.checked,
                  },
                  true
                )
              }
            />
          );
        },
        width: getColumnWidth(
          widths,
          "IS_MAIN_ACCOUNT_PERMISSION_CHECKED",
          100
        ),
      },
    ];
    const [openChageModal, setOpenChangeModel] =
      useState<LivePeopleChangePopupOpenType>();
    const [openAccountChageModal, setOpenAccountChangeModel] =
      useState<EntGetType>();
    return (
      <Stack>
        {openChageModal !== undefined && (
          <LivePeopleChangePopup
            open={openChageModal}
            onSubmit={() => handleModalSubmit("change")}
            onCancel={() => setOpenChangeModel(undefined)}
          />
        )}
        {openAccountChageModal !== undefined && (
          <LiveAccountChangePopup
            open={openAccountChageModal}
            onSubmit={() => handleModalSubmit("account")}
            onCancel={() => setOpenAccountChangeModel(undefined)}
          />
        )}
        <DataGrid
          className="rdg-light"
          style={{ height: show ? `61vh` : `84vh` }}
          columns={columns}
          rowHeight={autoRowHeight}
          sortColumns={sortColumns}
          onSortColumnsChange={handleSortColumnsChange}
          onRowsChange={handleUpdateEntInfo}
          onCellKeyDown={handleCellKeyDown}
          rows={rows}
          onSelectedCellChange={handleSelectedRow}
          bottomSummaryRows={summaryRows} // 하단 요약 행 추가
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          onColumnResize={(idx, width) =>
            onColumnResize("ad_progress_list", columns, idx, width)
          }
          onCopy={handleCopy}
        />
      </Stack>
    );
  }
);

export default ADProgressTable;
