import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList } from "@mui/lab";
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridApi,
  GridCallbackDetails,
  GridCellEditStartParams,
  GridCellEditStopParams,
  GridCellParams,
  GridColDef,
  GridColumnResizeParams,
  gridExpandedSortedRowEntriesSelector,
  GridFilterModel,
  GridRowHeightParams,
  GridRowSelectionModel,
  MuiEvent,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import CustomCheckbox from "../../../components/common/CustomCheckbox";
import { popupCustomAlert } from "../../../components/common/CustomToast";
import DataTotalCount, {
  DataCount,
} from "../../../components/common/DataTotalCount";
import { getColumnWidth } from "../../../components/shared/cell-renderers/WidthComponent";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import SelectForm from "../../../components/shared/input-field/SelectForm";
import { CustomColumnMenu } from "../../../components/shared/lib/CustomColumnMenu";
import { getCurrentDate } from "../../../components/shared/lib/getCurrentDate";
import {
  isKeyboardEvent,
  multilineColumn,
} from "../../../components/shared/lib/multilineColumn";
import { handleColumnResize } from "../../../components/shared/lib/xdg";
import { Container } from "../../../components/shared/styled-css/common-css";
import { customAxios } from "../../../config";
import { colorOptions } from "../../../constants/constant";
import { getDetailCode } from "../../../service/common/api";
import { BlackGetType } from "../../../types/BlackType";
import { CallTabType, CallType } from "../../../types/CallType";
import { CodeDetailType } from "../../../types/CodeType";
import { EntGetType } from "../../../types/EntType";
import { errorHandler } from "../../../utils/apiUtil";
import { getClipboardValue } from "../../../utils/commonUtil";
import { formatDate, formatDateYYYYMMDD } from "../../../utils/dateUtil";
import { excelDownLoader } from "../../../utils/excelDownLoader";
import { getJwtState } from "../../../utils/jwtState";
import SheetChangePopup from "./SheetChangePopup";

export const COLORS: { [key: string]: string } = {
  white: "#fff",
  red: "#f3cccb",
  orange: "#fde5cd",
  yellow: "#fff2cc",
  green: "#daead2",
  blue: "#cee3f3",
};

const CallSheet = () => {
  const COLUMN_WIDTHS_KEY = "callSheet";
  const storedData = localStorage.getItem(COLUMN_WIDTHS_KEY);
  const widths = storedData ? JSON.parse(storedData) : {};

  const apiRef = useGridApiRef();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );

  const [openSheetChangeModal, setOpenSheetChangeModal] = useState<CallType[]>(
    []
  );
  const [tab, setTab] = useState<string>("C024A");
  const [tabs, setTabs] = useState<CallTabType[]>([]);
  const [color, setColor] = useState<string>("전체");
  const { userId } = getJwtState();
  const [rows, setRows] = useState<CallType[]>([]);
  const [blackList, setBlackList] = useState<BlackGetType[]>([]);
  const [search, setSearch] = useState("");
  const [entList, setEntList] = useState<EntGetType[]>([]);

  const memoizedRows = React.useMemo(() => rows, [rows]);

  const getTabType = async () => {
    try {
      const response = await getDetailCode("C024");
      const data: CodeDetailType[] = response.data;

      const transformedData = data.map((item) => ({
        value: item.CD_DTL_ID,
        label: item.CD_DTL_NM,
      }));
      if (transformedData.length > 0) {
        setTab(transformedData[0].value);
      }
      setTabs(transformedData);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const scrollToRow = () => {
    console.log("scrollToRow");

    if (!apiRef.current) {
      console.warn("apiRef.current is not available.");
      return;
    }
    // const storedData = React.useMemo(() => {
    //   const data = localStorage.getItem(COLUMN_WIDTHS_KEY + tab);
    //   return data ? JSON.parse(data) : {};
    // }, [tab]);
    // if (!isEmpty(storedData)) {
    //   console.log(storedData);

    //   apiRef.current.setCellFocus(storedData.id, storedData.field);
    //   setTimeout(() => {
    //     const result = apiRef.current.scrollToIndexes({
    //       rowIndex: storedData.rowIndex,
    //       colIndex: 1,
    //     });
    //     console.log("result", result);
    //   }, 200);
    // }
  };

  const getRows = async () => {
    try {
      const response = await customAxios.get("/call", {
        params: {
          CALL_DIV_CD: tab,
          SALESPERSON_ID: userId,
          COLOR: color === "전체" ? "" : color,
          search,
        },
      });
      const data: CallType[] = response.data;
      setRows(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  const getEntList = async () => {
    try {
      const response = await customAxios.get("/ent/list");
      setEntList(response.data);
    } catch (e: unknown) {
      errorHandler(e);
    }
  };

  const getBlackList = async () => {
    try {
      const response = await customAxios.get("/black/call");
      const data: BlackGetType[] = response.data;
      setBlackList(data);
    } catch (e) {
      errorHandler(e);
    }
  };

  useEffect(() => {
    getTabType();
    getBlackList();
    getEntList();
  }, []);

  useEffect(() => {
    getRows();
  }, [tab, color]);

  useEffect(() => {
    scrollToRow();
  }, [tab]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      getRows();
    }
  };

  const handleDeleteSelectedRow = async () => {
    const selectedRows = rows.filter((row) =>
      selectionModel.includes(row.CALL_ID)
    );
    const selectedIds = selectedRows.map((row) => row.CALL_ID);
    const size = selectedRows.length;

    if (size === 0) {
      popupCustomAlert("warn", "선택된 데이터가 없습니다");
      return;
    }

    if (confirm(`선택된 ${size}개의 데이터를 삭제하시겠습니까?`)) {
      try {
        await customAxios.delete("/call/selected", {
          data: {
            CALLS_ID: selectedIds,
          },
        });
        getRows();
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  const handleMoveSheet = () => {
    const selectedRows = rows.filter((row) =>
      selectionModel.includes(row.CALL_ID)
    );
    const size = selectedRows.length;

    if (size === 0) {
      popupCustomAlert("warn", `선택된 데이터가 없습니다`);
      return;
    }

    setOpenSheetChangeModal(selectedRows);
  };

  const handleSheetChangeModalSubmit = () => {
    setOpenSheetChangeModal([]);
    getRows();
  };

  const handleCreateRow = async () => {
    const currentDate = getCurrentDate();
    try {
      await customAxios.post("/call", {
        CALL_DIV_CD: tab,
        SALESPERSON_ID: userId,
        CREATE_DATE: currentDate,
      });
      getRows();
    } catch (e) {
      errorHandler(e);
    }
  };

  /**
   * Mui 무이
   */
  const handleSelectionChange = (newSelectionModel: GridRowSelectionModel) => {
    setSelectionModel(newSelectionModel); // 선택 상태 업데이트
  };
  const isWindows = React.useMemo(
    () => navigator.userAgent.toLowerCase().includes("windows"),
    []
  );

  const handleCellClick = (
    params: GridCellParams,
    event: React.MouseEvent
  ) => {};

  const handleCellKeyDown = (
    params: GridCellParams,
    event: React.KeyboardEvent
  ) => {
    const { id, field, isEditable, cellMode } = params;

    localStorage.setItem(
      COLUMN_WIDTHS_KEY + tab,
      JSON.stringify({
        id: params.id,
        field: params.field,
        rowIndex: params.row.SEQ,
      })
    );

    if (isEditable === false) return;

    const passKey = new Set([
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "F1",
      "F2",
      "F3",
      "F4",
      "F5",
      "F6",
      "F7",
      "F8",
      "F9",
      "F10",
      "F11",
      "F12",
      "Escape",
    ]);
    if (passKey.has(event.key)) {
      return;
    }

    if (event.shiftKey === false && event.key === "Enter") {
      event.preventDefault();
    }

    if ((event.ctrlKey || event.metaKey) && event.key === "c") {
      if (cellMode === "view") {
        event.preventDefault();
        const cellValue = params.value || "";
        navigator.clipboard.writeText(String(cellValue)).then(() => {});
        return;
      }
    }

    if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      if (cellMode === "view") {
        getClipboardValue().then(async (v) => {
          if (v) {
            // updateCell(row, columnKey, v, true).then(() => {
            //   apiRef.current.stopCellEditMode({
            //     id,
            //     field,
            //   });
            // });
            const result = await apiRef.current.setEditCellValue({
              id,
              field,
              value: v,
            });

            if (result) {
              apiRef.current.stopCellEditMode({
                id,
                field,
              });
            }
          }
        });
      }
    }

    if (
      event.ctrlKey ||
      event.metaKey ||
      ((event.ctrlKey || event.metaKey) && event.key === "v")
    ) {
      return;
    }

    // event.preventDefault();
    if (apiRef.current.getCellMode(id, field) !== "edit") {
      event.preventDefault();
      apiRef.current.startCellEditMode({ id, field });
    }
  };

  const processRowUpdate = async (newRow: CallType, oldRow: CallType) => {
    const fieldChanged = Object.keys(newRow).find(
      (field) =>
        newRow[field as keyof CallType] !== oldRow[field as keyof CallType]
    );
    let newValue = newRow[fieldChanged as keyof CallType];
    let oldValue = oldRow[fieldChanged as keyof CallType];

    if (newValue === oldValue) {
      return oldRow;
    }

    updateCell(newRow, fieldChanged as keyof CallType, newValue);
    return newRow;
  };

  const updateCell = async (
    row: CallType,
    field: keyof CallType,
    value: any,
    isSearch: boolean = false
  ) => {
    try {
      const updatedRow = { ...row, [field]: value };
      customAxios
        .put("/call", {
          ...updatedRow,
        })
        .then(() => {
          if (isSearch) {
            getRows();
          }
        });
    } catch (e) {
      errorHandler(e);
    }
  };

  const CustomFooter = () => {
    const [quickSearch, setQuickSearch] = useState("");

    useEffect(() => {
      apiRef.current.setQuickFilterValues([quickSearch]);
    }, [quickSearch]);

    return (
      <Stack flexDirection={"row"} borderTop={"1px solid #e0e0e0"}>
        <Stack
          borderRight={"1px solid #ddd"}
          p={1}
          minWidth={100}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DataTotalCount totalCount={rows.length} />
        </Stack>
        <Stack
          borderRight={"1px solid #ddd"}
          p={1}
          minWidth={100}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DataCount
            unitText="건"
            totalCount={selectionModel.length}
            text="선택"
          />
        </Stack>
        <Box borderRight={"1px solid #ddd"} p={1} alignContent={"center"}>
          <TextField
            value={quickSearch}
            size="small"
            placeholder="조회 결과 내 검색"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuickSearch(e.target.value);
            }}
          />
        </Box>
        {apiRef.current.state &&
          (apiRef.current.state.filter.filterModel.items.length > 0 ||
            quickSearch !== "") && (
            <Box borderRight={"1px solid #ddd"} p={1} alignContent={"center"}>
              <FilteredRowsCount apiRef={apiRef} />
            </Box>
          )}
      </Stack>
    );
  };

  //컬럼 테이블 생성
  const columns: GridColDef<CallType>[] = [
    {
      field: "SEQ",
      headerName: "순번",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      align: "center",
      display: "flex",
      width: widths["SEQ"] === undefined ? 70 : widths["SEQ"],
    },
    {
      field: "COLOR",
      headerName: "색상",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: (params: GridCellParams<CallType>) => {
        return `${params.row.COLOR}-background`;
      },
      align: "center",
      display: "flex",
      // width:getColumnWidth(widths, "COLOR", 70),
      width: widths["COLOR"] === undefined ? 70 : widths["COLOR"],
      valueFormatter: () => "",
      editable: true,
      renderEditCell(params) {
        const onChange = (e: SelectChangeEvent<string>) => {
          updateCell(params.row, "COLOR", e.target.value, true);
          if (params.api) {
            params.api.stopCellEditMode({
              id: params.row.CALL_ID,
              field: "COLOR",
            });
          }
        };

        return (
          <Select
            value={params.row.COLOR}
            onChange={onChange}
            fullWidth
            sx={{
              fontSize: "14px",
              textAlign: "center",
              backgroundColor: COLORS[params.row.COLOR] || "transparent",
              border: "none", // 테두리 제거
              boxShadow: "none", // 선택 시 그림자 제거
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none", // 기본 테두리 제거
              },
            }}
            size="small"
          >
            {Object.keys(COLORS).map((colorKey) => (
              <MenuItem
                key={colorKey}
                value={colorKey}
                sx={{
                  backgroundColor: COLORS[colorKey] || "transparent",
                  color: "#000",
                }}
              >
                {colorKey === "white" ? "없음" : colorKey}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: "SALESPERSON_NM",
      headerName: "영업사원",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      align: "center",
      display: "flex",
      width:
        widths["SALESPERSON_NM"] === undefined ? 70 : widths["SALESPERSON_NM"],
      renderCell: (params) => <span>{params.row.SALESPERSON_NM}</span>,
    },
    {
      field: "CREATE_DATE",
      headerName: "작성일",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      align: "center",
      display: "flex",
      width: widths["CREATE_DATE"] === undefined ? 70 : widths["CREATE_DATE"],
      renderCell: (params) => (
        <span>{formatDateYYYYMMDD(params.row.CREATE_DATE)}</span>
      ),
      editable: true,
    },
    {
      field: "RECENT_CONTACT_DATE",
      headerName: "최근 컨택일",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width:
        widths["RECENT_CONTACT_DATE"] === undefined
          ? 70
          : widths["RECENT_CONTACT_DATE"],
      editable: true,
      ...multilineColumn,
    },
    {
      field: "CONTACT_SOURCE",
      headerName: "출처",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width:
        widths["CONTACT_SOURCE"] === undefined ? 70 : widths["CONTACT_SOURCE"],
      editable: true,
      ...multilineColumn,
    },
    {
      field: "KEYWORD",
      headerName: "키워드",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width: widths["KEYWORD"] === undefined ? 70 : widths["KEYWORD"],
      editable: true,
      ...multilineColumn,
    },
    {
      field: "BUSINESS_NAME",
      headerName: "브랜드명",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width:
        widths["BUSINESS_NAME"] === undefined ? 70 : widths["BUSINESS_NAME"],
      editable: true,
      ...multilineColumn,
    },
    {
      field: "PRODUCT_URL",
      headerName: "링크",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width: widths["PRODUCT_URL"] === undefined ? 70 : widths["PRODUCT_URL"],
      editable: true,
      ...multilineColumn,
    },
    {
      field: "ENT_CONTACT_NAME",
      headerName: "업체 담당자",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width:
        widths["ENT_CONTACT_NAME"] === undefined
          ? 70
          : widths["ENT_CONTACT_NAME"],
      editable: true,
      ...multilineColumn,
    },
    {
      field: "PHONE_NUMBER",
      headerName: "전화번호",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width: widths["PHONE_NUMBER"] === undefined ? 70 : widths["PHONE_NUMBER"],
      editable: true,
      ...multilineColumn,
    },
    {
      field: "NOTES",
      headerName: "통화 내용",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width: widths["NOTES"] === undefined ? 250 : widths["NOTES"],
      editable: true,
      ...multilineColumn,
    },
    {
      field: "EMAIL",
      headerName: "이메일",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width: widths["EMAIL"] === undefined ? 150 : widths["EMAIL"],
      editable: true,
    },
    {
      field: "IS_KAKAO_CONTACT",
      headerName: "카톡",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width:
        widths["IS_KAKAO_CONTACT"] === undefined
          ? 70
          : widths["IS_KAKAO_CONTACT"],
      align: "center",
      renderCell: (params) => (
        <CustomCheckbox
          checked={!!params.row.IS_KAKAO_CONTACT}
          onChange={(event) =>
            updateCell(
              params.row,
              "IS_KAKAO_CONTACT",
              event.target.checked,
              true
            )
          }
          checkedBgColor="#ecca06"
        />
      ),
    },
    {
      field: "IS_MAIL_CONTACT",
      headerName: "메일",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width:
        widths["IS_MAIL_CONTACT"] === undefined
          ? 70
          : widths["IS_MAIL_CONTACT"],
      align: "center",
      renderCell: (params) => (
        <CustomCheckbox
          checked={!!params.row.IS_MAIL_CONTACT}
          onChange={(event) =>
            updateCell(
              params.row,
              "IS_MAIL_CONTACT",
              event.target.checked,
              true
            )
          }
          checkedBgColor="#6e00d4"
        />
      ),
    },
    {
      field: "CREATED_AT",
      headerName: "데이터 생성시간",
      headerClassName: "xdg-header-font",
      headerAlign: "center",
      cellClassName: "xdg-cell-font",
      display: "flex",
      width: getColumnWidth(widths, "CREATED_AT", 250),
      editable: true,
      renderCell: (params) => <div>{formatDate(params.row.CREATED_AT)}</div>,
    },
  ];

  const handleColorChange = (e: SelectChangeEvent) => {
    setColor(e.target.value);
  };

  const handleExcelExport = () => {
    const selectedRows = rows.filter((row) =>
      selectionModel.includes(row.CALL_ID)
    );
    if (selectedRows.length === 0) {
      popupCustomAlert("warn", "다운로드할 데이터가 없습니다");
      return;
    }
    const excelData = selectedRows.map((item) => {
      return {
        영업사원ID: item.SALESPERSON_ID,
        영업사원명: item.SALESPERSON_NM,
        작성일: item.CREATE_DATE,
        최근컨택일: item.RECENT_CONTACT_DATE,
        색상: item.COLOR,
        출처: item.CONTACT_SOURCE,
        키워드: item.KEYWORD,
        브랜드명: item.BUSINESS_NAME,
        링크: item.PRODUCT_URL,
        업체담당자: item.ENT_CONTACT_NAME,
        전화번호: item.PHONE_NUMBER,
        통화내용: item.NOTES,
        이메일: item.EMAIL,
        카톡: item.IS_KAKAO_CONTACT ? "O" : "X",
        메일: item.IS_MAIL_CONTACT ? "O" : "X",
      };
    });

    excelDownLoader(excelData, "콜시트.xlsx");
  };

  const FilteredRowsCount = (props: {
    apiRef: React.MutableRefObject<GridApi>;
  }) => {
    const { apiRef } = props;

    const rowsCount = useGridSelector(
      apiRef,
      gridExpandedSortedRowEntriesSelector
    );

    return (
      <DataCount unitText="건" totalCount={rowsCount.length} text="필터" />
    );
  };

  return (
    <Container>
      <Stack flex={1} display={"flex"} width={"100%"} gap={2}>
        {openSheetChangeModal.length > 0 && (
          <SheetChangePopup
            open={openSheetChangeModal}
            tab={tab}
            tabs={tabs}
            onSubmit={handleSheetChangeModalSubmit}
            onCancel={() => setOpenSheetChangeModal([])}
          />
        )}
        <Stack gap={2} display={"flex"} flex={1}>
          <Stack direction="row" justifyContent={"space-between"} gap={3}>
            <TabContext value={tab}>
              <TabList
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab key={"C024A"} label={"콜시트"} value={"C024A"} />
                <Tab key={"C024B"} label={"가망 건"} value={"C024B"} />
                <Tab key={"C024C"} label={"분배 DB"} value={"C024C"} />
                <Tab key={"C024D"} label={"A급 DB"} value={"C024D"} />
              </TabList>
            </TabContext>
            <Stack
              flexDirection={"row"}
              display={"flex"}
              gap={1}
              alignItems={"center"}
              justifyContent={"center"}
              alignContent={"center"}
            >
              <SelectForm
                label="색상"
                options={colorOptions}
                size="small"
                sx={{ width: "120px" }}
                value={color}
                handleChange={handleColorChange}
              />
              <SearchInputField
                value={search}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                placeholder=""
                showIcon={false}
                width={200}
              />
              <CommonButton
                onClick={getRows}
                label="검색"
                icon={<SearchIcon fontSize="small" />}
              />
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleExcelExport}
                sx={{
                  fontFamily: "malgun",
                  height: 40,
                }}
              >
                엑셀 다운로드
              </Button>
              <Button
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleCreateRow}
                sx={{
                  fontFamily: "malgun",
                  height: 40,
                }}
              >
                행 추가
              </Button>
              <Button
                variant="contained"
                startIcon={<DriveFileMoveIcon />}
                onClick={handleMoveSheet}
                color="success"
                sx={{ fontFamily: "malgun", height: 40, color: "white" }}
              >
                시트 이동
              </Button>
              <Button
                variant="contained"
                startIcon={<DeleteForeverOutlinedIcon />}
                onClick={handleDeleteSelectedRow}
                sx={{
                  fontFamily: "malgun",
                  height: 40,
                }}
                color="warning"
              >
                일괄 삭제
              </Button>
            </Stack>
          </Stack>
          <DataGrid
            apiRef={apiRef}
            getRowId={(row: CallType) => row.CALL_ID}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleSelectionChange}
            rows={memoizedRows}
            getRowHeight={(params: GridRowHeightParams) => {
              const defaultRowHeight = 50; // 기본 행 높이
              const lineHeight = 25;
              let maxLineBreaks = 0;

              Object.values(params.model).forEach((value) => {
                if (typeof value === "string") {
                  const lineBreaks = (value.match(/\n/g) || []).length;
                  maxLineBreaks = Math.max(maxLineBreaks, lineBreaks);
                }
              });

              const additionalHeight = maxLineBreaks * lineHeight;
              return defaultRowHeight + additionalHeight;
            }}
            columns={columns}
            columnHeaderHeight={35}
            checkboxSelection
            disableRowSelectionOnClick
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              popupCustomAlert("error", "해당 문제를 담당자에게 문의주세요");
              console.error("수정 처리 중 오류:", error);
            }}
            onColumnResize={(params: GridColumnResizeParams) =>
              handleColumnResize(params, COLUMN_WIDTHS_KEY)
            }
            onColumnWidthChange={(params: GridColumnResizeParams) =>
              handleColumnResize(params, COLUMN_WIDTHS_KEY)
            }
            onFilterModelChange={(
              model: GridFilterModel,
              details: GridCallbackDetails<"filter">
            ) => {}}
            showCellVerticalBorder
            showColumnVerticalBorder
            onCellClick={handleCellClick}
            onCellKeyDown={handleCellKeyDown}
            onCellEditStart={(
              params: GridCellEditStartParams,
              event: MuiEvent
            ) => {}}
            onCellEditStop={(
              params: GridCellEditStopParams,
              event: MuiEvent
            ) => {
              if (
                isKeyboardEvent(event) &&
                event.shiftKey &&
                event.key === "Enter"
              ) {
                event.defaultMuiPrevented = true;
                return;
              }
            }}
            hideFooterPagination
            disableColumnMenu
            slots={{
              footer: CustomFooter,
              columnMenu: CustomColumnMenu,
              // toolbar: GridToolbar
            }}
            slotProps={
              {
                // toolbar: {
                //   showQuickFilter: true,
                // },
              }
            }
            sx={{
              minHeight: "84vh",
              maxHeight: "84vh",

              "& .MuiDataGrid-footerContainer": {
                // display: "none",
              },
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#f9f9f9",
              },
              "&.MuiDataGrid-root": {
                borderRadius: 0,
              },
              '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
                {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
            }}
          />
        </Stack>
      </Stack>
    </Container>
  );
};
export default CallSheet;
