import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabList } from "@mui/lab";
import { SelectChangeEvent, Stack, Tab } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import SearchInputField from "../../../components/shared/input-field/SearchInputField";
import SelectMonthField from "../../../components/shared/input-field/SelectMonthField";
import SelectYearField from "../../../components/shared/input-field/SelectYearField";
import { ListTableHandle, TabType } from "../../../types/CommonType";
import LiveRequestTable from "./LiveRequestTable";
import SelectLiveForm, { liveStateOptions } from "../../../components/common/SelectLiveForm";

export default function LiveRequestList() {
  const [selected, setSelected] = useState<TabType>("");
  const [search, setSearch] = useState("");
  const tableRef = useRef<ListTableHandle>(null);
  const today = new Date();
  const [year, setYear] = useState<string>(today.getFullYear().toString()); // 오늘 년도
  const [month, setMonth] = useState<string>(
    (today.getMonth() + 1).toString().padStart(2, "0")
  );
  const [live, setLive] = useState<string>("전체");

  useEffect(() => {
    onSearch();
  }, [selected]);

  /**
   * 컴포넌트
   */
  const onSearch = () => {
    tableRef.current?.triggerSearch();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: TabType) => {
    setSelected(newValue);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  const handleLiveChange = (e: SelectChangeEvent) => {
    setLive(e.target.value);
  };

  return (
    <Stack flex={1} display={"flex"} width={"100%"}>
      <Stack
        sx={{ width: "100%", typography: "body1" }}
        display={"flex"}
        flexDirection={"column"}
        flex={1}
      >
        <TabContext value={selected}>
          <Stack gap={2}>
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto">
                <Tab label={"전체"} value={""} />
                <Tab label={"국내매체광고"} value={"domestic"} />
                <Tab label={"해외매체광고"} value={"overseas"} />
                <Tab label={"검색광고"} value={"search"} />
              </TabList>
              <Stack
                display={"flex"}
                flexDirection={"row"}
                gap={1}
                alignItems={"center"}
              >
                <SelectLiveForm
                  label="상태"
                  options={liveStateOptions.filter((v) => v.value === "request" || v.value === 'divide' || v.value ==='전체')}
                  size="small"
                  sx={{ width: "120px" }}
                  value={live}
                  handleChange={handleLiveChange}
                />
                <SelectYearField
                  sx={{ backgroundColor: "white" }}
                  label="년도"
                  fullWidth={false}
                  size="small"
                  value={year}
                  handleChange={(e) => {
                    setYear(e.target.value);
                  }}
                />
                <SelectMonthField
                  label="월"
                  sx={{ backgroundColor: "white" }}
                  fullWidth={false}
                  size="small"
                  value={month}
                  handleChange={(e) => {
                    setMonth(e.target.value);
                  }}
                />
                <SearchInputField
                  value={search}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  placeholder=""
                  showIcon={false}
                  width={200}
                />
                <CommonButton
                  onClick={onSearch}
                  label="검색"
                  icon={<SearchIcon fontSize="small" />}
                />
              </Stack>
            </Stack>
          </Stack>
        </TabContext>
        <Stack mt={2} flex={1}>
          <LiveRequestTable
            type={selected}
            search={search}
            ref={tableRef}
            year={year}
            month={month}
            live={live}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
